import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { AppState } from '@Mesh/store/app.state';
import { Store } from '@ngrx/store';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { ChatService } from './chat.service';

import { User } from '@Mesh/core/models/user';
import { ChatDialogService, ChatDialogType } from './chat-dialog/chat-dialog.service';
import { filter, mergeMap, takeUntil, throttleTime } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { UserService } from '@Mesh/core/services/user.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { getTaskProgressStatusNew } from '../../../store/task/task.selectors';
import { ChatDialog } from './models/chat-dialog';
import { TaskProgressLoad } from '../../../store/task/task.actions';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger('productRecognition', [
      state(
        'open',
        style({
          opacity: '*',
          bottom: '15px',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          bottom: '-56px',
        })
      ),
      transition('open => closed', [animate('0.7s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked {
  u$ = new Subject();
  _record: User | any;
  dialog: ChatDialog;
  current_user: User;
  userToChat = null;
  search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentUser$: Observable<any>;
  activeOutlet$ = this.store.select(outletSelectors.getActive);
  showPreviewTask$ = this.store.select(getTaskProgressStatusNew);
  search: string;
  spinner = SPINNER;
  newChat: boolean;
  supportUserId: number;
  showPreviewTask: boolean = false;
  all$: Observable<unknown>;

  set searchUsers(value: string) {
    this.search$.next(value);
    this.cdr.detectChanges();
  }

  get record() {
    return this._record;
  }

  set record(value) {
    this._record = value;
    this.chatDialogService.record = value;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.u$.next();
    this.u$.unsubscribe();
  }

  openCreateChatView() {
    this.newChat = true;
  }

  ngAfterViewChecked(): void {}

  constructor(
    public chatService: ChatService,
    private chatDialogService: ChatDialogService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private userService: UserService,
    private feathersService: FeathersService,
    private ngxService: NgxUiLoaderService
  ) {}

  getDialog(params: Params): Observable<any> {
    this.dialog = {};
    if (params?.action === 'chat') {
      switch (params?.action_type) {
        case 'task-comments':
          this.dialog = {
            type: params?.action_type,
            typeId: params?.action_id,
            taskId: params?.task_id,
            addressSapId: params?.address_sap_id,
            stepId: params?.step_id,
            clientSapId: params?.client_sap_id,
            sentBy: params?.sent_by,
            approve: params?.approve,
            cancel: params?.cancel,
          };
          if (params?.action_id) {
            this.getTaskStatus(params?.action_id);
          }
          break;
        case 'support':
          this.dialog = {
            type: params?.action_type,
            typeId: params?.action_id,
            addressSapId: params?.address_sap_id,
            clientSapId: params?.client_sap_id,
            sentBy: params?.sent_by,
          };
          console.log('dialog', this.dialog);
          break;
      }
      const observables = [];
      if (this.dialog.sentBy) {
        observables.push(this.chatService.getUser(this.dialog.sentBy));
      } else if (this.dialog.clientSapId) {
        observables.push(this.chatService.getUserByClientSapId(this.dialog.clientSapId));
      }
      observables.push(
        this.dialog.type === 'task-comments' && this.dialog.taskId ? this.chatService.getTaskById(this.dialog.taskId) : of(null),
        this.dialog.stepId ? this.chatService.getTaskStep(this.dialog.stepId) : of(null),
        this.dialog.addressSapId ? this.chatService.getOutletByAddressSapId(this.dialog.addressSapId) : of(null)
      );
      return forkJoin(observables);
    }
    return of([]);
  }

  ngOnInit() {
    this.currentUser$ = this.feathersService.currentUserSubject;
    this.search$.pipe(takeUntil(this.u$), throttleTime(300)).subscribe((value) => {
      this.search = value;
    });
    this.showPreviewTask$.pipe(takeUntil(this.u$)).subscribe((value) => {
      this.showPreviewTask = value;
    });

    this.currentUser$.pipe(takeUntil(this.u$)).subscribe((user) => {
      this.current_user = user;
      this.chatService.user = user;
      this.cdr.detectChanges();
    });

    this.route.queryParamMap
      .pipe(
        takeUntil(this.u$),
        mergeMap((paramsMap): Observable<any[]> => {
          const params = paramsMap['params'];
          return this.getDialog(params);
        })
      )
      .subscribe(([user, task, taskStep, outlet]) => {
        console.log('user-from:', user);
        this.dialog.task = task;
        this.dialog.taskStep = taskStep;
        this.dialog.outlet = outlet;
        this.record = user;
      });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(
        takeUntil(this.u$),
        mergeMap((value): Observable<any[]> => {
          const params = { ...this.route.snapshot.params, ...this.route.snapshot.queryParams };
          return this.getDialog(params);
        })
      )
      .subscribe(([user, task, taskStep, outlet]) => {
        // console.log('user-from:', user);
        this.dialog.task = task;
        this.dialog.taskStep = taskStep;
        this.dialog.outlet = outlet;
        this.record = user;
      });
  }

  onBack() {
    const queryParams = {
      action: 'chat',
      action_type: null,
      action_id: null,
      task_id: null,
      step_id: null,
      address_sap_id: null,
      client_sap_id: null,
      approve: null,
      cancel: null,
      sent_by: null,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  openChat(record: { dialog?: any; type?: ChatDialogType; info? }) {
    if (record && record.dialog) {
      const { id: current_user_id } = this.current_user,
        { id: reply_user_id = current_user_id } = record.dialog.message.replyUser || {};
      this.dialog = record.dialog;
      this.record = current_user_id !== reply_user_id ? record.dialog.message.replyUser : record.dialog.message.user;
    } else if (record === 'support') {
      this.chatService
        .getSupportUser()
        .pipe(
          mergeMap(({ userId }) => {
            this.supportUserId = userId;
            console.log('userId', userId);
            return this.activeOutlet$;
          })
        )
        .subscribe((outlet) => {
          console.log('outlet', outlet);
          this.getDialog({
            action: 'chat',
            action_type: 'support',
            address_sap_id: outlet.addressSapId,
            sent_by: this.supportUserId,
          }).subscribe(([user, task, taskStep, outlet]) => {
            console.log('user-from:', user);
            this.dialog.task = task;
            this.dialog.taskStep = taskStep;
            this.dialog.outlet = outlet;
            this.record = user;
          });
        });
    }
    this.cdr.detectChanges();
  }

  onStartedTask() {
    this.showPreviewTask = false;
    const taskId = this.dialog.task.step[0].id;
    const query = { taskOutletClientId: this.dialog.typeId, addressSapId: this.dialog.addressSapId };
    this.chatService.getTaskSteps(taskId, query).pipe(takeUntil(this.u$)).subscribe();
  }

  private getTaskStatus(taskId: number): void {
    this.store.dispatch(new TaskProgressLoad({ taskOutletClientId: taskId }));
  }
}
