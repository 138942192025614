import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ModalModule,
  TabsModule,
  BsDropdownModule,
  AccordionModule,
  AlertModule,
  ButtonsModule,
  CollapseModule,
  ProgressbarModule,
  TooltipModule,
  TypeaheadModule,
} from 'ngx-bootstrap';

import { TasksComponent } from './tasks.component';
import { TasksContentComponent } from './tasks-content/tasks-content.component';
import { TaskItemComponent } from './tasks-content/task-item/task-item.component';
import { TasksPlaceholderComponent } from './tasks-placeholder/tasks-placeholder.component';
import { TasksTitleComponent } from './tasks-title/tasks-title.component';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModerationTaskContentComponent } from './moderation-task-content/moderation-task-content.component';
import { ProgressBarModule } from '@Mesh/shared/components/progress-bar/progress-bar.module';
import { TasksWidgetComponent } from './components/tasks-widget/tasks-widget.component';

@NgModule({
  declarations: [
    TasksContentComponent,
    TasksComponent,
    TaskItemComponent,
    TasksPlaceholderComponent,
    TasksTitleComponent,
    ModerationTaskContentComponent,
    TasksWidgetComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CollapseModule,
    ProgressbarModule,
    TooltipModule,
    TypeaheadModule,
    InfiniteScrollModule,
    ProgressBarModule,
  ],
  exports: [TasksContentComponent, TasksComponent, TaskItemComponent, TasksWidgetComponent],
  providers: [],
  entryComponents: [],
})
export class TasksModule {}
