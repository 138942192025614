import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-history-placeholder',
  templateUrl: './history-placeholder.component.html',
  styleUrls: ['./history-placeholder.component.scss'],
})
export class HistoryPlaceholderComponent {
  @Input() isHistory = true;
  @Input() title = 'История заказов отсутствует';
}
