import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: number = 0;
  @Input() showLabel: boolean = false;
  @Input() labelText: string | number = '0';
  @Input() size: 'sm' | 'lg' = 'sm';
  @Input() type: 'gradient' | 'progress' = 'gradient';
  classProgressType: string = '';

  constructor() {}

  ngOnInit() {
    if (this.type === 'progress') {
      if (this.inRange(this.progress, 0, 40)) {
        this.classProgressType = 'danger';
      } else if (this.inRange(this.progress, 40, 60)) {
        this.classProgressType = 'warning';
      } else if (this.inRange(this.progress, 60, 100)) {
        this.classProgressType = 'success';
      } else {
        this.classProgressType = 'danger';
      }
    } else {
      this.classProgressType = 'gradient';
    }
  }

  inRange(x, min, max) {
    return x >= min && x <= max;
  }
}
