import { Injectable, Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'groupBy',
})
@Injectable()
export class GroupByPipe implements PipeTransform {
  constructor() {}

  public transform(value: Array<any>, field: string): Array<any> {
    const groupedMap: Map<any, any[]> = value.reduce((map, cur) => {
      const key = cur[field];
      const group = map.get(key);
      if (!group) {
        map.set(key, [cur]);
      } else {
        group.push(cur);
      }
      return map;
    }, new Map<any, any[]>());

    return Array.from(groupedMap, ([key, value]) => ({ key, value }));
  }
}
