import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from '../../tasks.model';

@Component({
  selector: 'iqretail-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss'],
})
export class TaskItemComponent {
  @Input() task: Task;
  @Input() isLast = false;

  @Output() navToTaskDetails = new EventEmitter<{ taskId: number }>();

  onNavToTasksDetails(): void {
    this.navToTaskDetails.emit({ taskId: this.task.id });
  }
}
