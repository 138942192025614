import { Component, Input, HostBinding, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Outlet, HistoryData, OrderStatus, OrderDetails } from '../../active-orders.models';
import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'iqretail-active-order-item',
  templateUrl: './active-order-item.component.html',
  styleUrls: ['./active-order-item.component.scss'],
})
export class ActiveOrderItemComponent implements OnChanges {
  @Input() order: HistoryData;
  @Input() outlet: Outlet;
  @Input() orderDetails: OrderDetails[] = [];
  @Input() isLast = false;
  @Input() selectedOrderId: string;

  @Output() openDetailsEvent = new EventEmitter<string>();
  @Output() navToActiveOrdersDetails = new EventEmitter<{ data: string }>();
  @Output() confirmedDelivery = new EventEmitter<string>();

  @HostBinding('class.apply-elements') apply = true;

  times: string[] = [];

  OrderStatus = OrderStatus;
  isOpen = false;

  ngOnChanges({ orderDetails }: SimpleChanges) {
    console.log('0- order', this.outlet, this.orderDetails);
    if (orderDetails && orderDetails.currentValue) {
      this.setOrderDetailsTimes(orderDetails.currentValue);
    }
  }

  toggleDetails() {
    if (this.isOpen) {
      this.isOpen = false;
    } else if (this.order.docNumber) {
      this.isOpen = true;
      this.openDetailsEvent.emit(this.order.docNumber);
    }
  }

  getStatusClass(status: OrderStatus): string {
    const classMap: Record<OrderStatus, string> = {
      [OrderStatus.inBasket]: 'waiting',
      [OrderStatus.accepted]: 'accepted',
      [OrderStatus.denied]: 'denied',
      [OrderStatus.finished]: 'finished',
    };

    return classMap[status];
  }

  onNavToActiveOrdersDetails(): void {
    this.navToActiveOrdersDetails.emit({ data: this.order.docNumber });
  }

  onConfirmDelivery(event: Event): void {
    if (event) {
      event.stopPropagation();
    }
    this.confirmedDelivery.emit(this.order.docNumber);
  }

  private setOrderDetailsTimes(orderDetails: OrderDetails[]) {
    this.times = orderDetails.map((el) => {
      console.error(moment(el.docDateTime).local().format('hh:mm'));
      return moment(el.docDateTime).local().format('hh:mm');
    });
  }
}
