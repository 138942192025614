import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderDetailComponent } from './ordrer-detail.component';
import { TimesPipe } from './times.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TimesPipe, OrderDetailComponent],
  exports: [OrderDetailComponent],
})
export class OrderDetailModule {}
