import { CartActionsTypes, CartActionsUnion } from '@Mesh/store/cart/cart.actions';
import { RequestStatus } from '@Mesh/shared/enums/request-status.enum';
import {
  CartFileAddressProcess,
  CartItem,
  CartItemType,
  MinimalCartRuleGroupedStatus,
  MinimalCartRules,
  OrderCompletedState,
} from '@Mesh/store/cart/cart.models';
import { GroupedStock, Product, Stock } from '@Mesh/core/models/product';
import { CategotyTree } from '../../core/models/categories';
import { HttpErrorResponse } from '@angular/common/http';
import { IChangedCartPosition } from '@Mesh/pages/catalog/basket/basket-interfaces';
import { OrderHistoryItem } from '../order/order.model';
import { convertArrayToObject } from '../../shared/helpers/object.helpers';
import { groupBy } from '../../shared/helpers/group.helpers';

export const cartFeatureKey = 'cart';

export interface CartState {
  cart: CartItem[];
  defaultCart: CartItem[];
  recommendedCart: CartItem[];
  recommendedOrderId: number;
  cartProducts: Product[];
  loadCartStatus: RequestStatus;
  submitCartStatus: RequestStatus;
  cartChangedData: HttpErrorResponse;
  submitCartError: HttpErrorResponse;
  cartStockMap: Record<number, Stock>; // product id, Stock
  orderCompletedState: OrderCompletedState[];
  closestDateLoading: boolean;
  nextDeliveryDate: string;
  minimalCartRules: MinimalCartRules;
  minimalCartRulesGroupStatus: MinimalCartRuleGroupedStatus;
  changesRecommendedCart: boolean;
  categoryCart: CategotyTree[];
  fillByFileProgress: CartFileAddressProcess;
  fillByFileCurrent: any;
  ordersHistoryMap: Record<number, OrderHistoryItem[]>; // product id, OrderHistoryItem
}

const initialState: CartState = {
  cart: [],
  cartChangedData: null,
  defaultCart: [],
  recommendedCart: [],
  recommendedOrderId: null,
  cartProducts: [],
  orderCompletedState: [],
  loadCartStatus: RequestStatus.NONE,
  submitCartStatus: RequestStatus.NONE,
  submitCartError: null,
  cartStockMap: {},
  closestDateLoading: false,
  nextDeliveryDate: null,
  minimalCartRules: null,
  minimalCartRulesGroupStatus: null,
  changesRecommendedCart: false,
  categoryCart: [],
  fillByFileProgress: null,
  fillByFileCurrent: null,
  ordersHistoryMap: {},
};

export function cartReducer(state = initialState, action: CartActionsUnion): CartState {
  switch (action.type) {
    case CartActionsTypes.LoadCart:
      return {
        ...state,
        loadCartStatus: RequestStatus.SENT,
        cartChangedData: null,
      };
    case CartActionsTypes.LoadCartSuccess:
      return {
        ...state,
        loadCartStatus: RequestStatus.SUCCESS,
        defaultCart: [...action.payload],
        cart: [...action.payload],
      };
    case CartActionsTypes.LoadCartFailure:
      return {
        ...state,
        loadCartStatus: RequestStatus.FAILURE,
      };
    case CartActionsTypes.LoadCartProductsSuccess:
      return {
        ...state,
        cartProducts: action.payload,
      };
    case CartActionsTypes.SubmitCart:
      return {
        ...state,
        submitCartStatus: RequestStatus.SENT,
        submitCartError: null,
      };
    case CartActionsTypes.SubmitCartSuccess:
      const orderCompletedState: any = [];
      action.payload.orders.forEach((order) => {
        const items = [];
        let totalAmount = 0;
        let totalPrice = 0;
        let totalDiscountPrice = 0;
        order.carts.forEach((item) => {
          items.push({
            name: item.materialId.toString(),
            amount: item.quantity,
            unit: item.unit,
            distributorName: item.distributorName,
            price: item.discountNdsPrice,
            image: '',
          });
          totalAmount += item.quantity;
          totalDiscountPrice += item.discountNdsPrice * item.quantity;
          totalPrice += item.ndsPrice * item.quantity;
        });
        orderCompletedState.push({
          orderNumber: order.orderNumber,
          delayedOrderId: order.delayedOrderId,
          items: items,
          type: order.type,
          deliveryDate: order.deliveryDate,
          delayedTo: order.delayedTo,
          totalPrice: totalPrice,
          totalDiscountPrice: totalDiscountPrice,
          totalAmount: totalAmount,
          distributorName: order.distributorName,
        });
      });

      orderCompletedState.forEach((order) => {
        order.items.forEach((item) => {
          const curr = action.payload.cart.find((itm) => itm.materialId === parseInt(item.name, 10));
          if (curr) {
            item.name = curr.name;
            item.image = curr.image;
            order.salesOrgSapId = curr.salesOrgSapId;
            order.distributorCode = curr.distributorCode;
          }
        });
      });

      return {
        ...state,
        submitCartStatus: RequestStatus.SUCCESS,
        orderCompletedState,
        cart: [],
        recommendedCart: [],
        defaultCart: [],
        fillByFileProgress: null,
        // orderCompletedState: { ...state.orderCompletedState, orderNumber: action.payload.orderNumber },
      };
    case CartActionsTypes.SubmitCartFailure:
      console.log(action.error);
      return {
        ...state,
        submitCartStatus: RequestStatus.FAILURE,
        submitCartError: action.error,
        orderCompletedState: undefined,
      };
    case CartActionsTypes.UpdateCartSuccess:
      const tempUpdateCart = [...state.cart];
      const updatedItemIndex = tempUpdateCart.findIndex(
        // (el) => el.materialId === action.cartItem.materialId && el.unit === action.cartItem.unit

        (el) =>
          el.materialId === action.cartItem.materialId &&
          el.unit === action.cartItem.unit &&
          el.distributorCode === action.cartItem.distributorCode &&
          el.distributorName === action.cartItem.distributorName &&
          el.salesOrgSapId === action.cartItem.salesOrgSapId &&
          el.ndsPrice === action.cartItem.ndsPrice
      );
      if (updatedItemIndex === -1) {
        tempUpdateCart.push({ ...action.cartItem });
      } else {
        if (action.cartItem.quantity) {
          tempUpdateCart[updatedItemIndex] = { ...action.cartItem };
        } else {
          tempUpdateCart.splice(updatedItemIndex, 1);
        }
      }
      const elementUpdateRecommendCart = (state.recommendedCart || []).find(
        (el) => el.materialId === action.cartItem.materialId && el.unit === action.cartItem.unit
      );
      return {
        ...state,
        defaultCart: [...tempUpdateCart].filter((item) => item.type.indexOf(CartItemType.RECOMMENDED) !== -1),
        cart: [...tempUpdateCart],
        changesRecommendedCart: elementUpdateRecommendCart && elementUpdateRecommendCart.quantity > action.cartItem.quantity && true,
      };
    case CartActionsTypes.DeleteProductSuccess:
      const tempRemoveCart = [...state.cart];
      const indexToRemove = tempRemoveCart.findIndex(
        (el) => el.materialId === action.payload.materialId && el.unit === action.payload.unit
      );
      tempRemoveCart.splice(indexToRemove, 1);
      const elementRemoveRecommendCart = (state.recommendedCart || []).find(
        (el) => el.materialId === action.payload.materialId && el.unit === action.payload.unit
      );
      return {
        ...state,
        defaultCart: [...tempRemoveCart].filter((item) => item.type.indexOf(CartItemType.RECOMMENDED) !== -1),
        cart: [...tempRemoveCart],
        changesRecommendedCart: elementRemoveRecommendCart && true,
      };
    case CartActionsTypes.SetStock:
      const obj = { ...state.cartStockMap };
      obj[action.payload.id] = action.payload.stock;
      return {
        ...state,
        cartStockMap: { ...obj },
      };
    case CartActionsTypes.PageProductsLoaded:
      const stockObj: Record<number, Stock> = {};
      action.products
        .filter((el) => !!el)
        .forEach((product) => {
          if (!stockObj[product.id]) {
            stockObj[product.id] = setDefaultStock(product);
          }
        });
      return {
        ...state,
        cartStockMap: stockObj,
      };
    case CartActionsTypes.ClearOnOrderCompletedState:
      return {
        ...state,
        orderCompletedState: undefined,
        submitCartStatus: RequestStatus.NONE,
      };
    case CartActionsTypes.LoadDeliveryDate:
      return {
        ...state,
        closestDateLoading: true,
      };
    case CartActionsTypes.HandleError409:
      return {
        ...state,
        cartChangedData: action.payload,
      };
    case CartActionsTypes.LoadDeliveryDateSuccess:
      const ordCompSt = (state.orderCompletedState ? [...state.orderCompletedState] : []).map((order) => {
        order.deliveryDate = order.deliveryDate === null ? action.payload.date : order.deliveryDate;
        return order;
      });
      return {
        ...state,
        closestDateLoading: false,
        nextDeliveryDate: action.payload.date,
        orderCompletedState: ordCompSt,
        // orderCompletedState: state.orderCompletedState ? { ...state.orderCompletedState, closestDate: action.payload.date } : undefined,
      };
      return state;
    case CartActionsTypes.LoadDeliveryDateFailure:
      return {
        ...state,
        closestDateLoading: false,
      };
    case CartActionsTypes.SetOrderSubmitState:
      const orderCompSt = [...state.orderCompletedState];

      orderCompSt.forEach((order) => {
        order.items.forEach((item) => {
          const curr = action.payload.find((itm) => itm.materialId === parseInt(item.name, 10));
          item.name = curr.name;
          order.salesOrgSapId = curr.salesOrgSapId;
        });
      });

      return {
        ...state,
        // orderCompletedState: { ...state.orderCompletedState, ...action.payload },
        orderCompletedState: orderCompSt,
      };
    case CartActionsTypes.LoadMinimalCartRulesByCategorySuccess:
      return {
        ...state,
        minimalCartRules:
          action.payload && action.payload.length && action.payload[0].currentAmount < action.payload[0].amount && action.payload[0],
      };

    case CartActionsTypes.ResetMinimalCartRulesByCategory:
      return {
        ...state,
        minimalCartRules: null,
      };

    case CartActionsTypes.ResetMinimalCartRuleGroupedStatus:
      return {
        ...state,
        minimalCartRulesGroupStatus: null,
      };

    case CartActionsTypes.LoadMinimalCartRuleGroupedStatusSuccess:
      return {
        ...state,
        minimalCartRulesGroupStatus: action.payload,
      };

    case CartActionsTypes.LoadRecommendedOrderInCartSuccess:
      return {
        ...state,
        recommendedCart: action.payload,
        cart: [...action.payload, ...state.defaultCart],
      };

    case CartActionsTypes.UpdateRecommendedOrderInCartSuccess:
      return {
        ...state,
        recommendedCart: [...action.payload],
        cart: [...state.recommendedCart, ...state.defaultCart],
        changesRecommendedCart: false,
      };

    case CartActionsTypes.SetCurrentRecommendedOrderId:
      return {
        ...state,
        recommendedOrderId: action.payload.recommendedOrderId,
      };

    case CartActionsTypes.ResetCart:
      return {
        ...state,
        cart: [],
        defaultCart: [],
        categoryCart: [],
      };

    case CartActionsTypes.ResetRecommendedOrderInCart:
      return {
        ...state,
        recommendedCart: [],
        cart: [],
      };

    case CartActionsTypes.FillUpCartsMinimalLimitSuccess:
      return {
        ...state,
        defaultCart: [...action.payload],
        cart:
          state.recommendedCart && state.recommendedCart.length ? [...state.recommendedCart, ...state.defaultCart] : [...action.payload],
        changesRecommendedCart: false,
      };

    case CartActionsTypes.LoadCartCategoryTreeSuccess:
      return {
        ...state,
        categoryCart: [...action.payload],
      };

    case CartActionsTypes.ResetCartCategoryTree:
      return {
        ...state,
        categoryCart: [],
      };

    case CartActionsTypes.FillCartsByFileProgressSuccess:
      return {
        ...state,
        fillByFileProgress: action.payload,
      };

    case CartActionsTypes.FillCartsByFileCurrentSuccess:
      return {
        ...state,
        fillByFileProgress: action.payload,
      };

    case CartActionsTypes.FillCartsByFileProgressReset:
      return {
        ...state,
        fillByFileProgress: null,
      };

    case CartActionsTypes.LoadHistoryOrderItemsSuccess:
      const ordersHistoryMap: Record<string, OrderHistoryItem[]> = action.payload.reduce((rv, x) => {
        (rv[x.product.materialId] = rv[x.product.materialId] || []).push(x);
        return rv;
      }, {});
      return {
        ...state,
        ordersHistoryMap: ordersHistoryMap,
      };

    case CartActionsTypes.ToggleProductFavoriteSuccess:
      return {
        ...state,
        cartProducts: state.cartProducts.map((item) =>
          item.materialId === action.payload.materialId ? { ...item, isFavorite: !item.isFavorite } : item
        ),
      };

    default:
      return state;
  }
}

function setDefaultStock(product: Product) {
  const CODE_MEGAPOLIS = '7700';
  let stock: Stock[] = [];
  const findGroup = (product.groupedStock || []).find((group) => group.distributorCode === CODE_MEGAPOLIS);

  if (findGroup) {
    stock = findGroup.stock;
  } else {
    stock = product.stock;
  }

  const sortStock = stock.sort((a, b) => {
    return a.baseQuantUnit !== b.baseQuantUnit ? (a.baseQuantUnit < b.baseQuantUnit ? -1 : 1) : 0;
  });

  return sortStock[0];
}
