import { AwardImage } from './award-image';
import { AuctionItem } from './auction-item';
import * as moment from 'moment';
import { Character } from '@Mesh/core/models/character';
import { Deserializable } from '@Mesh/core/models/deserializable';

export const AWARD_AUCTION_DATE_PATTERN = 'DD.MM.YYYY hh:mm:ss';

const ENDING_SOON_HOURS_LIMIT = 8;

export class Award implements Deserializable {
  get delivery_days_formatted() {
    return (this.delivery_days ? this.delivery_days : 0) + ' ' + this.declOfNum(this.delivery_days, ['день', 'дня', 'дней']);
  }

  get points_formatted() {
    return (this.points ? this.points : 0) + ' ' + this.declOfNum(this.points, ['балл', 'балла', 'баллов']);
  }

  id?: number;
  name?: string;
  points?: number;
  description?: string;
  delivery_days?: number;
  main_image?: string;
  target_image?: string;
  properties?;
  images?: AwardImage[];
  likes_count?: number;
  dislikes_count?: number;
  liked?: boolean;
  disliked?: boolean;
  character?: Character;
  auctions?: Array<AuctionItem>;
  on_auction?: number;
  bought?: boolean = false;
  requestError?: any;

  get mainImage() {
    return this.previewsList[0].image;
  }

  get previewsList() {
    return this.character
      ? [{ image: this.character.poster }]
      : this.images && this.images.length
      ? this.images
      : [{ image: this.main_image }];
  }

  get auctionInfo(): AuctionItem {
    return (this.auctions || [])[0];
  }

  get isAuction(): boolean {
    return !!this.auctionInfo;
  }

  get maxBet() {
    // @ts-ignore
    if (this.auctionInfo) {
      const max_bet = ((this.auctionInfo || {}).max_bet || {}).points;
      return max_bet || this.points || 0;
    } else {
      return this.points || 0;
    }
  }

  get maxBetUser() {
    return this.auctionInfo && this.auctionInfo.max_bet && this.auctionInfo.max_bet.user;
  }

  get endDate() {
    return this.auctionInfo ? moment(this.auctionInfo.end_date, AWARD_AUCTION_DATE_PATTERN).utc() : moment(new Date(0)).utc();
  }

  get endsIn() {
    // const end = moment(this.endDate, AWARD_AUCTION_DATE_PATTERN).utc();
    // const now = moment(new Date()).utc();
    // const diff = end.diff(now);
    // @ts-ignore
    return this.auctionInfo && moment.duration(this.auctionInfo.left_to_finish, 'second').format('D[д.] H[ч.] m[м.]');
  }

  get startIn() {
    // @ts-ignore
    return this.auctionInfo && moment.duration(this.auctionInfo.left_before_start, 'second').format('D[д.] H[ч.] m[м.]');
  }

  get endingSoon() {
    let marker = moment(new Date()).utc().add(ENDING_SOON_HOURS_LIMIT, 'hours');
    return this.endDate.isBefore(marker);
  }

  get isEnded() {
    // let marker = moment(new Date()).utc();
    // return this.endDate.isBefore(marker);
    return this.auctionInfo && this.auctionInfo.left_before_start === 0 && this.auctionInfo.left_to_finish === 0;
  }

  get isStarted() {
    return this.auctionInfo && this.auctionInfo.left_before_start === 0 && this.auctionInfo.left_to_finish !== 0;
  }

  declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input?.images) {
      const images: AwardImage[] = [];
      input.images.map((data) => {
        images.push(new AwardImage().deserialize(data));
      });
      this.images = images;
    }
    return this;
  }
}
