import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Documents, Client, User, Outlet } from './organization.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrganizationType } from './types/organization.type';

@Component({
  selector: 'iqretail-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() client: Client;
  @Input() documents: Documents[] = [];
  @Input() users: User[] = [];
  @Input() totalUsers = 0;
  @Input() currentUser: User;
  @Input() activeOutlet: Outlet;
  @Input() totalDocs = 0;
  @Input() totalUsersPages: number;
  @Input() usersCurrentPage: number;

  @Input() isAgent = false;

  @Output() loadAllUsers = new EventEmitter();
  @Output() loadUsersPages = new EventEmitter();
  @Output() showUserHistoryPoint = new EventEmitter();

  @Output() openDialog = new EventEmitter<
    'addEmployee' | 'sms-verification' | 'sms-verified' | 'employee-deleted-verified' | 'employee-delete' | 'update-pass' | 'none'
  >();
  @Output() editUser = new EventEmitter<number>();
  @Output() removeUser = new EventEmitter<number>();

  @Output() downloadDocument = new EventEmitter<number>();
  @Output() signDocument = new EventEmitter<number>();

  orgType$ = new BehaviorSubject<OrganizationType>('documents');
  readonly staffModalOpened$ = new BehaviorSubject(false);
  readonly orgModalOpened$ = new BehaviorSubject(false);

  owner = '';
  active = true;

  throttle = 300;
  scrollDistance = 2;
  page = 0;

  private readonly unsubscribe = new Subject();

  ngOnInit(): void {
    this.orgType$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.staffModalOpened$.next(false);
      this.orgModalOpened$.next(false);
    });
  }

  ngOnChanges({ users }: SimpleChanges) {
    if (users && users.currentValue) {
      const list = users.currentValue as User[];
      const data = list.find((us) => us.type === 'OWNER');
      this.owner = (data && data.name) || '';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  openModal() {
    if (this.orgType$.value === 'staff') {
      this.staffModalOpened$.next(true);
    } else {
      this.orgModalOpened$.next(true);
    }
  }

  loadStaffPages() {
    this.usersCurrentPage += 1;
    if (this.totalUsersPages >= this.usersCurrentPage) {
      this.loadUsersPages.emit(this.usersCurrentPage);
    }
  }

  toggle() {
    this.active = !this.active;
  }

  closeModal() {
    this.staffModalOpened$.next(false);
    this.orgModalOpened$.next(false);
  }

  setOrgType(orgType: OrganizationType): void {
    this.orgType$.next(orgType);
  }

  addUserModalOpen() {
    this.openDialog.emit('addEmployee');
  }
}
