import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task } from '../tasks.model';

@Component({
  selector: 'iqretail-tasks-content',
  templateUrl: './tasks-content.component.html',
  styleUrls: ['./tasks-content.component.scss'],
})
export class TasksContentComponent {
  _tasks: Task[] = [];

  get tasks(): Task[] {
    return this._tasks;
  }
  @Input() set tasks(value: Task[]) {
    this._tasks = value;
  }
  @Input() isTab = false;
  @Input() totalTasks: number;
  @Input() scrollDistance: number;
  @Input() throttle: number;

  @Output() scrollEnd = new EventEmitter();
  @Output() navToTaskDetails = new EventEmitter<Task>();

  constructor() {}

  onScrollEnd() {
    this.scrollEnd.emit();
  }
}
