export const getDividers = <T>(arr: T[], dateProperty: string) => {
  const dates: { date: Date; index: number }[] = [];
  arr.forEach((item, index) => {
    if (item[dateProperty]) {
      const date = new Date(item[dateProperty]);

      const existedDate = dates.find((el) => sameDay(el.date, date));
      if (existedDate) {
        if (index < existedDate.index) {
          existedDate.index = index;
        }
      } else {
        dates.push({ date, index });
      }
    }
  });
  const result: { [key: number]: Date } = {};

  dates.forEach((el) => {
    result[el.index] = el.date;
  });

  return result;
};

const sameDay = (first: Date, second: Date) => {
  return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
};

export function onChangesHelper<T>(arr: T[], changedValue: { currentValue?: T[] }) {
  return changedValue && changedValue.currentValue && changedValue.currentValue.length ? changedValue.currentValue : arr;
}
