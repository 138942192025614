import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '../../company/company.component';
import { HistoryData, OrderDetails, Outlet } from '../active-orders.models';
import { DelayedOrder } from '../delayed-order/delayed-order';

@Component({
  selector: 'iqretail-active-orders-content',
  templateUrl: './active-orders-content.component.html',
  styleUrls: ['./active-orders-content.component.scss'],
})
export class ActiveOrdersContentComponent {
  _orders: HistoryData[] = [];

  get orders(): HistoryData[] {
    return this._orders;
  }
  @Input() set orders(value: HistoryData[]) {
    this._orders = value;
  }
  @Input() outlet: Outlet;
  @Input() isTab = false;
  @Input() orderDetails: OrderDetails[] = [];
  @Input() scrollDistance: number;
  @Input() throttle: number;
  @Input() selectedOrderId: string;
  @Input() set delayedOrders(value: DelayedOrder[]) {
    this._delayedOrders = value;
  }
  get delayedOrders(): DelayedOrder[] {
    return this._delayedOrders;
  }
  private _delayedOrders: DelayedOrder[];
  @Input() orderUsers: Record<number, User>;
  @Input() ordersProducts: Record<number, any>;

  @Output() scrollEnd = new EventEmitter();
  @Output() orderClicked = new EventEmitter<string>();
  @Output() navToActiveOrdersDetails = new EventEmitter<{ data: string }>();
  @Output() confirmedDelivery = new EventEmitter<string>();
  @Output() editedDelayedOrder: EventEmitter<any> = new EventEmitter();
  @Output() deletedDelayedOrder: EventEmitter<any> = new EventEmitter();
  @Output() historyDelayedOrder: EventEmitter<any> = new EventEmitter();
  @Output() requestInvoiceDelayedOrder: EventEmitter<any> = new EventEmitter();

  onScrollEnd() {
    this.scrollEnd.emit();
  }

  onConfirmedDelivery(docNumber: string) {
    this.confirmedDelivery.emit(docNumber);
  }

  onEditedDelayedOrder(delayedOrder: DelayedOrder) {
    this.editedDelayedOrder.emit(delayedOrder);
  }

  onDropedDelayedOrder(delayedOrderId: number) {
    this.deletedDelayedOrder.emit(delayedOrderId);
  }

  onShowHistoryDelayedOrder(delayedOrder: DelayedOrder) {
    this.historyDelayedOrder.emit(delayedOrder);
  }

  onRequestInvoiceDelayedOrder(delayedOrderId: number) {
    this.requestInvoiceDelayedOrder.emit(delayedOrderId);
  }
}
