import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Outlet, User } from '../../organization.model';

@Component({
  selector: 'iqretail-staff-content',
  templateUrl: './staff-content.component.html',
  styleUrls: ['./staff-content.component.scss'],
})
export class StaffContentComponent {
  _users: User[] = [];

  get users(): User[] {
    return this._users;
  }
  @Input() set users(value: User[]) {
    this._users = value;
  }
  @Input() isTab = false;
  @Input() currentUser: User;
  @Input() activeOutlet: Outlet;
  @Input() closeModeShowPeople = 3;
  @Input() totalUsers: number;
  @Input() scrollDistance: number;
  @Input() throttle: number;
  @Output() scrollEnd = new EventEmitter();

  @Output() editUser = new EventEmitter<number>();
  @Output() removeUser = new EventEmitter<number>();
  @Output() showUserHistoryPoint = new EventEmitter<User>();

  onScrollEnd() {
    this.scrollEnd.emit();
  }
}
