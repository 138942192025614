import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-active-orders-placeholder',
  templateUrl: './active-orders-placeholder.component.html',
  styleUrls: ['./active-orders-placeholder.component.scss'],
})
export class ActiveOrdersPlaceholderComponent {
  @Output() navigateToCatalog = new EventEmitter();
}
