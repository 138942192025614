import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { API_PLAN_URL } from '@Env/environment';
import { IPageableList } from '@Mesh/core/models/pageable';
import { Plan } from '@Mesh/core/models/plan';
import { CategoryMain, CategotyTree, IQLCategory } from '@Mesh/core/models/categories';
import { Product } from '@Mesh/core/models/product';
import { Subject } from 'rxjs/Subject';
import { Outlet } from '@Mesh/core/models';
import { ProductCriteria } from '@Mesh/core/models/request/product-criteria';
import { objectToParams } from '@Mesh/shared/helpers/object.helpers';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private readonly http: HttpClient) {}

  headerBgcSubject = new Subject<string>();
  readonly connectionStringPlan = API_PLAN_URL;

  getCategoriesMain(addressSapId: number): Observable<Array<CategoryMain>> {
    const params = objectToParams({ addressSapId });
    return this.http.get<Array<CategoryMain>>(`${this.connectionStringPlan}/v1/category/main`, { params }).pipe(
      map((categories: Object) => {
        return Object.keys(categories).map((k) => ({
          categoryNameId: k,
          categoryName: categories[k],
        }));
      })
    );
  }

  getCategoriesTree(addressSapId: number) {
    return this.getCategoriesMain(addressSapId).pipe(
      switchMap((mains) => {
        return forkJoin(
          mains.map((main) => {
            return this.http
              .get<IQLCategory[]>(
                `${this.connectionStringPlan}/v2/category/categoryManufactureBrands?materialGroupNameId=${main.categoryNameId}&addressSapId=${addressSapId}`
              )
              .pipe(
                map((results) => {
                  main.categories = results;
                  return main;
                })
              );
          })
        );
      })
    );
  }

  getCategoryTree(addressSapId: number, onlyFavorites: boolean): Observable<CategotyTree[]> {
    const params = objectToParams({ addressSapId, onlyFavorites });
    return this.http.get<CategotyTree[]>(`${this.connectionStringPlan}/v2/category/tree`, { params });
  }

  getCategoryTreeByPlanId(addressSapId: number, planId: number, onlyFavorites: boolean) {
    const params = objectToParams({ addressSapId, planId, onlyFavorites });
    return this.http.get<CategotyTree[]>(`${this.connectionStringPlan}/v2/category/tree`, { params }).pipe(
      map((results) => {
        return results;
      })
    );
  }

  getCategoriesTreeByPlanId(addressSapId: number, planId: number) {
    return this.http
      .get<IQLCategory[]>(
        `${this.connectionStringPlan}/v2/category/categoryManufactureBrands?planId=${planId}&addressSapId=${addressSapId}`
      )
      .pipe(
        map((results) => {
          return results;
        })
      );
  }

  getPlanTop(outlet: Outlet): Observable<Plan[]> {
    const queryString = `/v1/progress/material?addressSapId=${outlet.addressSapId}`;
    return this.http.get<Plan[]>(`${this.connectionStringPlan}${queryString}`);
  }

  getPlanSale(outlet: Outlet): Observable<Plan[]> {
    const queryString = `/v1/bonus?addressSapId=${outlet.addressSapId}`;
    return this.http.get<Plan[]>(`${this.connectionStringPlan}${queryString}`);
  }

  getChartSampleData() {
    return this.http.get('assets/data/charts.json').pipe(map((res) => res));
  }

  getProductByParam(params: any): Observable<IPageableList<Product>> {
    const newParams = { ...params, showOnlyInOrder: true };
    return this.http.get<IPageableList<Product>>(`${this.connectionStringPlan}/v1/product/`, { params: newParams });
  }

  getProducts(criteria: ProductCriteria): Observable<IPageableList<Product>> {
    const params = objectToParams(criteria);
    return this.http.get<IPageableList<Product>>(`${this.connectionStringPlan}/v1/product/`, { params });
  }

  toggleProductFavorite(materialId: number): Observable<Product> {
    return this.http.put<Product>(`${this.connectionStringPlan}/v1/product/${materialId}/favorites`, {});
  }
}
