import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ModalModule,
  TabsModule,
  BsDropdownModule,
  AccordionModule,
  AlertModule,
  ButtonsModule,
  CollapseModule,
  ProgressbarModule,
  TooltipModule,
  TypeaheadModule,
} from 'ngx-bootstrap';

import { HistoryComponent } from './history.component';
import { HistoryContentComponent } from './history-content/history-content.component';
import { OrdersComponent } from './orders/orders.component';
import { TasksComponent } from './tasks/tasks.component';
import { HistoryPlaceholderComponent } from './history-placeholder/history-placeholder.component';
import { FinanceComponent } from './finance/finance.component';
import { HistoryTitleComponent } from './history-title/history-title.component';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BonusHistoryComponent } from './bonus-history/bonus-history.component';
import { BonusHistoryItemComponent } from './bonus-history/bonus-history-item/bonus-history-item.component';
import { PositivePipe } from './bonus-history/positive.pipe';
import { OrdersItemComponent } from './orders/orders-item/orders-item.component';
import { OrderDetailModule } from './orders/ordrer-detail/ordrer-detail.module';
import { HistoryWidgetComponent } from './components/history-widget/history-widget.component';

@NgModule({
  declarations: [
    HistoryComponent,
    HistoryContentComponent,
    OrdersComponent,
    TasksComponent,
    HistoryPlaceholderComponent,
    FinanceComponent,
    HistoryTitleComponent,
    BonusHistoryComponent,
    BonusHistoryItemComponent,
    PositivePipe,
    OrdersItemComponent,
    HistoryWidgetComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CollapseModule,
    ProgressbarModule,
    TooltipModule,
    TypeaheadModule,
    InfiniteScrollModule,
    OrderDetailModule,
  ],
  exports: [
    HistoryComponent,
    HistoryContentComponent,
    HistoryPlaceholderComponent,
    OrdersComponent,
    TasksComponent,
    BonusHistoryComponent,
    BonusHistoryItemComponent,
    HistoryWidgetComponent,
  ],
  providers: [],
  entryComponents: [],
})
export class HistoryModule {}
