import { Component, Input } from '@angular/core';
import { Task } from '@Mesh/core/models/task';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-task-card',
  templateUrl: './training-task-card.component.html',
  styleUrls: ['./training-task-card.component.scss'],
  animations: [
    trigger('confirmState', [
      state('1', style({ opacity: '1', background: 'linear-gradient(0deg, #2ECC71 0%, #2ECC71 100%), #FFF' })),
      state('0', style({ opacity: '0', background: 'linear-gradient(0deg, #2ECC71 0%, #2ECC71 100%), #FFF' })),
      transition('0 <=> 1', animate('700ms ease')),
    ]),
  ],
})
export class TrainingTaskCardComponent {
  @Input()
  task?: Task;

  @Input()
  disabled: boolean = false;

  @Input()
  status?: string;

  onCLick() {}
}
