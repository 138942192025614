import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { taskSelectors } from '@Mesh/store/task/task.selectors';
import { Observable } from 'rxjs';
import { Outlet } from '@Mesh/core/models';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { bonusSelectors } from '@Mesh/store/bonus';
import { financeSelectors } from '@Mesh/store/finance/finance.selectors';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

export const IMAGE_CLOUD_URL = 'https://static.cloud.retail.iql.ru';

@Component({
  selector: 'app-avatar-widget',
  templateUrl: './avatar-widget.component.html',
  styleUrls: ['./avatar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarWidgetComponent {
  totalActiveTasks$ = this.store.select(taskSelectors.getActiveTotalCount);
  activeOutlet$: Observable<Outlet> = this.store.select(outletSelectors.getActive);
  user$ = this.store.select(loginSelectors.selectUser);
  outletBonusesSum$ = this.store.select(bonusSelectors.getOutletBonusesSum);
  outletAwaitingSum$ = this.store.select(bonusSelectors.getAwaitingSum);
  clientBonusData$ = this.store.select(bonusSelectors.getClientBonus);
  clientBonusPredictTotalAmount$ = this.store.select(bonusSelectors.getClientBonusPredictTotalAmount);
  totalModerationTasks$ = this.store.select(taskSelectors.getModerationTotalCount);
  bonuses$ = this.store.select(bonusSelectors.getOutletBonuses);
  completedPlan$ = this.store.select(bonusSelectors.getComletedOutletBonuses);
  userFinanceSum$ = this.store.select(financeSelectors.getFinanceSum);
  userFinanceInfo$ = this.store.select(financeSelectors.getUserFinanceInformation);
  readonly avatarUrl$ = this.user$.pipe(
    map((user) => {
      return IMAGE_CLOUD_URL + user.avatar;
    })
  );
  constructor(private store: Store<AppState>, private router: Router) {}

  navigateToProfile() {
    this.router.navigate(['/profile']);
  }
}
