import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Client, IOutlet, Outlet } from './company.models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

export interface UserFinanceInformation {
  buText: string;
  payDate: string;
  sumFp: number;
}

export interface User {
  addresses: string;
  avatar: string;
  client: Client;
  email: string;
  id: number;
  name: string;
  permissions: { addressSapId: number; permissions: string[] }[] | null;
  phone: string;
  position: string;
  possibleClientSapId: number;
  possibleEmail: string;
  possiblePhone: string;
  status: string;
  type: 'OWNER' | 'EMPLOYEE' | 'MANAGER';
}

export interface FinanceSum {
  clientSapId: number;
  addressSapId: number;
  plan: number;
  limit: number;
  debts: number;
  debtswa: number;
  arrears: number;
  balance: number;
}
export interface BalanceByDistributor {
  kkbText: string;
  debLimit: number;
  debts: number;
  plan: number;
  debtswa: number;
  delayed: number;
  balance: number;
  arrears?: number;
}
export interface UserFinanceSumCard {
  state?: string;
  gradient: string;
  text: string;
  amount: number;
  currencyColor: string;
}

const enterTransition = transition(':enter', [
  style({
    opacity: 0,
  }),
  animate(
    '700ms',
    style({
      opacity: 1,
    })
  ),
]);

const leaveTrans = transition(':leave', [
  style({
    opacity: 1,
  }),
  animate(
    '700ms',
    style({
      opacity: 0,
    })
  ),
]);

const fadeIn = trigger('fadeIn', [enterTransition]);

const fadeOut = trigger('fadeOut', [leaveTrans]);

@Component({
  selector: 'iqretail-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  animations: [
    fadeIn,
    fadeOut,
    trigger('animateFinanceSum', [
      state('state1', style({ opacity: 1 })),
      state('state2', style({ opacity: 1 })),
      state('state3', style({ opacity: 1 })),
      transition('state1 => state2', [
        style({
          opacity: 0.5,
        }),
        animate('0.7s linear'),
        style({
          opacity: 0.5,
        }),
      ]),
      transition('state2 => state3', [
        style({
          opacity: 0.5,
        }),
        animate('0.7s linear'),
        style({
          opacity: 0.5,
        }),
      ]),
      transition('state3 => state1', [
        style({
          opacity: 0.5,
        }),
        animate('0.7s linear'),
        style({
          opacity: 0.5,
        }),
      ]),
    ]),
  ],
})
export class CompanyComponent implements OnDestroy {
  @Input()
  set outlet(value: IOutlet) {
    console.log(value, 'IOutlet');
    this._outlet = new Outlet(value);
    console.log(this._outlet, 'Outlet');
  }
  @Input() set userFinanceSum(userFinanceSum: BalanceByDistributor) {
    this._userFinanceSum = userFinanceSum;
    if (!this.userFinanceSum) {
      return;
    }

    if (!this.interval) {
      this.callInterval();
    }

    if (this.userFinanceSumCard.length === 0) {
      if (this.userFinanceSum.debLimit > 0) {
        this.userFinanceSumCard.push({
          state: 'state1',
          gradient: 'linear-gradient(86.54deg, #1B34D9 1.02%, #55D6FF 100.31%)',
          text: 'Остаток кредитного лимита',
          amount: this.userFinanceSum.balance || 0,
          currencyColor: '#55D6FF',
        });
        this.userFinanceSumCard.push({
          state: 'state2',
          gradient: 'linear-gradient(89.98deg, #FF8C4D 0%, #FFA14C 99.97%)',
          text: 'Общая ДЗ',
          amount: this.userFinanceSum.debLimit > 0 && this.userFinanceSum.debtswa > 0 ? this.userFinanceSum.debtswa : 0,
          currencyColor: '#FFA14C',
        });
        this.userFinanceSumCard.push({
          state: 'state3',
          gradient: 'linear-gradient(90deg, #FF4D4E 0%, #FF684D 100%)',
          text: 'Общая ПДЗ',
          amount:
            this.userFinanceSum.debLimit > 0 && this.userFinanceSum.arrears && this.userFinanceSum.arrears > 0
              ? this.userFinanceSum.arrears
              : 0,
          currencyColor: '#FF684D',
        });
      } else {
        this.userFinanceSumCard.push({
          state: 'state1',
          gradient: 'rgba(46, 213, 115, 1)',
          text: 'Общая предоплата',
          amount: this.userFinanceSum.balance || 0,
          currencyColor: '#55D6FF',
        });
      }
    }

    if (this.userFinanceSum) {
      this.financeSumCardSub$.next(this.userFinanceSumCard[this.count]);
      this.count++;
    }
  }

  get userFinanceSum(): BalanceByDistributor {
    return this._userFinanceSum;
  }
  @Input() tasks = 0;
  @Input() onModeration = 0;
  @Input() client: Client;
  @Input() started = 0;
  @Input() completed = 0;
  @Input() outletBonus = 0;
  @Input() outletPredictBonus = 0;
  @Input() clientBonus = 0;
  @Input() clientPredictBonus = 0;
  @Input() currentUser: User;
  @Input() userFinanceInfo: UserFinanceInformation;
  @Input() userAvatarUrl?: string;

  @Input() isAgent = false;

  @Output() navigateToProfile = new EventEmitter();

  userFinanceSumCard: UserFinanceSumCard[] = [];

  _outlet: Outlet;

  private _userFinanceSum: BalanceByDistributor;

  imgUrl = '123';
  sub = new Subscription();
  financeSumSub = new Subscription();
  valuesToggleSubject = new BehaviorSubject<boolean>(true);
  valuesToggle = this.valuesToggleSubject;
  financeSumCardSub$ = new BehaviorSubject<UserFinanceSumCard>(null);
  financeSumCard$ = this.financeSumCardSub$;
  interval;
  count = 0;
  animationState = 'state1';

  callInterval() {
    this.interval = setInterval(() => this.showFinanceSumCard(), 4000);
  }

  toggle = () => {
    this.valuesToggleSubject.next(!this.valuesToggleSubject.value);
  };

  showFinanceSumCard() {
    if (this._userFinanceSum) {
      if (this.count > this.userFinanceSumCard.length - 1) {
        this.count = 0;
      }
      this.toggle();
      this.financeSumCardSub$.next(this.userFinanceSumCard[this.count]);
      this.animationState = this.userFinanceSumCard[this.count].state;
      this.count++;
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  onNavigateToProfile(): void {
    this.navigateToProfile.emit();
  }

  changeBackgroundImage() {
    const imgUrl = this.userAvatarUrl || this.imgUrl + this.currentUser.avatar;

    return {
      'background-image': `linear-gradient(0.01deg, #000000 0.01%, rgba(0, 0, 0, 0) 58.96%), linear-gradient(0.01deg, #000000 0.01%, rgba(0, 0, 0, 0) 58.96%), linear-gradient(0.01deg, #000000 0.01%, rgba(0, 0, 0, 0) 58.96%), url(${imgUrl})`,
    };
  }

  changeBackgroundImageTablet() {
    const imgUrl = this.userAvatarUrl || this.imgUrl + this.currentUser.avatar;

    return { 'background-image': `url(${imgUrl})` };
  }
}
