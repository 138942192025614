import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { User } from '../company/company.component';
import { Outlet, HistoryData, OrderDetails } from './active-orders.models';
import { DelayedOrder } from './delayed-order/delayed-order';

@Component({
  selector: 'iqretail-active-orders',
  templateUrl: './active-orders.component.html',
  styleUrls: ['./active-orders.component.scss'],
})
export class ActiveOrdersComponent {
  @Input() orders: HistoryData[] = [];
  @Input() outlet: Outlet;
  @Input() orderDetails: OrderDetails[] = [];
  @Input() totalActiveOrdersPages: number;
  @Input() activeOrdersCurrentPage: number;
  @Input() activeOrdersTotalCount: number;
  @Input() selectedOrderId: string;
  @Input() delayedOrders: DelayedOrder[];
  @Input() orderUsers: Record<number, User>;
  @Input() ordersProducts: Record<number, any>;

  @Output() getOrderDetails = new EventEmitter<string>();
  @Output() loadAllActiveOrders = new EventEmitter();
  @Output() loadActiveOrdersPages = new EventEmitter();
  @Output() navtoActiveOrders = new EventEmitter();
  @Output() navigateToCatalog = new EventEmitter();
  @Output() confirmedDelivery = new EventEmitter<string>();
  @Output() editedDelayedOrder: EventEmitter<any> = new EventEmitter();
  @Output() deletedDelayedOrder: EventEmitter<any> = new EventEmitter();
  @Output() historyDelayedOrder: EventEmitter<any> = new EventEmitter();
  @Output() requestInvoiceDelayedOrder: EventEmitter<any> = new EventEmitter();

  @ViewChild('modal') modal: ElementRef;

  public bsModalRef: BsModalRef;
  active = true;

  throttle = 300;
  scrollDistance = 2;
  page = 0;

  constructor(private modalService: BsModalService) {}

  openModal() {
    this.loadAllActiveOrders.emit();
    this.bsModalRef = this.modalService.show(this.modal, Object.assign({}, { class: 'gray modal-lg active-orders-modal' }));
    this.bsModalRef.content.onClose.subscribe((result) => {
      console.log('results277', result);
    });
  }

  onScrollEnd() {
    this.activeOrdersCurrentPage += 1;
    if (this.totalActiveOrdersPages >= this.activeOrdersCurrentPage) {
      this.loadActiveOrdersPages.emit(this.activeOrdersCurrentPage);
    }
  }

  onNavtoActiveOrders() {
    this.navtoActiveOrders.emit();
  }

  onConfirmedDelivery(docNumber: string) {
    this.confirmedDelivery.emit(docNumber);
  }

  toggle() {
    this.active = !this.active;
  }

  onEditedDelayedOrder(delayedOrder: DelayedOrder) {
    this.editedDelayedOrder.emit(delayedOrder);
  }

  onDropedDelayedOrder(delayedOrderId: number) {
    this.deletedDelayedOrder.emit(delayedOrderId);
  }

  onShowHistoryDelayedOrder(delayedOrder: DelayedOrder) {
    this.historyDelayedOrder.emit(delayedOrder);
  }

  onRequestInvoiceDelayedOrder(delayedOrderId: number) {
    this.requestInvoiceDelayedOrder.emit(delayedOrderId);
  }
}
