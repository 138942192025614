import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Balance, ClientHistoryBonus, HistoryData, OrderDetails, Outlet, PayHistory, Task, UserHistoryBonus } from './history.models';
import { ModeType } from './mode.type';

const titleBase = 'Развернуть';

@Component({
  selector: 'iqretail-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnChanges {
  public bsModalRef: BsModalRef;

  @Input() historyData: HistoryData[] = [];
  @Input() outlet: Outlet;
  @Input() tasks: Task[];
  @Input() orderDetails: OrderDetails[] = [];
  @Input() totalAmountHistory = 0;
  @Input() totalAmountTasks = 0;
  @Input() payHistory: PayHistory[] = [];
  @Input() balance: Balance[] = [];
  @Input() bonusHistory: UserHistoryBonus[] = [];
  @Input() bonusHistoryClient: ClientHistoryBonus[] = [];

  @Input() totalPayHistory = 0;
  @Input() selectedOrderId?: string;
  @Input() totalOrderHistoryPages: number;
  @Input() totalTasksPages: number;
  @Input() totalPayHistoryPages: number;
  @Input() historyOrdersCurrentPage: number;
  @Input() historyTasksCurrentPage: number;
  @Input() historyFinanceCurrentPage: number;
  @Input() historyBonusesCurrentPage = 1;
  @Input() historyBonusesClientCurrentPage = 1;

  @Input() isAgent = false;

  @Output() getDetails = new EventEmitter<string>();
  @Output() loadAllOrdersHistory = new EventEmitter();
  @Output() loadAllTasksHistory = new EventEmitter();
  @Output() loadAllPayHistory = new EventEmitter();
  @Output() loadOrdersHistoryPages = new EventEmitter();
  @Output() loadHistoryTasksPages = new EventEmitter();
  @Output() loadPayHistoryPages = new EventEmitter();
  @Output() loadBonusHistoryPages = new EventEmitter();
  @Output() loadBonusHistoryClientPages = new EventEmitter();
  @Output() navToHistoryOrdersDetails = new EventEmitter<{ data: string }>();
  @Output() navigateToHistory = new EventEmitter();

  modeType: ModeType = 'orders';

  showMoreTitleHistory = titleBase;
  showMoreTitleTasks = titleBase;
  showMoreFinanceItems = titleBase;

  @ViewChild('modal') modal: ElementRef;

  constructor(private modalService: BsModalService) {}

  throttle = 300;
  scrollDistance = 2;
  page = 0;

  count = 0;
  itemsPerPage = 5;

  ngOnChanges({ totalAmountHistory, totalAmountTasks, tasks, historyData, totalPayHistory, payHistory }: SimpleChanges) {
    if (
      totalAmountHistory &&
      totalAmountHistory.currentValue &&
      ((historyData && historyData.currentValue && historyData.currentValue.length) || (this.historyData && this.historyData.length))
    ) {
      const notEmptyHistory = ((historyData && historyData.currentValue && historyData.currentValue.length) ||
        (this.historyData && this.historyData.length)) as HistoryData[];
      const numberOfOrdersHistory = notEmptyHistory.length <= 3 ? notEmptyHistory.length : 3;
      const amount = totalAmountHistory.currentValue - numberOfOrdersHistory;
      this.showMoreTitleHistory = `${titleBase} (${amount})`;
    }
    if (
      totalAmountTasks &&
      totalAmountTasks.currentValue &&
      ((tasks && tasks.currentValue && tasks.currentValue.length) || (this.tasks && this.tasks.length))
    ) {
      const notEmptyTasks = ((tasks && tasks.currentValue && tasks.currentValue.length) || (this.tasks && this.tasks.length)) as Task[];
      const numberOfTasks = notEmptyTasks.length <= 3 ? notEmptyTasks.length : 3;
      const amount = totalAmountTasks.currentValue - numberOfTasks;
      this.showMoreTitleTasks = `${titleBase} (${amount})`;
    }
    if (
      totalPayHistory &&
      totalPayHistory.currentValue &&
      ((payHistory && payHistory.currentValue && payHistory.currentValue.length) || (this.payHistory && this.payHistory.length))
    ) {
      const notEmptyFinanceItems = ((payHistory && payHistory.currentValue && payHistory.currentValue.length) ||
        (this.payHistory && this.payHistory.length)) as PayHistory[];
      const numberOfFinanceItems = notEmptyFinanceItems.length <= 3 ? notEmptyFinanceItems.length : 3;
      const amount = totalPayHistory.currentValue - numberOfFinanceItems;
      this.showMoreFinanceItems = `${titleBase} (${amount})`;
    }
  }

  openModal() {
    this.loadAllData();
    this.bsModalRef = this.modalService.show(this.modal, Object.assign({}, { class: 'gray modal-lg history-modal' }));
  }

  openDetails(docNumber: string): void {
    this.getDetails.emit(docNumber);
  }

  loadAllData() {
    if (this.modeType === 'orders') {
      this.historyOrdersCurrentPage += 1;
      if (this.totalOrderHistoryPages >= this.historyOrdersCurrentPage) {
        this.loadOrdersHistoryPages.emit(this.historyOrdersCurrentPage);
      }
    } else if (this.modeType === 'tasks') {
      this.historyTasksCurrentPage += 1;
      if (this.totalTasksPages >= this.historyTasksCurrentPage) {
        this.loadHistoryTasksPages.emit(this.historyTasksCurrentPage);
      }
    } else if (this.modeType === 'finance') {
      this.count++;
      if (this.count === 1) {
        this.itemsPerPage = 10;
        this.loadPayHistoryPages.emit(this.itemsPerPage);
      } else {
        if (this.count > 1 && this.itemsPerPage > 5) {
          this.loadPayHistoryPages.emit(this.itemsPerPage + 5);
        }
      }
    } else if (this.modeType === 'bonuses') {
      this.loadBonusHistoryPages.emit(this.historyBonusesCurrentPage++);
    } else if (this.modeType === 'bonusesClient') {
      this.loadBonusHistoryClientPages.emit(this.historyBonusesClientCurrentPage++);
    }
  }

  onModeTypeChange(type: ModeType) {
    this.modeType = type;
  }
}
