import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { Task } from './tasks.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'iqretail-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tasks: Task[];
  @Input() moderationTasks: Task[];
  @Input() totalTasks = 0;
  @Input() totalModerationTasks = 0;
  @Input() totalTasksPages = 0;

  @Input() isAgent = false;

  @Output() loadAllTasks = new EventEmitter();
  @Output() loadAllModerationTasks = new EventEmitter();
  @Output() loadTasksPages: EventEmitter<number> = new EventEmitter<number>();
  @Output() loadModerationTasksPages: EventEmitter<number> = new EventEmitter<number>();
  @Output() navToAllTasks = new EventEmitter();
  @Output() navToTaskDetails = new EventEmitter<Task>();

  @ViewChild('modal') modal: ElementRef;

  title = '';
  subtitle = '';
  mode: string;

  selectedMode$ = new BehaviorSubject<'tasks' | 'moderation'>('tasks');

  bsModalRef: BsModalRef;
  active = true;
  private unsubscribe = new Subject();

  throttle = 300;
  scrollDistance = 2;
  page = 0;

  public newTasks: Task[] = [];
  public allModerationTasks: Task[] = [];

  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    this.selectedMode$.pipe(takeUntil(this.unsubscribe)).subscribe((mode) => {
      this.mode = mode;
      this.changeTitles(mode);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showOnlyNewTasks();
    this.showOnlyModerationTasks();

    this.selectedMode$.pipe(take(1)).subscribe((mode) => {
      this.mode = mode;
      this.changeTitles(mode);
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  toggle() {
    this.active = !this.active;
  }

  openModal() {
    this.bsModalRef = this.modalService.show(this.modal, Object.assign({}, { class: 'gray modal-lg task-modal' }));
  }

  onScrollEnd() {
    this.page = this.page + 1;
    if (this.totalTasksPages >= this.page) {
      this.loadAllData(this.page);
    }
  }

  loadAllData(page) {
    if (this.selectedMode$.value === 'tasks') {
      this.loadTasksPages.emit(page);
    } else {
      this.loadModerationTasksPages.emit(page);
    }
  }

  toggleMode() {
    const mode: 'tasks' | 'moderation' = this.selectedMode$.value === 'tasks' ? 'moderation' : 'tasks';
    this.selectedMode$.next(mode);
  }

  onNavToAllTasks() {
    this.navToAllTasks.emit(); // todo - implement in app
  }

  onNavToTask(task: Task) {
    this.navToTaskDetails.emit(task);
  }

  showOnlyNewTasks() {
    this.newTasks = this.tasks.filter((task) => {
      return task.notOverdue;
    });
  }

  showOnlyModerationTasks() {
    this.allModerationTasks = this.tasks.filter((task) => {
      return task.onModeration;
    });
  }

  private changeTitles(mode) {
    if (mode === 'tasks') {
      this.title = `Задания (${this.tasks.length})`;
      this.subtitle = 'Модерация';
    } else {
      this.title = `Модерация (${this.allModerationTasks.length})`;
      this.subtitle = 'Задания';
    }
  }
}
