import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingTaskWidgetComponent } from '@Mesh/shared/modules/training-tasks/components/training-task-widget/training-task-widget.component';
import { TrainingTaskCardComponent } from '@Mesh/shared/modules/training-tasks/components/training-task-card/training-task-card.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TaskAllCompletedCardComponent } from '@Mesh/shared/modules/training-tasks/components/training-task-widget/task-all-completed-card/task-all-completed-card.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [TrainingTaskWidgetComponent, TrainingTaskCardComponent, TaskAllCompletedCardComponent],
  exports: [TrainingTaskWidgetComponent],
  imports: [CommonModule, NgxUiLoaderModule, RouterModule],
})
export class TrainingTasksModule {}
