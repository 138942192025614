import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActsType, AgreementsType, Client, Documents, RestType, User } from '../../organization.model';
import { DocType } from '../../types/document.type';

@Component({
  selector: 'iqretail-documents-content',
  templateUrl: './documents-content.component.html',
  styleUrls: ['./documents-content.component.scss'],
})
export class DocumentsContentComponent implements OnChanges {
  @Input() isTab = false;
  @Input() docType: DocType;
  @Input() client: Client;
  @Input() documents: Documents[];
  @Input() owner = '';
  @Input() user: User;
  @Input() totalDocs: number;
  @Input() scrollDistance: number;
  @Input() throttle: number;
  @Output() scrollEnd = new EventEmitter();

  @Output() changeDocType = new EventEmitter<DocType>();
  @Output() changeDocsNumber = new EventEmitter<number>();

  @Output() signDocument = new EventEmitter<number>();
  @Output() downloadDocument = new EventEmitter<number>();

  agreements: Documents[] = [];
  acts: Documents[] = [];
  rest: Documents[] = [];

  restDividers = {};
  actDividers = {};
  agreementsDividers = {};

  nonSignedRestDividers = {};
  nonSignedActDividers = {};
  nonSignedAgreementsDividers = {};

  constructor() {}

  ngOnChanges({ documents }: SimpleChanges) {
    if (documents && documents.currentValue) {
      this.agreements = [];
      this.acts = [];
      this.rest = [];
      (documents.currentValue as Documents[]).forEach((d) => {
        if (Object.keys(RestType).indexOf(d.documentType) !== -1) {
          this.rest.push({ ...d, description: RestType[d.documentType] });
        }
        if (true || Object.keys(AgreementsType).indexOf(d.documentType) !== -1) {
          this.agreements.push({
            ...d,
            description: AgreementsType[d.documentType],
          });
        }
        if (Object.keys(ActsType).indexOf(d.documentType) !== -1) {
          this.acts.push({ ...d, description: ActsType[d.documentType] });
        }
      });

      this.rest = this.sortDocs(this.rest);
      this.acts = this.sortDocs(this.acts);
      this.agreements = this.sortDocs(this.agreements);

      this.restDividers = getDividers(this.rest, 'startDate');
      this.actDividers = getDividers(this.acts, 'startDate');
      this.agreementsDividers = getDividers(this.agreements, 'startDate');

      this.nonSignedRestDividers = addNonSignedData(this.rest);
      this.nonSignedActDividers = addNonSignedData(this.acts);
      this.nonSignedAgreementsDividers = addNonSignedData(this.agreements);
    }
  }

  onScrollEnd() {
    this.scrollEnd.emit();
  }

  selectContentOrganization(type: DocType) {
    this.changeDocType.emit(type);
    switch (type) {
      case 'agreements':
        return this.changeDocsNumber.emit(this.agreements.length);
      case 'acts':
        return this.changeDocsNumber.emit(this.acts.length);
      case 'rest':
        return this.changeDocsNumber.emit(this.rest.length);
    }
  }

  private sortDocs(docs: Documents[]) {
    const data = [...docs];

    return data.sort((a, b) => {
      // Сначала сортируем по isSigned (false сначала)
      if (a.isSigned === false && b.isSigned !== false) {
        return -1; // a идет первым
      } else if (a.isSigned !== false && b.isSigned === false) {
        return 1; // b идет первым
      } else {
        // Если isSigned одинаковый, сортируем по startDate
        return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
      }
    });
  }
}

export const getDividers = <T>(arr: T[], dateProperty: string) => {
  const dates: { date: Date; index: number }[] = [];
  arr.forEach((item, index) => {
    if (item[dateProperty]) {
      const date = new Date(item[dateProperty]);

      const existedDate = dates.find((el) => sameDay(el.date, date));
      if (existedDate) {
        if (index < existedDate.index) {
          existedDate.index = index;
        }
      } else {
        dates.push({ date, index });
      }
    }
  });
  const result: { [key: number]: Date } = {};

  dates.forEach((el) => {
    result[el.index] = el.date;
  });

  return result;
};

const sameDay = (first: Date, second: Date) => {
  return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
};

const addNonSignedData = (arr: Documents[]) => {
  const result: { [key: number]: true } = {};
  let sequence = false;
  arr.forEach((el, index) => {
    if (el.shouldSign && !el.isSigned && !sequence) {
      result[index] = true;
      sequence = true;
    } else if (el.shouldSign && el.isSigned) {
      sequence = false;
    }
  });
  return result;
};
