import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupByModule } from './group-by/group-by.module';
import { DelayedOrderItemDetailComponent } from './delayed-order-item-detail/delayed-order-item-detail.component';
import { DelayedOrderItemComponent } from './delayed-order-item/delayed-order-item.component';
import { OrderItemProductComponent } from './order-item-product/order-item-product.component';

@NgModule({
  imports: [CommonModule, GroupByModule],
  declarations: [DelayedOrderItemComponent, DelayedOrderItemDetailComponent, OrderItemProductComponent],
  exports: [DelayedOrderItemComponent, DelayedOrderItemDetailComponent],
})
export class DelayedOrderModule {}
