import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { getDividers } from '../utils';
import { Balance, PayHistory } from '../history.models';

@Component({
  selector: 'iqretail-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceComponent implements OnChanges {
  _payHistory: PayHistory[] = [];

  get payHistory(): PayHistory[] {
    return this._payHistory;
  }
  @Input() set payHistory(value: PayHistory[]) {
    this._payHistory = value;
  }
  @Input() balance: Balance[] = [];
  @Input() fullMode = false;
  @Input() scrollDistance: number;
  @Input() throttle: number;
  @Output() scrollEnd = new EventEmitter();

  dividers: { [key: number]: Date } = {};

  items: PayHistory[] = [];
  uniqueDates: any;

  ngOnChanges({ payHistory }: SimpleChanges) {
    if (payHistory && payHistory.currentValue) {
      this.setDividers(payHistory.currentValue);
    }
  }

  onScrollEnd() {
    this.scrollEnd.emit();
  }
  private setDividers(payHistory: PayHistory[]): void {
    this.dividers = getDividers(payHistory, 'buDate');
  }
}
