import { Component, ViewChild, ElementRef, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { User, Outlet } from '../organization.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'iqretail-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss'],
})
export class StaffComponent implements OnChanges {
  @Input() users: User[] = [];
  @Input() totalUsers: number;
  @Input() showModal = false;
  @Input() currentUser: User;
  @Input() activeOutlet: Outlet;
  @Input() closeModeShowPeople = 3;
  @Input() totalUsersPages: number;

  @Output() loadAllUsers = new EventEmitter();
  @Output() loadUsersPages = new EventEmitter();
  @Output() closeModal = new EventEmitter();

  @Output() editUser = new EventEmitter<number>();
  @Output() removeUser = new EventEmitter<number>();
  @Output() showUserHistoryPoint = new EventEmitter<User>();

  public bsModalRef: BsModalRef;

  @ViewChild('modal') modal: ElementRef;

  throttle = 300;
  scrollDistance = 2;
  page = 0;

  constructor(private modalService: BsModalService) {}

  ngOnChanges({ showModal }: SimpleChanges) {
    if (showModal && showModal.currentValue === true) {
      this.openModal();
    } else if (showModal && showModal.currentValue === false) {
      this.bsModalRef && this.bsModalRef.hide();
    }
  }

  openModal() {
    this.loadAllUsers.emit();
    document.body.classList.add('disable-scroll');
    this.bsModalRef = this.modalService.show(this.modal, Object.assign({}, { class: 'gray modal-lg staff-modal' }));
    this.modalService.onHidden.pipe(take(1)).subscribe(() => {
      document.body.classList.remove('disable-scroll');
      this.closeModal.emit();
    });
  }

  onScrollEnd() {
    this.loadUsersPages.emit();
  }

  onEditUser(userId: number) {
    this.editUser.emit(userId);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  onRemoveUser(userId: number) {
    this.removeUser.emit(userId);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  onShowPointsHistory(user: User) {
    this.showUserHistoryPoint.emit(user);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }
}
