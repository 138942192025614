import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ClientHistoryBonus, UserHistoryBonus } from '../history.models';
import { getDividers } from '../utils';

@Component({
  selector: 'iqretail-bonus-history',
  templateUrl: './bonus-history.component.html',
  styleUrls: ['./bonus-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BonusHistoryComponent implements OnChanges {
  _bonusHistory: Array<ClientHistoryBonus | UserHistoryBonus> = [];

  get bonusHistory(): Array<ClientHistoryBonus | UserHistoryBonus> {
    return this._bonusHistory;
  }

  @Input() set bonusHistory(value: Array<ClientHistoryBonus | UserHistoryBonus>) {
    this._bonusHistory = value;
  }

  @Input() fullMode = false;
  @Input() scrollDistance: number;
  @Input() throttle: number;
  @Output() scrollEnd = new EventEmitter();

  dividers: { [key: number]: Date } = {};

  items: Array<ClientHistoryBonus | UserHistoryBonus> = [];

  ngOnChanges({ bonusHistory }: SimpleChanges) {
    if (bonusHistory && bonusHistory.currentValue) {
      this.setDividers(bonusHistory.currentValue);
    }
  }

  onScrollEnd() {
    this.scrollEnd.emit();
  }

  private setDividers(bonusHistory: Array<ClientHistoryBonus | UserHistoryBonus>): void {
    this.dividers = getDividers(bonusHistory, 'date');
  }
}
