import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { User } from '../../../company/company.component';
import { DelayedOrder } from '../delayed-order';

@Component({
  selector: 'app-delayed-order-item-detail',
  templateUrl: './delayed-order-item-detail.component.html',
  styleUrls: ['./delayed-order-item-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelayedOrderItemDetailComponent implements OnInit {
  @Input() delayedOrder: DelayedOrder;
  @Input() ordersProducts: Record<number, any>;
  @Input() orderUsers: Record<number, User>;
  @Output() showedHistory: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onShowHistory(event: Event) {
    event.stopPropagation();
    this.showedHistory.emit();
  }
}
