import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { CartItemDisplay } from '../../../../store/cart/cart.models';

@Component({
  selector: 'app-default-cart-list',
  templateUrl: './default-cart-list.component.html',
  styleUrls: ['./default-cart-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultCartListComponent implements OnInit {
  @Input() cartList: CartItemDisplay[];
  @Output() decrementedAmount: EventEmitter<any> = new EventEmitter();
  @Output() incrementedAmount: EventEmitter<any> = new EventEmitter();
  @Output() changeAmount: EventEmitter<any> = new EventEmitter();
  @Output() deleted: EventEmitter<any> = new EventEmitter();
  @Output() showedOrderHistory: EventEmitter<any> = new EventEmitter();
  @Output() toggleFavorite: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  incrementAmount(product: CartItemDisplay): void {
    this.incrementedAmount.emit(product);
  }

  decrementAmount(product: CartItemDisplay): void {
    this.decrementedAmount.emit(product);
  }

  deleteCartItem(product: CartItemDisplay): void {
    this.deleted.emit(product);
  }

  onShowOrderHistory(product: CartItemDisplay): void {
    this.showedOrderHistory.emit(product);
  }

  onToggleFavorite(product: CartItemDisplay): void {
    this.toggleFavorite.emit(product);
  }

  onChangeAmount({ product, amount }): void {
    this.changeAmount.emit({ product, amount });
  }

  trackById(index: number, item: CartItemDisplay): number {
    return item.materialId;
  }
}
