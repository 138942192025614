import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Task, HistoryData, Outlet, OrderDetails, Balance, PayHistory, ClientHistoryBonus, UserHistoryBonus } from '../history.models';
import { ModeType } from '../mode.type';
import { onChangesHelper } from '../utils';

@Component({
  selector: 'iqretail-history-content',
  templateUrl: './history-content.component.html',
  styleUrls: ['./history-content.component.scss'],
})
export class HistoryContentComponent implements OnInit, OnChanges {
  @Input() history: HistoryData[] = [];
  @Input() tasks: Task[] = [];
  @Input() payHistory: PayHistory[] = [];
  @Input() balance: Balance[] = [];
  @Input() bonusHistory: UserHistoryBonus[] = [];
  @Input() bonusHistoryClient: ClientHistoryBonus[] = [];

  @Input() outlet: Outlet;
  @Input() fullMode = false;
  @Input() modeType: ModeType;
  @Input() orderDetails: OrderDetails[] = [];
  @Input() selectedOrderId?: string;
  @Input() totalOrderHistoryPages: number;
  @Input() totalTasksPages: number;

  @Input() scrollDistance: number;
  @Input() throttle: number;

  @Input() isAgent = false;

  @Output() modeTypeChange = new EventEmitter<ModeType>();
  @Output() openDetails = new EventEmitter<string>();

  @Output() loadAllOrdersHistory = new EventEmitter();
  @Output() loadAllTasksHistory = new EventEmitter();
  @Output() loadAllPayHistory = new EventEmitter();

  @Output() loadOrdersHistoryPages = new EventEmitter();
  @Output() loadHistoryTaskPages = new EventEmitter();
  @Output() loadData = new EventEmitter();
  @Output() navToHistoryOrdersDetails = new EventEmitter<{ data: string }>();
  @Output() navigateToHistory = new EventEmitter();

  lastDate?: Date;
  active = true;
  selectedContent = false;

  showScrollBar = null;
  financeBlock: boolean;

  selectContent(type: ModeType) {
    if (type === 'tasks' || type === 'orders') {
      this.financeBlock = false;
    }
    this.modeTypeChange.emit(type);
  }

  ngOnInit() {}

  ngOnChanges({ modeType, tasks, history, payHistory }: SimpleChanges) {
    const mt: ModeType = (modeType && modeType.currentValue) || this.modeType;
    const tasksArr = onChangesHelper(this.tasks, tasks as { currentValue?: Task[] });
    const ordersArr = onChangesHelper(this.history, history as { currentValue?: HistoryData[] });
    const financeArr = onChangesHelper(this.payHistory, payHistory as { currentValue?: PayHistory[] });
    const arr =
      mt === 'tasks'
        ? tasksArr && tasksArr.map((el) => el.dateStart)
        : mt === 'orders'
        ? ordersArr.map((el) => el.docDateTime)
        : mt === 'finance'
        ? financeArr.map((el) => el.buDate)
        : this.history.map((el) => el.docDateTime);
    if (arr && arr.length) {
      this.setLastActivityDate(arr);
    } else {
      this.lastDate = undefined;
    }
  }

  private setLastActivityDate(dates: string[]) {
    const start = new Date(0);
    let last = start;
    dates.forEach((el) => {
      if (el) {
        const date = new Date(el);
        if (date instanceof Date && !isNaN(date.getTime()) && date > last) {
          last = date;
        }
      }
    });
    if (last.getTime() !== start.getTime()) {
      this.lastDate = last;
    }
  }

  toggle() {
    this.active = !this.active;
  }

  onLoadAllOrdersHistory() {
    this.loadAllOrdersHistory.emit();
  }

  onloadAllTasksHistory() {
    this.loadAllTasksHistory.emit();
  }

  onloadAllPayHistory() {
    this.loadAllPayHistory.emit();
  }

  onNavigateToHistory() {
    this.navigateToHistory.emit(); // todo - implement in app
  }

  onScrollEnd() {
    this.loadData.emit();
  }

  onToggleFinance() {
    this.financeBlock = true;
    this.selectContent('finance');
  }
}
