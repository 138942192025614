import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ModalModule,
  TabsModule,
  BsDropdownModule,
  AccordionModule,
  AlertModule,
  ButtonsModule,
  CollapseModule,
  ProgressbarModule,
  TooltipModule,
  TypeaheadModule,
} from 'ngx-bootstrap';

import { OrganizationComponent } from './organization.component';
import { StaffComponent } from './staff/staff.component';
import { StaffContentComponent } from './staff/staff-content/staff-content.component';
import { StaffItemComponent } from './staff/staff-content/staff-item/staff-item.component';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentsContentComponent } from './documents/documents-content/documents-content.component';
import { DocumentElementComponent } from './documents/documents-content/document-element/document-element.component';
import { DocumentsPlaceholderComponent } from './documents-placeholder/documents-placeholder.component';
import { NgxMaskModule } from 'ngx-mask';
import { OrganizationTitleComponent } from './organization-title/organization-title.component';
import { CommonModule } from '@angular/common';
import { DocumentsWidgetComponent } from './components/documents-widget/documents-widget.component';

@NgModule({
  declarations: [
    DocumentsComponent,
    StaffComponent,
    OrganizationComponent,
    StaffContentComponent,
    StaffItemComponent,
    DocumentElementComponent,
    DocumentsContentComponent,
    DocumentsPlaceholderComponent,
    OrganizationTitleComponent,
    DocumentsWidgetComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CollapseModule,
    ProgressbarModule,
    TooltipModule,
    TypeaheadModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    InfiniteScrollModule,
  ],
  exports: [
    DocumentsComponent,
    StaffComponent,
    OrganizationComponent,
    StaffContentComponent,
    StaffItemComponent,
    DocumentElementComponent,
    DocumentsContentComponent,
    DocumentsWidgetComponent,
  ],
  providers: [],
  entryComponents: [],
})
export class OrganizationModule {}
