import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'iqretail-history-title',
  templateUrl: './history-title.component.html',
  styleUrls: ['./history-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryTitleComponent implements OnInit {
  @Input() title: string;
  @Input() isActive: boolean;
  @Input() dataCount: number = 0;

  @Output() toggle: EventEmitter<any> = new EventEmitter();

  public hideArrow = false;

  private _screenBreakPoint = 768;

  constructor() {}

  ngOnInit() {
    this.hideArrow = this.checkWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;

    if (width <= this._screenBreakPoint) {
      this.hideArrow = true;
    }
  }

  onClick() {
    if (this.checkWidth()) {
      this.hideArrow = true;
      this.toggle.emit();
    }
  }

  private checkWidth() {
    const width = window.innerWidth;
    return width <= this._screenBreakPoint;
  }
}
