import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Client, Documents, User } from '../organization.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'iqretail-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnChanges {
  @Input() client: Client;
  @Input() documents: Documents[];
  @Input() modalOpened = false;
  @Input() owner = '';
  @Input() user: User;
  @Input() totalDocs: number;

  @Output() closeModal = new EventEmitter();

  @Output() downloadDocument = new EventEmitter<number>();
  @Output() signDocument = new EventEmitter<number>();

  currentDocsNumber = 0;

  docType: 'agreements' | 'acts' | 'rest' = 'agreements';
  public bsModalRef: BsModalRef;

  @ViewChild('modal') modal: ElementRef;

  throttle = 300;
  scrollDistance = 2;
  page = 0;

  constructor(private modalService: BsModalService) {}

  ngOnChanges({ modalOpened }: SimpleChanges) {
    if (modalOpened && modalOpened.currentValue === true) {
      this.openModal();
    } else if (modalOpened && modalOpened.currentValue === false) {
      if (this.bsModalRef) {
        this.bsModalRef.hide();
      }
    }
  }

  openModal() {
    this.bsModalRef = this.modalService.show(this.modal, Object.assign({}, { class: 'gray modal-lg organization-modal' }));
    this.modalService.onHidden.pipe(take(1)).subscribe(() => {
      this.closeModal.emit();
    });
  }
  onDocTypeChange(type: 'agreements' | 'acts' | 'rest') {
    this.docType = type;
  }
  setCurrentDocsNumber(num: number) {
    this.currentDocsNumber = num;
  }
}
