import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from '@Mesh/shared/modules/safe/safe.pipe';

@NgModule({
  declarations: [SafePipe],
  exports: [SafePipe],
  imports: [CommonModule],
  providers: [SafePipe],
})
export class SafeModule {}
