import { Injectable } from '@angular/core';
import { TaskService } from '@Mesh/core/services/task.service';
import { shareReplay, map } from 'rxjs/operators';

@Injectable()
export class TrainingTaskWidgetService {
  trainingTasks$ = this.commonTaskService.getClientTasks({}).pipe(
    map((data) => data.content),
    shareReplay(1)
  );

  constructor(private commonTaskService: TaskService) {}
}
