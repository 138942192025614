import { Component, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Bonus, Plan, PlanCategory } from './targets.model';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iqretail-targets',
  templateUrl: './targets.component.html',
  styleUrls: ['./targets.component.scss'],
})
export class TargetsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() plans: Plan[] = [];
  @Input() bonuses: Bonus[] = [];
  @Input() predictBonuses: Bonus[] = [];
  @Input() imageUrl: string = 'https://static.cloud.stage.retail.iql.ru/';
  @Output() navigateToPlan = new EventEmitter<number>();
  @Output() navigateToCatalog = new EventEmitter();

  private readonly currentPlans$ = new BehaviorSubject<Plan[]>([]);

  handledPlans: Plan[] = [];
  readonly activeCategory$ = new BehaviorSubject<string>(undefined);
  categories: string[] = [];

  public bsModalRef: BsModalRef;

  @ViewChild('modal') modal: ElementRef;

  private readonly unsubscribe$ = new Subject();

  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    combineLatest(this.activeCategory$, this.currentPlans$)
      .pipe(
        filter(([activeCategory, plans]) => !!activeCategory && plans && !!plans.length),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([activeCategory, plans]) => {
        this.handledPlans = plans.filter((p) => p.category === activeCategory);
      });
  }

  ngOnChanges({ plans, bonuses }: SimpleChanges) {
    if (plans && plans.currentValue) {
      const plansData = plans.currentValue;
      this.currentPlans$.next(plansData);
    }
    if (bonuses && bonuses.currentValue) {
      const b = bonuses.currentValue as Bonus[];
      const set = new Set<string>(b.map((el) => el.categoryName));
      this.categories = Array.from(set);
      this.activeCategory$.next(this.categories[0]);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCategoryChange(category: string) {
    this.activeCategory$.next(category);
  }

  openModal() {
    this.bsModalRef = this.modalService.show(this.modal, Object.assign({}, { class: 'gray modal-lg target-modal' }));
  }
}
