import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DelayedOrderCart } from '../delayed-order';

@Component({
  selector: 'app-order-item-product',
  templateUrl: './order-item-product.component.html',
  styleUrls: ['./order-item-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderItemProductComponent implements OnInit {
  @Input() productCart: DelayedOrderCart;
  @Input() ordersProducts: Record<number, any>;
  IMAGES_URL = 'https://cdn.stage.retail.iql.ru/';

  constructor() {}

  ngOnInit() {}
}
