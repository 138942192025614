import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ModalModule,
  TabsModule,
  BsDropdownModule,
  AccordionModule,
  AlertModule,
  ButtonsModule,
  CollapseModule,
  ProgressbarModule,
  TooltipModule,
  TypeaheadModule,
} from 'ngx-bootstrap';

import { CompanyComponent } from './company.component';
import { CommonModule } from '@angular/common';
import { NumberFormatPipe } from './number-format.pipe';
import { AbsolutePipe } from './absolute.pipe';
import { AvatarWidgetComponent } from './components/avatar-widget/avatar-widget.component';

@NgModule({
  declarations: [CompanyComponent, NumberFormatPipe, AbsolutePipe, AvatarWidgetComponent],
  imports: [
    FormsModule,
    CommonModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CollapseModule,
    ProgressbarModule,
    TooltipModule,
    TypeaheadModule,
  ],
  exports: [CompanyComponent, AvatarWidgetComponent],
  providers: [],
  entryComponents: [],
})
export class CompanyModule {}
