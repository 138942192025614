import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ModalModule,
  TabsModule,
  BsDropdownModule,
  AccordionModule,
  AlertModule,
  ButtonsModule,
  CollapseModule,
  ProgressbarModule,
  TooltipModule,
  TypeaheadModule,
} from 'ngx-bootstrap';

import { ActiveOrdersComponent } from './active-orders.component';
import { ActiveOrdersContentComponent } from './active-orders-content/active-orders-content.component';
import { ActiveOrderItemComponent } from './active-orders-content/active-order-item/active-order-item.component';
import { ActiveOrdersPlaceholderComponent } from './active-orders-placeholder/active-orders-placeholder.component';
import { ActiveOrdersTitleComponent } from './active-orders-title/active-orders-title.component';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DelayedOrderModule } from './delayed-order/delayed-order.module';
import { ActiveOrdersWidgetComponent } from './components/active-orders-widget/active-orders-widget.component';

@NgModule({
  declarations: [
    ActiveOrdersComponent,
    ActiveOrdersContentComponent,
    ActiveOrderItemComponent,
    ActiveOrdersPlaceholderComponent,
    ActiveOrdersTitleComponent,
    ActiveOrdersWidgetComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CollapseModule,
    ProgressbarModule,
    TooltipModule,
    TypeaheadModule,
    InfiniteScrollModule,
    DelayedOrderModule,
  ],
  exports: [ActiveOrdersComponent, ActiveOrdersContentComponent, ActiveOrderItemComponent, ActiveOrdersWidgetComponent],
  providers: [],
  entryComponents: [],
})
export class ActiveOrdersModule {}
