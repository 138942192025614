import { Injectable } from '@angular/core';
import { Award } from '@Mesh/core/models/award';
import { HttpClient } from '@angular/common/http';
import { API_TASK_URL } from '@Env/environment.dev';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShopWidgetService {
  private BASE_URL = `${API_TASK_URL}/awards`;

  constructor(private http: HttpClient) {}

  last() {
    return of(new Award().deserialize({}));
  }
}
