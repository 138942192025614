import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HistoryData, OrderDetails, Outlet, UnitIdName } from '../../history.models';

@Component({
  selector: 'iqretail-orders-item',
  templateUrl: './orders-item.component.html',
  styleUrls: ['./orders-item.component.scss'],
})
export class OrdersItemComponent implements OnInit {
  @Input() order: HistoryData;
  @Input() outlet: Outlet;
  @Input() orderDetails: OrderDetails[] = [];
  @Input() selectedDocNumber?: string;
  @Output() openDetailsEvent = new EventEmitter<string>();
  @Output() navToHistoryOrdersDetails = new EventEmitter<{ data: string }>();

  isOpenedDetails: boolean;

  constructor() {}

  ngOnInit() {}

  toggleDetails() {
    if (this.isOpenedDetails) {
      this.isOpenedDetails = false;
    } else if (this.order.docNumber) {
      this.isOpenedDetails = true;
      this.openDetailsEvent.emit(this.order.docNumber);
    }
  }

  onNavToHistoryOrdersDetails(docNumber: string): void {
    this.navToHistoryOrdersDetails.emit({ data: docNumber });
  }
}
