import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tasks-placeholder',
  templateUrl: './tasks-placeholder.component.html',
  styleUrls: ['./tasks-placeholder.component.scss'],
})
export class TasksPlaceholderComponent {
  @Input() tasksOnModeration = 0;
}
