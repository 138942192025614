import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { HistoryData, OrderDetails, Outlet } from '../history.models';
import { getDividers } from '../utils';

@Component({
  selector: 'iqretail-history-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnChanges {
  _history: HistoryData[] = [];

  get history(): HistoryData[] {
    return this._history;
  }
  @Input() set history(value: HistoryData[]) {
    this._history = value.map((el) => ({ ...el, isOpenedDetails: false }));
  }

  @Input() outlet: Outlet;
  @Input() fullMode: boolean;
  @Input() orderDetails: OrderDetails[] = [];
  @Input() selectedDocNumber?: string;
  @Input() scrollDistance: number;
  @Input() throttle: number;
  @Output() scrollEnd = new EventEmitter();

  @Output() openDetailsEvent = new EventEmitter<string>();
  @Output() navToHistoryOrdersDetails = new EventEmitter<{ data: string }>();

  times: string[] = [];

  dividers: { [key: number]: Date } = {};

  ngOnChanges({ history, orderDetails }: SimpleChanges) {
    if (history && history.currentValue) {
      this.setDividers(history.currentValue);
    }
    if (orderDetails && orderDetails.currentValue) {
      // this.setOrderDetailsTimes(orderDetails.currentValue);
    }
  }

  onScrollEnd() {
    this.scrollEnd.emit();
  }

  onOpenDetails(docNumber: string) {
    this.openDetailsEvent.emit(docNumber);
  }

  onNavToHistoryOrdersDetails(docNumber: string): void {
    this.navToHistoryOrdersDetails.emit({ data: docNumber }); // todo - implement outside
  }

  private setDividers(history: HistoryData[]): void {
    this.dividers = getDividers(history, 'docDateTime');
  }

  // private setOrderDetailsTimes(orderDetails: OrderDetails[]) {
  //   this.times = orderDetails.map((el) => {
  //     return moment.utc(el.docDateTime).local().format('hh:mm');
  //   });
  // }
}
