import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { IPlanGroup, PlanService } from '@Mesh/core/services/plan.service';
import { Observable } from 'rxjs/Observable';
import { Plan } from '@Mesh/core/models/plan';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { selectPlan } from '@Mesh/store/plan/plan.selectors';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-old-targets-widget',
  templateUrl: './targets-widget.component.html',
  styleUrls: ['./targets-widget.component.scss'],
  animations: [
    trigger('collapseState', [
      state(
        'inactive',
        style({
          opacity: '0',
          height: 0,
          paddingBottom: '0',
          margin: '0',
        })
      ),
      state(
        'active',
        style({
          opacity: '1',
          height: '*',
          paddingBottom: '*',
          margin: '*',
        })
      ),
      transition('inactive => active', animate('125ms ease-in')),
      transition('active => inactive', animate('125ms ease-out')),
    ]),
    trigger('fadeAnimation', [
      state(
        'false',
        style({
          opacity: '0',
          visibility: 'hidden',
        })
      ),
      state(
        'true',
        style({
          opacity: '1',
          visibility: 'visible',
        })
      ),
      transition('false => true', animate('500ms ease-in')),
      transition('true => false', animate('500ms ease-out')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetsWidgetComponent implements OnInit {
  isCollapsed = false;
  active = true;
  tags: IPlanGroup[];
  plans$: Observable<IPlanGroup[]>;
  activeTag: number = 0;
  config = {
    init: true,
    autoHeight: true,
    // observer: true,
    direction: 'horizontal',
    // initialSlide: 0,
    slidesPerView: 1,
    // pagination: true,
    // centeredSlides: true,
    freeMode: false,
  };
  public showPlansArr: boolean[] = [];
  public swiper: any;

  constructor(private planService: PlanService, private store: Store<AppState>) {}

  clickHeader($event: MouseEvent): void {
    if (this.isCollapsed) {
      this.active = !this.active;
    }
  }

  ngOnInit() {
    this.tags = this.planService.planGroups;
    this.plans$ = this.store.select(selectPlan).pipe(tap((data) => console.log('aaaaaaaaaa', data)));
  }

  toggle() {
    this.isCollapsed = this.isCollapsed !== true;
  }

  onSwiperInit(swiper) {
    this.swiper = swiper;
    setTimeout(() => {
      swiper.update();
    }, 500);
  }

  showPlan(sapId: string) {
    this.showPlansArr[sapId] = true;
    setTimeout(() => {
      this.swiper.update();
    });
  }

  hidePlan(sapId: string) {
    this.showPlansArr[sapId] = false;
    setTimeout(() => {
      this.swiper.update();
    });
  }
}
