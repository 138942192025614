import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Outlet, Task } from '../history.models';
import * as moment_ from 'moment';
import { getDividers } from '../utils';

const moment = moment_;

@Component({
  selector: 'iqretail-history-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnChanges {
  _tasks: Task[] = [];

  get tasks(): Task[] {
    return this._tasks;
  }
  @Input() set tasks(value: Task[]) {
    this._tasks = value;
  }
  @Input() outlet: Outlet;
  @Input() fullMode: boolean;
  @Input() showScrollBar = null;
  @Input() scrollDistance: number;
  @Input() throttle: number;
  @Output() scrollEnd = new EventEmitter();

  statuses = [];

  dividers: { [key: number]: Date } = {};

  stepStatusNamesRus: { [key in StepStatusNames]: string } = {
    [StepStatusNames.NEW]: 'Новое',
    [StepStatusNames.CHECKING]: 'Проверка',
    [StepStatusNames.ACCEPTED]: 'Принят',
    [StepStatusNames.NOT_ACCEPTED]: 'Отклонен',
    [StepStatusNames.OVERDUE]: 'Просрочен',
    [StepStatusNames.UNKNOWN]: 'Неизвестен',
  };

  statusesEnum = StepStatusNames;

  ngOnChanges({ tasks }: SimpleChanges) {
    if (tasks && tasks.currentValue) {
      this.dividers = this.dividers = getDividers(tasks.currentValue, 'dateStart');
      this.setStatuses(tasks.currentValue);
    }
  }

  onScrollEnd() {
    this.scrollEnd.emit();
  }

  private setStatuses(tasks: Task[]) {
    this.statuses = tasks.map((task) => {
      if (task.taskCompleted) {
        return StepStatusNames.ACCEPTED;
      }
      if (task.onModeration) {
        return StepStatusNames.CHECKING;
      }
      if (!task.notOverdue || (task.dateEnd && moment(task.dateEnd).add(1, 'day').isBefore(moment()))) {
        return StepStatusNames.OVERDUE;
      }
      if (task.dateStart && moment(task.dateStart).isAfter(moment())) {
        return StepStatusNames.NEW;
      }
      return StepStatusNames.UNKNOWN;
    });
  }
}

export enum StepStatusNames {
  NEW = 'NEW',
  CHECKING = 'CHECKING',
  ACCEPTED = 'ACCEPTED',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
  OVERDUE = 'OVERDUE',
  UNKNOWN = 'UNKNOWN',
}
