import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Plan } from '@Mesh/core/models/plan';
import { Bonus } from '@Mesh/core/models/bonus';
import { bonusSelectors } from '@Mesh/store/bonus';
import { filter, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { Router } from '@angular/router';

export const IMAGE_CLOUD_URL = 'https://static.cloud.retail.iql.ru';

@Component({
  selector: 'app-targets-widget',
  templateUrl: './targets-widget.component.html',
  styleUrls: ['./targets-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetsWidgetComponent implements OnInit, OnDestroy {
  bonuses$ = this.store.select(bonusSelectors.getOutletBonuses);
  plans$ = new BehaviorSubject<Partial<Plan>[]>([]);
  predictBonuses$ = this.store.select(bonusSelectors.getPredictionData);

  IMAGE_CLOUD_URL = IMAGE_CLOUD_URL;

  private unsubscribe$ = new Subject();

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit() {
    this.bonuses$
      .pipe(
        filter((bonuses) => !!bonuses),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((bonuses) => {
        this.setPlans(bonuses);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }

  setPlans(bonuses: Bonus[]) {
    const plans: Partial<Plan>[] = [];
    bonuses.forEach((bonus) => {
      plans.push({
        plan_id: bonus.planId,
        planName: bonus.planName,
        category: bonus.categoryName,
        path: bonus.path,
        parentPath: bonus.parentPath,
      });
    });
    this.plans$.next(plans);
  }

  navigateToPlan(planId: number) {
    const queryParams: { [key: string]: string } = {
      planId: planId.toString(),
    };
    this.router.navigate(['catalog', 'plan'], { queryParams });
  }

  navigateToCatalog() {
    this.router.navigate(['catalog']);
  }
}
