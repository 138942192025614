import { Component, Input, OnInit } from '@angular/core';
import { ClientHistoryBonus, UserHistoryBonus } from '../../history.models';

@Component({
  selector: 'iqretail-bonus-history-item',
  templateUrl: './bonus-history-item.component.html',
  styleUrls: ['./bonus-history-item.component.scss'],
})
export class BonusHistoryItemComponent {
  @Input() bonus: ClientHistoryBonus | UserHistoryBonus;
}
