import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from '../tasks.model';

@Component({
  selector: 'iqretail-moderation-task-content',
  templateUrl: 'moderation-task-content.component.html',
  styleUrls: ['./moderation-task-content.component.scss'],
})
export class ModerationTaskContentComponent implements OnInit {
  _moderationTasks: Task[] = [];

  get moderationTasks(): Task[] {
    return this._moderationTasks;
  }
  @Input() set tasks(value: Task[]) {
    this._moderationTasks = value;
  }
  @Input() isTab = false;
  @Input() totalTasks: number;
  @Input() scrollDistance: number;
  @Input() throttle: number;

  @Output() scrollEnd = new EventEmitter();
  @Output() navToTaskDetails = new EventEmitter<Task>();

  constructor() {}

  ngOnInit() {}

  onScrollEnd() {
    this.scrollEnd.emit();
  }
}
