import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../../company/company.component';
import { Outlet } from '../../active-orders.models';
import { DelayedOrder, DelayedOrderStatus } from '../delayed-order';

const bodyTrigger = trigger('orderBody', [
  state(
    'collapsed',
    style({
      overflow: 'hidden',
      visibility: 'hidden',
      height: 0,
    })
  ),
  state(
    'expanded',
    style({
      overflow: 'hidden',
      visibility: 'visible',
    })
  ),
  transition('collapsed => expanded', animate('100ms ease-in')),
  transition('expanded => collapsed', animate('100ms ease-out')),
]);

@Component({
  selector: 'app-delayed-order-item',
  templateUrl: './delayed-order-item.component.html',
  styleUrls: ['./delayed-order-item.component.scss'],
  animations: [bodyTrigger],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelayedOrderItemComponent implements OnInit {
  @Input() delayedOrder: DelayedOrder;
  @Input() outlet: Outlet;
  @Input() orderUsers: Record<number, User>;
  @Output() edited: EventEmitter<any> = new EventEmitter();
  @Output() deleted: EventEmitter<any> = new EventEmitter();
  @Output() opened: EventEmitter<any> = new EventEmitter();
  @Output() requestInvoice: EventEmitter<any> = new EventEmitter();
  collapsed: boolean = true;
  DelayedOrderStatusMap = {
    [DelayedOrderStatus.ACTIVE]: 'Ожидает',
    [DelayedOrderStatus.PARTIALLY_EXECUTED]: 'Выполненен частитично',
    [DelayedOrderStatus.REMOVED]: 'Удален',
    [DelayedOrderStatus.EXECUTED]: 'Выполнен',
  };

  constructor() {}

  ngOnInit() {}

  get state(): string {
    return this.collapsed ? 'collapsed' : 'expanded';
  }

  onToggle() {
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      this.opened.emit();
    }
  }

  onEditOrder(event: Event) {
    event.stopPropagation();
    this.edited.emit();
  }

  onDropOrder(event: Event) {
    event.stopPropagation();
    this.deleted.emit();
  }

  onRequestInvoice(event: Event) {
    event.stopPropagation();
    this.requestInvoice.emit();
  }

  get usersOrder() {
    const users = this.delayedOrder && this.delayedOrder.carts.map((item) => item.userId);
    return users.filter((item, pos) => users.indexOf(item) === pos);
  }
}
