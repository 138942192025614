import { createSelector } from '@ngrx/store';
import { selectOrderState } from '../app.state';
import { OrderState } from '@Mesh/store/order/order.state';

export const orderSelectors = {
  getOrders: createSelector(selectOrderState, (state: OrderState) => state.orders),
  getHistoryOrders: createSelector(selectOrderState, (state: OrderState) => state.historyOrders),
  getOrderTotalCount: createSelector(selectOrderState, (state: OrderState) => state.totalCount),
  orderDetails: createSelector(selectOrderState, (state: OrderState) => state.orderDetails),
  getError: createSelector(selectOrderState, (state: OrderState) => state.error),
  getSelectedId: createSelector(selectOrderState, (state: OrderState) => state.selectedDocNumber),
  getSelectedOrder: createSelector(selectOrderState, (state: OrderState) =>
    state.orders?.find((d) => d['docNumber'] === state.selectedDocNumber)
  ),
  getHistoryTotalCount: createSelector(selectOrderState, (state) => state.totalHistoryCount),
  getDetailedOrder: createSelector(selectOrderState, (state) => state.orderDetails),
  getOrderTotalPages: createSelector(selectOrderState, (state: OrderState) => state.totalPages),
  getHistoryOrdersCurrentPage: createSelector(selectOrderState, (state: OrderState) => state.hisrotyOrdersCurrentPage),
  getActiveOrdersCurrentPage: createSelector(selectOrderState, (state: OrderState) => state.activeOrdersCurrentPage),
  selectLoading: createSelector(selectOrderState, (state) => state.ordersHistoryLoading),
  getBonusRecommendedOrders: createSelector(selectOrderState, (state) => state.bonusRecommendedOrders),
  getBonusRecommendedOrdersByCatalog: createSelector(selectOrderState, (state) => state.bonusRecommendedOrdersByCatalog),
  getDelayedOrders: createSelector(selectOrderState, (state) => state.delayedOrders),
  getDelayedOrdersTotalCount: createSelector(selectOrderState, (state: OrderState) => state.totalCountDelayOrder),
  getOrdersProducts: createSelector(selectOrderState, (state) => state.ordersProducts),
  getOrdersUsers: createSelector(selectOrderState, (state) => state.ordersUsers),
  getErrorsDelayedOrder: createSelector(selectOrderState, (state) => state.errorsDelayedOrder),
  getCompletedDelayedOrder: createSelector(selectOrderState, (state) => state.completedUpdateDelayedOrder),
};

export const getOrdersWithoutDeliveredTrue = createSelector(orderSelectors.getOrders, (orders) =>
  (orders || []).filter((order) => order.delivered !== true)
);

export const getTotalCountOrders = createSelector(
  orderSelectors.getOrderTotalCount,
  orderSelectors.getDelayedOrdersTotalCount,
  (totalOrder, totalDelayedOrder) => totalOrder + totalDelayedOrder
);
