export interface Client {
  clientName: string;
  clientSapId: number;
  inn: string;
  kpp: null | number;
  ogrn: string;
  urAdr: string;
}
export interface Task {
  active: boolean;
  addressSapId: number;
  clientSapId: number;
  comment: string;
  cost: number;
  countStep: number;
  dateEnd: string;
  dateOfCreation: string;
  dateStart: string;
  id: number;
  imageUrl: string;
  name: string;
  notOverdue: boolean;
  status: string;
  step: Step[];
  progress?: number;
}
export interface Step {
  additionalType: null;
  comment: string;
  cost: number;
  description: string;
  id: number;
  imageUrls: any[];
  name: string;
  planId: number;
  question: null;
  reply: any[];
  requiredCountMedia: null;
  step: number;
  taskId: number;
  type: StepType;
  videoUrls: any[];
}
export interface StepType {
  description: string;
  goldCost: number;
  ironCost: number;
  name: string;
  silverCost: number;
  title: string;
  uncategoryCost: number;
}
export enum Status {
  gold = 'Gold',
  silver = 'Silver',
  iron = 'Iron',
}

export interface IOutlet {
  name: string;
  statusPower: number;
  addressSapId: number;
  addressName: string;
  clientSapId: number;
  clientName: string;
  room: string;
  building: string;
  cityName: string;
  cityName1: string;
  district: string;
  guid: string;
  street: string;
  house: string;
  housing: string;
  plantSapId: string;
  postcode: number;
  regionCode: number;
  regionName: string;
  remark: string;
  salesOrgSapId: string;
  segmentName: Status;
  subdistributor: string;
  latitude: number;
  longitude: number;
  users: string;
}

export class Outlet {
  name: string;
  statusPower: number;
  addressSapId: number;
  addressName: string;
  clientSapId: number;
  clientName: string;
  room: string;
  building: string;
  cityName: string;
  cityName1: string;
  district: string;
  guid: string;
  street: string;
  house: string;
  housing: string;
  plantSapId: string;
  postcode: number;
  regionCode: number;
  regionName: string;
  remark: string;
  salesOrgSapId: string;
  segmentName: Status;
  subdistributor: string;
  latitude: number;
  longitude: number;
  users: string;
  get address(): string {
    const addressArray = [this.street, this.house, this.housing, this.building, this.room, this.cityName, this.regionName].filter(
      (e) => !!e
    );
    return addressArray.join(', ');
  }

  constructor(outlet: IOutlet) {
    Object.assign(this, outlet);
  }
}
