export interface Bonus {
  availablePercents: Array<Percentage>;
  currentBonus: number;
  executedAmount: number;
  executedPercent: number;
  expectedBonus: '40 баллов за каждые 10 блоков';
  maxBonus: number;
  nextStepBonus: number;
  nextStepPercent: number;
  planId: number;
  planName: string;
  requiredAmount: number;
  strategy: Strategy;
  shortName?: string;
  categoryName?: string;
  image?: string;
  parentPath?: string;
  path?: string;
  step: number;
  requiredAmountInBaseUnit: number;
  executedAmountInBaseUnit: number;
  baseUnit: string;
}

export interface Percentage {
  percent: number;
  bonus: number;
}

export enum Strategy {
  val = 'Val',
  orderAmount = 'OrderAmount',
  amount = 'Amount',
  top24Material = 'Top24Material',
  top24SaleVolume = 'Top24SaleVolume',
  driveList = 'DriveList',
}

export interface Plan {
  image: string;
  keyGroup: null;
  nameGroup: null;
  planName: string;
  plan_id: number;
  quant: number;
  planCategory?: PlanCategory;
  category?: string;
  bonus: Bonus;
  predictBonus: Bonus;
  parentPath?: string;
  path?: string;
}

export enum PlanCategory {
  tabacoo = 'Табак',
  rrp = 'РПП',
  energyDrink = 'Энергетики',
  grocery = 'Бакалея',
}
