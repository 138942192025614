import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Documents, User } from '../../../organization.model';

@Component({
  selector: 'iqretail-document-element',
  templateUrl: './document-element.component.html',
  styleUrls: ['./document-element.component.scss'],
})
export class DocumentElementComponent implements OnChanges {
  @Input() document: Documents;
  @Input() user: User;

  @Output() signDocument = new EventEmitter<number>();
  @Output() downloadDocument = new EventEmitter<number>();

  showSignButton = false;

  ngOnChanges({ user }: SimpleChanges) {
    if (user && user.currentValue) {
      this.setShowSignButton(user.currentValue);
    }
  }

  private setShowSignButton(user: User) {
    if (user.type === 'OWNER' || user.type === 'MANAGER') {
      this.showSignButton = true;
    }
  }
}
