import { Deserializable } from '@Mesh/core/models/deserializable';

export class AwardImage implements Deserializable {
  id?: number;
  image?: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
