import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Bonus, Plan, Strategy } from '../../targets.model';

@Component({
  selector: 'iqretail-targets-item',
  templateUrl: './targets-item.component.html',
  styleUrls: ['./targets-item.component.scss'],
})
export class TargetsItemComponent implements OnChanges {
  @Input() plan: Plan;
  @Input() bonuses: Bonus[];
  @Input() predictBonuses: Bonus[];
  @Input() typeState = false;
  @Input() isLast = false;
  @Input() imageUrl: string;
  @Output() navigateToPlan = new EventEmitter<number>();

  currentPlan: Plan;

  image?: string;

  get unit() {
    return this.bonus && this.bonus.baseUnit ? this.bonus.baseUnit : 'шт';
  }

  get imageOrDefault() {
    return this.imageUrl + '/' + (this.bonus && this.bonus.image) || 'assets/targets/' + this.image + '.svg';
  }

  get predictBonus() {
    return this.plan && this.plan.predictBonus;
  }

  get bonus() {
    return this.plan && this.plan.bonus;
  }

  get maxProgress() {
    return getMaxProgressByStrategy(this.bonus.strategy);
  }

  get left() {
    const percents = (this.progress / this.maxProgress) * 100;
    return percents > 100 ? 100 : percents;
  }

  get leftRisk() {
    return this.left > 90 ? this.left - 5 : this.left;
  }

  get predictLeft() {
    const percents = (this.progressPredict / this.maxProgress) * 100;
    return percents > 100 ? 100 : percents;
  }

  get maxPercents() {
    let maxPercent = -1;
    this.bonus.availablePercents.forEach((el) => {
      if (el.percent > maxPercent) {
        maxPercent = el.percent;
      }
    });
    return maxPercent;
  }

  get bonusColor() {
    if (this.bonus) {
      const percents = this.left;
      const base = 'percents__data_';
      for (let i = 0; i <= 110; i += 10) {
        if (percents <= i) {
          return base + 'per' + i;
        }
      }
    }
    return '';
  }

  get valueText(): string[] {
    let percent: string, amount: string, ball: string;

    switch (this.bonus.strategy) {
      case Strategy.amount:
        percent = `${this.percentEvery().toFixed()}%`;
        amount = `${this.bonus.executedAmount} ${this.bonus.baseUnit}`;
        ball = `${this.bonus.currentBonus} Б`;
        return [amount, this.bonus.currentBonus > 0 ? ball : percent];
      case Strategy.driveList:
        percent = `${this.bonus.executedPercent}%`;
        amount = `${this.bonus.executedAmount} ${this.bonus.baseUnit}`;
        return [amount, percent];
      case Strategy.orderAmount:
        ball = `${this.bonus.currentBonus} Б`;
        return [ball, ball];
      case Strategy.top24Material:
      case Strategy.top24SaleVolume:
      case Strategy.val:
        percent = `${this.bonus.executedPercent}%`;
        amount = `${this.bonus.executedAmount} ${this.bonus.baseUnit}`;
        return [amount, percent];
      default:
        return ['', ''];
    }
  }

  get waitingBonus() {
    const ballBonus = this.predictBonus && this.bonus ? this.predictBonus.currentBonus - this.bonus.currentBonus : 0;

    switch (this.bonus.strategy) {
      case Strategy.amount:
      case Strategy.driveList:
      case Strategy.top24Material:
      case Strategy.top24SaleVolume:
      case Strategy.val:
        const countAmount = this.predictBonus && this.bonus ? this.predictBonus.executedAmount - this.bonus.executedAmount : 0;
        return [
          `Ожидается получение <span>${ballBonus} Б</span>`,
          `Ожидается поступления товара <span>${Math.max(countAmount, 0)} ${this.unit}</span>`,
        ];
      case Strategy.orderAmount:
        return [`Ожидается получение <span>${ballBonus} Б</span>`, ` `];
      default:
        return [];
    }
  }

  get bottomText(): string {
    try {
      if (!this.bonus) {
        return '';
      }

      switch (this.bonus.strategy) {
        case Strategy.val:
          if (this.typeState) {
            return `За ${this.bonus.nextStepPercent}% получите <span>${this.bonus.nextStepBonus} Б</span>`;
          } else {
            return `Максимум получите <span>${this.bonus.maxBonus} Б</span>`;
          }
        case Strategy.orderAmount:
        case Strategy.amount:
          return this.bonus.expectedBonus;
        case Strategy.driveList:
        case Strategy.top24Material:
          return `Максимум получите <span>${this.bonus.maxBonus} Б</span>`;
        default:
          return '';
      }
    } catch (e) {
      return '';
    }
  }

  get planLabel() {
    return this.bonus && this.bonus.shortName;
  }

  get labelColor() {
    return this.bonus && this.bonus.planName && labelColors[this.bonus && this.bonus.planName]
      ? labelColors[this.bonus && this.bonus.planName]
      : '';
  }

  get receivedBonus() {
    if (!this.bonus) {
      return ['', ''];
    }

    let ballBonus: number, count: number, percent: number;

    switch (this.bonus.strategy) {
      case Strategy.amount:
        ballBonus = this.bonus.currentBonus;
        const needOrder = this.needOrder();
        return [`Уже получено <span>${ballBonus} Б</span>`, `Осталось заказать до 100% <span>${needOrder.toFixed()} ${this.unit}</span>`];
      case Strategy.driveList:
        ballBonus = this.bonus.currentBonus;
        percent = this.bonus.nextStepPercent;
        const requiredAmount = this.bonus.requiredAmount || 0;
        const executedAmount = this.predictBonus ? this.predictBonus.executedAmount : 0;
        count = Math.max(requiredAmount - executedAmount, 0);
        return [
          `Уже получено <span>${ballBonus} Б</span>`,
          count > 0
            ? `Осталось заказать до <span>${percent.toFixed()}% ${count.toFixed()} ${this.unit}</span>`
            : this.predictBonus && this.predictBonus.requiredAmount && this.predictBonus.executedAmount
            ? 'План выполнен!'
            : 'Мотивационная программа скоро обновится',
        ];
      case Strategy.orderAmount:
        ballBonus = this.bonus.currentBonus;
        count = Math.max(this.predictBonus.executedAmount - this.bonus.executedAmount, 0);
        return [`Уже получено <span>${ballBonus} Б</span>`, `Ожидается поступления товара <span>${count} ${this.unit}</span>`];
      case Strategy.top24Material:
      case Strategy.top24SaleVolume:
      case Strategy.val:
        ballBonus = this.bonus.currentBonus;
        percent = (this.bonus && this.bonus.nextStepPercent) || 0;
        const requiredWithPercent = ((this.predictBonus.requiredAmount || 0) * percent) / 100;
        count = this.bonus.requiredAmountInBaseUnit - this.bonus.executedAmountInBaseUnit || 0;

        return [
          `Уже получено <span>${ballBonus} Б</span>`,
          count > 0
            ? `Осталось заказать до <span>${percent.toFixed()}% ${count.toFixed()} ${this.unit}</span>`
            : this.predictBonus && this.predictBonus.requiredAmount && this.predictBonus.executedAmount
            ? 'План выполнен!'
            : 'Мотивационная программа скоро обновится',
        ];
      default:
        return ['', ''];
    }
  }

  get getPredictGradient(): string {
    if (!this.bonus || !this.bonus.executedAmount || !this.predictBonus || !this.predictBonus.executedAmount) {
      return '';
    }

    const calculateGradientColor = (percent, colors) => {
      for (let i = 0; i <= 110; i += 10) {
        if (percent >= i) {
          return colors[i - 1] || colors[i];
        }
      }
      return '';
    };

    const bonusPercents = (this.bonus.executedAmount / this.bonus.requiredAmount) * 100;
    const predictBonusPercents = (this.predictBonus.executedAmount / this.predictBonus.requiredAmount) * 100;

    const bonusColor = calculateGradientColor(bonusPercents, percentColors);
    const predictBonusColor = calculateGradientColor(predictBonusPercents, percentColors);

    if (bonusColor && predictBonusColor) {
      return `linear-gradient(90deg, ${bonusColor} 0%, ${predictBonusColor} 99.99%)`;
    }

    return 'linear-gradient(90deg, #42FF74 0%, #39A7FF 99.99%)';
  }

  ngOnChanges({ plan, bonuses, predictBonuses }: SimpleChanges): void {
    if (plan && plan.currentValue) {
      this.currentPlan = plan.currentValue;
      const bonusSource: Bonus[] = bonuses && bonuses.currentValue ? bonuses.currentValue : this.bonuses || [];
      const predictBonusSource: Bonus[] =
        predictBonuses && predictBonuses.currentValue ? predictBonuses.currentValue : this.predictBonuses || [];
      this.currentPlan.bonus = bonusSource.find((b) => b.planId === this.currentPlan.plan_id);
      this.currentPlan.predictBonus = predictBonusSource.find((b) => b.planId === this.currentPlan.plan_id);
      this.setImages(plan.currentValue);
    }
    if (bonuses && bonuses.currentValue) {
      this.currentPlan.bonus = bonuses.currentValue.find((b) => b.planId === this.currentPlan.plan_id);
    }
    if (predictBonuses && predictBonuses.currentValue) {
      this.currentPlan.predictBonus = predictBonuses.currentValue.find((b) => b.planId === this.currentPlan.plan_id);
    }
  }

  get progress() {
    if (this.plan && this.bonus) {
      switch (this.bonus.strategy) {
        case Strategy.amount:
          return this.percentEvery();
        case Strategy.driveList:
        case Strategy.top24Material:
        case Strategy.top24SaleVolume:
        case Strategy.val:
          return this.bonus.executedPercent;
        case Strategy.orderAmount:
          return this.bonus.currentBonus > 0 ? 15 : 0;
        default:
          return;
      }
    }
  }

  get progressPredict() {
    if (this.plan && this.bonus && this.predictBonus) {
      switch (this.bonus.strategy) {
        case Strategy.amount:
          return this.percentEveryPredict();
        case Strategy.driveList:
        case Strategy.top24Material:
        case Strategy.top24SaleVolume:
        case Strategy.val:
          return this.predictBonus.executedPercent;
        case Strategy.orderAmount:
          return Math.max(this.predictBonus.executedPercent, 0);
        default:
          return;
      }
    }
  }

  setImages(plan: Plan): void {
    this.image = this.getImage(plan.plan_id);
  }

  onNavToTargetsDetails(): void {
    if (this.bonus) {
      this.navigateToPlan.emit(this.bonus.planId);
    }
  }

  needOrder(): number {
    const { executedAmount, nextStepBonus } = this.bonus;
    const mod = executedAmount % nextStepBonus;
    if (executedAmount > 0) {
      return nextStepBonus - mod;
    } else {
      return nextStepBonus;
    }
  }

  colors(index: number): string {
    return colors[index] || colors[colors.length - 1];
  }

  private percentEvery(): number {
    const { executedAmount, nextStepBonus } = this.bonus;
    const mod = executedAmount % nextStepBonus;
    if (executedAmount > 0) {
      return mod > 0 ? (mod / nextStepBonus) * 100 : 100;
    } else {
      return 0;
    }
  }

  private percentEveryPredict(): number {
    const { executedAmount, nextStepBonus } = this.predictBonus;
    if (this.predictBonus.currentBonus > this.bonus.currentBonus) {
      return 100;
    } else {
      const mod = this.predictBonus.executedAmount % nextStepBonus;
      if (executedAmount > 0) {
        return mod > 0 ? (mod / nextStepBonus) * 100 : 100;
      } else {
        return 0;
      }
    }
  }

  private getImage(
    planId: number
  ): 'jti' | 'itg' | 'pmi' | 'TopMark' | 'Drive' | 'redbull' | 'tabak_val' | 'iqos' | 'other_combo' | 'top24new' {
    switch (planId) {
      case 2:
        return 'jti';
      case 3:
        return 'itg';
      case 1:
        return 'pmi';
      case 2222222212:
        return 'TopMark';
      case 2222222213:
        return 'Drive';
      case 3500000039:
        return 'redbull';
      case 3500000028:
        return 'tabak_val';
      case 7:
        return 'iqos';
      case null:
        return 'other_combo';
      default:
        return 'top24new';
    }
  }
}

const getEveryPlan = (planId: number | null): TEveryAmount | undefined =>
  everyAmount.find((e) => (e.planId === planId && planId !== null) || (e.strategy === Strategy.orderAmount && planId === null));

const everyAmount: TEveryAmount[] = [
  {
    planId: 3500000039,
    amount: 1000,
    ballStep: 50,
    strategy: Strategy.amount,
    expectedBonus: 'За каждые 1 000 ₽ получите - 50 Б',
    planName: 'Red Bull',
  },
  {
    planName: 'Зажигалки + Контрацептивы + Батареи',
    planId: null,
    amount: 2000,
    ballStep: 100,
    strategy: Strategy.orderAmount,
    expectedBonus: 'За каждые 2 000 ₽ получите - 100 Б',
  },
];

interface TEveryAmount {
  planId: number | null;
  amount: number;
  ballStep: number;
  strategy: Strategy;
  expectedBonus: string;
  planName: string;
}

export const TargetNamesRus = {
  [Strategy.val]: 'пач.',
  [Strategy.driveList]: 'пач.',
  [Strategy.amount]: 'руб.',
  [Strategy.orderAmount]: 'руб.',
  [Strategy.top24SaleVolume]: 'пач.',
  [Strategy.top24Material]: 'sku',
};

export const getMaxProgressByStrategy = (strategy: Strategy): number => {
  switch (strategy) {
    case Strategy.val:
    case Strategy.top24SaleVolume:
      return 120;
    case Strategy.driveList:
      return 100;
    default:
      return 100;
  }
};

const colors = ['#dbff00', '#00ff47', '#00ff47', '#00f0ff', '#00c2ff'];

const percentColors = {
  0: '#FF684D',
  10: '#FF684D',
  20: '#FF854D',
  30: '#FFA14C',
  40: '#FFBD4C',
  50: '#FFD94B',
  60: '#E2E651',
  70: '#BAEC5A',
  80: '#91F262',
  90: '#68F96B',
  100: '#40FF74',
  110: '#53D0FF',
};

const planLabels = {
  'Табак Вал': 'Табак ВАЛ',
  'ТОП-24. Закупить по 1 блоку': 'ТОП SKU',
  'ТОП-24. Объем продаж': 'ТОП Марки',
  'JTI Вал': 'JTI ВАЛ',
  'PMI Вал': 'PMI ВАЛ',
  'ITG Вал': 'ITG ВАЛ',
  'IQOS Вал, БЛК': 'IQOS ВАЛ',
  'Red Bull, руб': 'Red Bull',
  Комбо: 'Комбо',
};

const labelColors = {
  'Табак Вал': 'brown',
  'ТОП-24. Закупить по 1 блоку': 'purple',
  'ТОП-24. Объем продаж': 'blue',
  'JTI Вал': 'green',
  'PMI Вал': '',
  'ITG Вал': 'pink',
  'IQOS Вал, БЛК': 'sea',
  'Red Bull, руб': 'yellow-red',
  Комбо: 'pink-red',
};
