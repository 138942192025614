import { Component, Input, OnInit } from '@angular/core';
import { Plan } from '../../targets.model';

@Component({
  selector: 'iqretail-targets-group-item',
  templateUrl: './targets-group-item.component.html',
  styleUrls: ['./targets-group-item.component.scss'],
})
export class TargetsGroupItemComponent implements OnInit {
  @Input() plans: Plan[] = [];

  constructor() {}

  ngOnInit() {}

  get maxBonus() {
    return this.plans && this.plans.length && this.plans.reduce((accumulator, plan) => accumulator + plan.bonus.maxBonus, 0);
  }
}
