import {
  GetDelayedOrders,
  GetDelayedOrdersError,
  GetDelayedOrdersSuccess,
  LoadBonusesRecommendedOrders,
  LoadBonusesRecommendedOrdersByFilters,
  LoadBonusesRecommendedOrdersByFiltersFailure,
  LoadBonusesRecommendedOrdersByFiltersSuccess,
  LoadBonusesRecommendedOrdersFailure,
  LoadBonusesRecommendedOrdersSuccess,
  LoadBonusesRecommendedOrdersWithoutFilters,
  LoadBonusesRecommendedOrdersWithoutFiltersFailure,
  LoadBonusesRecommendedOrdersWithoutFiltersSuccess,
  LoadDelayedOrder,
  LoadDelayedOrderError,
  LoadDelayedOrderSuccess,
  LoadOrderProductsError,
  LoadOrderProductsSuccess,
  LoadOrderUsersError,
  LoadOrderUsersSuccess,
  OrderActionsTypes,
  OrderDetailsLoadError,
  OrderDetailsLoadRequest,
  OrderDetailsLoadSuccess,
  OrderHistoryLoadError,
  OrderHistoryLoadPageFailure,
  OrderHistoryLoadPageRequest,
  OrderHistoryLoadRequest,
  OrderHistoryLoadSuccess,
  OrderLoadError,
  OrderLoadPageFailure,
  OrderLoadPageRequest,
  OrderLoadRequest,
  OrderLoadSuccess,
  OrderSetActive,
  OrderSetActiveSuccess,
  OrderSetConfirmed,
  OrderSetConfirmedError,
  OrderSetConfirmedSuccess,
  OrderSetTotalCount,
  RemoveDelayedOrder,
  RemoveDelayedOrderError,
  RemoveDelayedOrderSuccess,
  RequestInvoiceDelayedOrder,
  RequestInvoiceDelayedOrderError,
  RequestInvoiceDelayedOrderSuccess,
  ResetHistoryOrdersValue,
  ResetOrdersValue,
  UpdateDelayedOrder,
  UpdateDelayedOrderError,
  UpdateDelayedOrderSuccess,
} from '@Mesh/store/order/order.actions';
import { OutletActionsTypes, OutletSetActive, OutletSetActiveId } from '@Mesh/store/outlet/outlet.actions';
import { Action, Store } from '@ngrx/store';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Order } from '@Mesh/core/models/order';
import { getGetStartDateAndFinishDate } from '@Mesh/core/services/util';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GetOrderHistoryCriteria, GetUserCriteria } from '@Mesh/core/models/request';
import { OrderService } from '@Mesh/core/services/order.service';
import { orderSelectors } from '@Mesh/store/order/order.selectors';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppState } from '@Mesh/store/app.state';
import { Injectable } from '@angular/core';
import { loginSelectors } from '../login/login.selectors';
import { ProductCriteria } from '../../core/models/request/product-criteria';
import { DataService } from '../../core/services/data.service';
import { DelayedOrderUpdatePayload } from '../../core/models/delayed-order';
import { UserService } from '../../core/services/user.service';

@Injectable()
export class OrderEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private orderService: OrderService,
    private dataService: DataService,
    private userService: UserService,
    private ngxService: NgxUiLoaderService
  ) {}

  @Effect()
  load: Observable<Action> = this.actions$.pipe(
    ofType<OrderLoadRequest>(OrderActionsTypes.OrderLoadRequest),
    switchMap((action) =>
      this.store$.select(outletSelectors.getActive).pipe(
        filter((outlet) => !!outlet),
        mergeMap((outlet) => this.orderService.getOrdersByOutlet(outlet.addressSapId, action.payload)),
        mergeMap((res) => [
          new OrderLoadSuccess({ orders: res.content, totalPages: res.totalPages, currentPage: 0 }),
          new OrderSetTotalCount({ totalCount: res.totalElements }),
        ])
      )
    ),
    catchError((error) => of(new OrderLoadError(error)))
  );

  @Effect()
  loadPage: Observable<Action> = this.actions$.pipe(
    ofType<OrderLoadPageRequest>(OrderActionsTypes.OrderLoadPageRequest),
    withLatestFrom(this.store$.select(orderSelectors.getOrders)),
    switchMap(([action, orders]) => {
      const activeOrdersCriteriaia: Partial<GetOrderHistoryCriteria> = {
        pageNumber: action.body.page,
        sort: [],
        pageSize: action.body.itemsPerPage,
        startDate: action.body.startDate,
        finishDate: action.body.finishDate,
        status: [0, 1],
      };
      return this.store$.select(outletSelectors.getActive).pipe(
        filter((outlet) => {
          return !!outlet;
        }),
        mergeMap((outlet) => this.orderService.getOrdersByOutlet(outlet.addressSapId, activeOrdersCriteriaia)),
        mergeMap((res) => {
          const newOrdersArr = [...orders, ...res.content] as Order[];
          return [
            new OrderLoadSuccess({ orders: newOrdersArr, totalPages: res.totalPages, currentPage: action.body.page }),
            new OrderSetTotalCount({ totalCount: res.totalElements }),
          ];
        })
      );
    }),
    catchError((error) => of(new OrderLoadPageFailure(error)))
  );

  @Effect()
  loadHistoryOrders: Observable<Action> = this.actions$.pipe(
    ofType<OrderHistoryLoadRequest>(OrderActionsTypes.OrderHistoryLoadRequest),
    switchMap((action) => {
      return this.store$.select(outletSelectors.getActive).pipe(
        filter((outlet) => !!outlet),
        mergeMap((outlet) => this.orderService.getOrdersByOutlet(outlet.addressSapId, action.payload)),
        map(
          (res) =>
            new OrderHistoryLoadSuccess({ orders: res.content, total: res.totalElements, totalpages: res.totalPages, currentPage: 0 })
        )
      );
    }),
    catchError((error) => of(new OrderHistoryLoadError(error)))
  );

  @Effect()
  loadHistoryOrdersPage: Observable<Action> = this.actions$.pipe(
    ofType<OrderHistoryLoadPageRequest>(OrderActionsTypes.OrderHistoryLoadPageRequest),
    withLatestFrom(this.store$.select(orderSelectors.getHistoryOrders)),
    switchMap(([action, orders]) => {
      const historyOrdersCriteriaia: Partial<GetOrderHistoryCriteria> = {
        sort: ['docDateTime|DESC', 'status|ASC'],
        pageSize: action.body.itemsPerPage,
        pageNumber: action.body.page,
        startDate: action.body.startDate,
        finishDate: action.body.finishDate,
        status: [0, 1, 2, 3],
      };
      return this.store$.select(outletSelectors.getActive).pipe(
        filter((outlet) => !!outlet),
        mergeMap((outlet) => this.orderService.getOrdersByOutlet(outlet.addressSapId, historyOrdersCriteriaia)),
        map((res) => {
          const newOrdersArr = [...orders, ...res.content] as Order[];
          return new OrderHistoryLoadSuccess({
            orders: newOrdersArr,
            total: res.totalElements,
            totalpages: res.totalPages,
            currentPage: action.body.page,
          });
        })
      );
    }),
    catchError((error) => of(new OrderHistoryLoadPageFailure(error)))
  );

  @Effect()
  setActive: Observable<Action> = this.actions$.pipe(
    ofType<OrderSetActive>(OrderActionsTypes.OrderSetActive),
    map(() => new OrderSetActiveSuccess()),
    catchError((error) => of(new OrderLoadError(error)))
  );

  @Effect()
  setConfirmed: Observable<Action> = this.actions$.pipe(
    ofType<OrderSetConfirmed>(OrderActionsTypes.OrderSetConfirmed),
    mergeMap((action) =>
      this.orderService.updateOrder(action.payload.docNumber, { delivered: true }).pipe(
        map(() => new OrderSetConfirmedSuccess({ docNumber: action.payload.docNumber })),
        catchError((error) => of(new OrderSetConfirmedError(error)))
      )
    )
  );

  @Effect()
  setActiveSuccess: Observable<Action> = this.actions$.pipe(
    ofType<OrderSetActiveSuccess>(OrderActionsTypes.OrderSetActiveSuccess),
    map(() => new OrderDetailsLoadRequest()),
    catchError((error) => of(new OrderLoadError(error)))
  );

  @Effect()
  loadOrderDetail: Observable<Action> = this.actions$.pipe(
    ofType<OrderDetailsLoadRequest>(OrderActionsTypes.OrderDetailsLoadRequest),
    switchMap(() => {
      // this.ngxService.start();
      return this.store$.select(orderSelectors.getSelectedId).pipe(
        filter((docNumber) => !!docNumber),
        mergeMap((docNumber) => this.orderService.getOrderDetails(docNumber)),
        map((res) => {
          // this.ngxService.stop();
          return new OrderDetailsLoadSuccess({ orderDetail: res });
        })
      );
    }),
    catchError((error) => of(new OrderDetailsLoadError(error)))
  );

  @Effect()
  changeOutlet: Observable<Action> = this.actions$.pipe(
    ofType<OutletSetActive | OutletSetActiveId>(OutletActionsTypes.OutletSetActive, OutletActionsTypes.OutletSetActiveId),
    map(() => {
      return new ResetOrdersValue();
    })
  );

  @Effect()
  loadActiveOrdersOnReset: Observable<Action> = this.actions$.pipe(
    ofType<ResetOrdersValue>(OrderActionsTypes.ResetOrdersValue),
    switchMap(() => {
      const [startDate, finishDate] = getGetStartDateAndFinishDate();
      return [
        new OrderLoadPageRequest({ page: 0, itemsPerPage: 10, startDate: startDate, finishDate: finishDate }),
        new GetDelayedOrders({ page: 0, size: 20 }),
        new OrderHistoryLoadPageRequest({ page: 0, itemsPerPage: 10, startDate: startDate, finishDate: finishDate }),
      ];
    })
  );

  @Effect()
  loadBonusRecommendedOrder$ = this.actions$.pipe(
    ofType<LoadBonusesRecommendedOrders>(OrderActionsTypes.LoadBonusesRecommendedOrders),
    withLatestFrom(this.store$.select(outletSelectors.getActive), this.store$.select(loginSelectors.selectUserId)),
    switchMap(([action, outlet, userId]) => {
      const payload = {
        userId: userId,
        ...action.payload,
      };
      return this.orderService.getBonusRecommendedOrders(outlet.addressSapId, payload).pipe(
        map((res) => new LoadBonusesRecommendedOrdersSuccess(res)),
        catchError((error) => of(new LoadBonusesRecommendedOrdersFailure(error)))
      );
    })
  );

  @Effect()
  loadBonusRecommendedOrderByFilters$ = this.actions$.pipe(
    ofType<LoadBonusesRecommendedOrdersByFilters>(OrderActionsTypes.LoadBonusesRecommendedOrdersByFilters),
    withLatestFrom(this.store$.select(outletSelectors.getActive), this.store$.select(loginSelectors.selectUserId)),
    switchMap(([action, outlet, userId]) => {
      const payload = {
        userId: userId,
        ...action.payload,
      };
      return this.orderService.getBonusRecommendedOrdersCategory(outlet.addressSapId, payload).pipe(
        map((res) => new LoadBonusesRecommendedOrdersByFiltersSuccess(res)),
        catchError((error) => of(new LoadBonusesRecommendedOrdersByFiltersFailure(error)))
      );
    })
  );

  @Effect()
  loadBonusesRecommendedOrdersWithoutFilters$ = this.actions$.pipe(
    ofType<LoadBonusesRecommendedOrdersWithoutFilters>(OrderActionsTypes.LoadBonusesRecommendedOrdersWithoutFilters),
    withLatestFrom(this.store$.select(outletSelectors.getActive), this.store$.select(loginSelectors.selectUserId)),
    switchMap(([action, outlet, userId]) => {
      const payload = {
        userId: userId,
        ...action.payload,
      };
      return this.orderService.getBonusRecommendedOrders(outlet.addressSapId, payload).pipe(
        map((res) => new LoadBonusesRecommendedOrdersWithoutFiltersSuccess(res)),
        catchError(() => of(new LoadBonusesRecommendedOrdersWithoutFiltersFailure()))
      );
    })
  );

  @Effect()
  loadCartProducts$ = this.actions$.pipe(
    ofType<GetDelayedOrdersSuccess>(OrderActionsTypes.GetDelayedOrdersSuccess),
    withLatestFrom(this.store$.select(outletSelectors.getActive), this.store$.select(orderSelectors.getDelayedOrders)),
    switchMap(([, outlet, orders]) => {
      const materialIds = orders.reduce((acc, val) => acc.concat(val.carts.map((item) => item.materialId)), []);
      const params: ProductCriteria = {
        pageNumber: 0,
        pageSize: 9999,
        sort: 'rank|ASC',
        addressSapId: outlet.addressSapId,
        clientSapId: outlet.clientSapId,
        materialIds,
      };
      return this.dataService.getProducts(params).pipe(
        map((data) => {
          return new LoadOrderProductsSuccess(data.content);
        }),
        catchError((error) => {
          return of(new LoadOrderProductsError(error));
        })
      );
    })
  );

  @Effect()
  loadOrderUsers$ = this.actions$.pipe(
    ofType<GetDelayedOrdersSuccess>(OrderActionsTypes.GetDelayedOrdersSuccess),
    withLatestFrom(this.store$.select(outletSelectors.getActive), this.store$.select(orderSelectors.getDelayedOrders)),
    switchMap(([_, outlet, orders]) => {
      const userIds = orders.reduce((acc, val) => acc.concat(val.carts.map((item) => item.userId)), []);
      const payload = {
        // addressSapId: [outlet.addressSapId],
        // clientSapId: outlet.clientSapId,
        userIds,
      };
      return this.userService.getUsers(payload).pipe(
        map((res) => new LoadOrderUsersSuccess(res.content)),
        catchError((error) => of(new LoadOrderUsersError(error)))
      );
    })
  );

  @Effect()
  loadDelayedOrders$ = this.actions$.pipe(
    ofType<GetDelayedOrders>(OrderActionsTypes.GetDelayedOrders),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    switchMap(([action, outlet]) => {
      const payload = {
        ...action.payload,
      };
      return this.orderService.getDelayedOrders(outlet.addressSapId, payload).pipe(
        map((res) => new GetDelayedOrdersSuccess(res)),
        catchError((error) => of(new GetDelayedOrdersError(error)))
      );
    })
  );

  @Effect()
  loadDelayedOrder$ = this.actions$.pipe(
    ofType<LoadDelayedOrder>(OrderActionsTypes.LoadDelayedOrder),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    switchMap(([action, outlet]) => {
      return this.orderService.getDelayedOrder(outlet.addressSapId, action.payload.delayedOrderId).pipe(
        map((res) => new LoadDelayedOrderSuccess(res)),
        catchError((error) => of(new LoadDelayedOrderError(error)))
      );
    })
  );

  @Effect()
  RemoveDelayedOrders$ = this.actions$.pipe(
    ofType<RemoveDelayedOrder>(OrderActionsTypes.RemoveDelayedOrder),
    withLatestFrom(this.store$.select(outletSelectors.getActive)),
    switchMap(([action, outlet]) => {
      return this.orderService.dropDelayedOrderForAddress(outlet.addressSapId, action.payload.delayedOrderId).pipe(
        map((res) => new RemoveDelayedOrderSuccess({ delayedOrderId: action.payload.delayedOrderId })),
        catchError((error) => of(new RemoveDelayedOrderError(error)))
      );
    })
  );

  @Effect()
  UpdateDelayedOrders$ = this.actions$.pipe(
    ofType<UpdateDelayedOrder>(OrderActionsTypes.UpdateDelayedOrder),
    withLatestFrom(this.store$.select(outletSelectors.getActive), this.store$.select(loginSelectors.selectUserId)),
    switchMap(([action, outlet, userId]) => {
      const payload: DelayedOrderUpdatePayload = {
        userId: userId,
        ...action.payload,
      };
      return this.orderService.updateDelayedCart(outlet.addressSapId, payload).pipe(
        map((res) => {
          const quantities = res && res.carts.reduce((prev, curr) => prev + (curr.quantity || 0), 0);
          if (!quantities) {
            return new RemoveDelayedOrder({ delayedOrderId: res.id });
          } else {
            return new UpdateDelayedOrderSuccess(res);
          }
        }),
        catchError((error) => of(new UpdateDelayedOrderError(error)))
      );
    })
  );

  @Effect()
  RequestInvoiceDelayedOrders$ = this.actions$.pipe(
    ofType<RequestInvoiceDelayedOrder>(OrderActionsTypes.RequestInvoiceDelayedOrder),
    map((action) => action.payload),
    switchMap((payload) => {
      const params: { shouldRequestInvoice: boolean } = {
        shouldRequestInvoice: payload.shouldRequestInvoice,
      };
      return this.orderService.requestInvoiceDelayedCart(payload.delayedOrderId, params).pipe(
        map(() => {
          return new RequestInvoiceDelayedOrderSuccess({ delayedOrderId: payload.delayedOrderId });
        }),
        catchError((error) => of(new RequestInvoiceDelayedOrderError(error)))
      );
    })
  );
}
