import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatTaskPreviewComponent } from './chat-task-preview.component';
import { ChatTaskPreviewStepComponent } from './chat-task-preview-step/chat-task-preview-step.component';
import { SafeModule } from '../../safe/safe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatTaskProductModule } from '../chat-task-product/chat-task-product.module';
import { ChatTaskImagesComponent } from './chat-task-images/chat-task-images.component';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { ProgressRecommendedOrderModule } from '../../../../pages/catalog/basket/progress-recommended-order/progress-recommended-order.module';

@NgModule({
  declarations: [ChatTaskPreviewComponent, ChatTaskPreviewStepComponent, ChatTaskImagesComponent],
  exports: [ChatTaskPreviewComponent],
  imports: [
    CommonModule,
    SafeModule,
    ReactiveFormsModule,
    FormsModule,
    ChatTaskProductModule,
    GalleryModule,
    LightboxModule,
    ProgressRecommendedOrderModule,
  ],
})
export class ChatTaskPreviewModule {}
