import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { trigger } from '@angular/animations';
import { fadeIn, fadeOut } from '@Mesh/utils/animations/fade-animations';
import { TrainingTaskWidgetService } from '@Mesh/shared/modules/training-tasks/components/training-task-widget/training-task-widget.service';
import { tap } from 'rxjs/operators';
import { Task } from '@Mesh/core/models/task';

@Component({
  selector: 'app-training-task-widget',
  templateUrl: './training-task-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./training-task-widget.component.scss'],
  animations: [trigger('fadeOut', fadeOut()), trigger('fadeIn', fadeIn())],
  providers: [TrainingTaskWidgetService],
})
export class TrainingTaskWidgetComponent {
  spinner = SPINNER;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  trainingTasks$ = this.widgetService.trainingTasks$.pipe(tap(() => this.loading$.next(true)));

  mock: Task = {
    taskId: 1,
    id: 1,
    name: 'Task name',
    cost: 12,
    active: true,
    addressSapId: 1,
    clientSapId: 1,
    comment: 'description',
    countStep: 2,
    dateStart: '12-12-12',
    dateEnd: '12-12-12',
    dateOfCreation: '12-12-12',
    imageUrl: '',
    notOverdue: false,
    onModeration: false,
    taskCompleted: false,
    status: 'Status',
    step: [],
    progress: 19,
  };

  readonly loaderId: string = 'task-progress-loader';

  constructor(private ngxService: NgxUiLoaderService, private widgetService: TrainingTaskWidgetService) {
    // this.ngxService.startLoader(this.loaderId);
  }
}
