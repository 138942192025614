import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Outlet } from '../../../../core/models';
import { CartRuleGroupedStatus, MinimalCartRuleGroupedStatus } from '../../../../store/cart/cart.models';

@Component({
  selector: 'app-basket-total-info',
  templateUrl: './basket-total-info.component.html',
  styleUrls: ['./basket-total-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketTotalInfoComponent implements OnInit {
  @Input() outlet: Outlet;
  @Input() totalOrderPrice: number;
  @Input() totalAmountOfOrder: number;
  @Input() totalOrderDiscountPrice: number;
  @Input() minimalCartRulesGroupStatus: MinimalCartRuleGroupedStatus;
  @Input() nextDeliveryDate: string;
  @Output() ordered = new EventEmitter<any>();
  CartRuleGroupedStatus = CartRuleGroupedStatus;
  today = new Date();

  constructor() {}

  ngOnInit() {}

  onOrder() {
    this.ordered.emit();
  }

  get hasDiscount() {
    return this.totalOrderPrice !== this.totalOrderDiscountPrice;
  }
}
