import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Outlet, User } from '../../../organization.model';

export const permissionIcons = {
  MANAGER: 'manager-permission.svg',
  ADDR_BOSS: 'addr-boss-permission.svg',
  ADD_USER: 'add-user-permission.svg',
  ORDR_TSK: 'order-permission.svg',
  OWNER: 'Icon-Star.svg',
};

@Component({
  selector: 'iqretail-staff-item',
  templateUrl: './staff-item.component.html',
  styleUrls: ['./staff-item.component.scss'],
})
export class StaffItemComponent implements OnChanges {
  @Input() user: User;
  @Input() currentUser: User;
  @Input() activeOutlet: Outlet;

  @Output() editUser = new EventEmitter<number>();
  @Output() removeUser = new EventEmitter<number>();
  @Output() showUserHistoryPoint = new EventEmitter<number>();

  showCross = false;
  showEdit = false;
  permissions = [];

  pattern = { Y: { pattern: new RegExp('.'), symbol: 'X' } };

  ngOnChanges({ user, currentUser }: SimpleChanges) {
    if (user && user.currentValue) {
      this.setPermissions((user.currentValue as User).permissions, (user.currentValue as User).type);
    }
    if (((user && user.currentValue) || this.user) && ((currentUser && currentUser.currentValue) || this.currentUser)) {
      const neededUser = (user && user.currentValue) || this.user;
      const neededCurrentUser = (currentUser && currentUser.currentValue) || this.currentUser;
      this.setCross(neededUser, neededCurrentUser);
      this.setShowEdit(neededUser, neededCurrentUser);
    }
  }

  deleteUser() {
    this.removeUser.emit(this.user.id);
  }

  onShowPointsHistory() {
    this.showUserHistoryPoint.emit(this.user.id);
  }

  private setPermissions(permissions: { addressSapId: number; permissions: string[] }[] | null, type: 'OWNER' | 'MANAGER' | 'EMPLOYEE') {
    if (permissions && this.activeOutlet) {
      const perms = new Set<string>();
      const activeAddressPermissions = permissions.find((el) => el.addressSapId === this.activeOutlet.addressSapId);
      if (activeAddressPermissions && activeAddressPermissions.permissions) {
        activeAddressPermissions.permissions.forEach((perm) => {
          perms.add(perm);
        });
      }
      this.permissions = Array.from(perms).map((perm: string) => permissionIcons[perm]);
    }
    if (type === 'MANAGER') {
      this.permissions = [...this.permissions, permissionIcons.MANAGER];
    }
    if (type === 'OWNER') {
      this.permissions = [...this.permissions, permissionIcons.OWNER];
    }
  }

  private setCross(user: User, currentUser: User) {
    if (user.type !== 'OWNER' && currentUser.type === 'OWNER') {
      this.showCross = true;
    }
    if (currentUser.type === 'MANAGER' && user.type !== 'OWNER' && user.type !== 'MANAGER') {
      this.showCross = true;
    }
  }

  private setShowEdit(user: User, currentUser: User) {
    if (currentUser.type === 'OWNER' || currentUser.type === 'MANAGER') {
      this.showEdit = true;
    }
    // todo - Возможность редактировать полномочия всех сотрудников на конкретной ТТ ЮЛ for ADDR_BOSS
  }
}
