import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { User } from '@Mesh/core/models/user';
import { TaskState } from '@Mesh/store/task/task.state';
import { Store } from '@ngrx/store';
import { AppState, selectTaskState } from '@Mesh/store/app.state';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-old-tasks-widget',
  templateUrl: './tasks-widget.component.html',
  styleUrls: ['./tasks-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksWidgetComponent implements OnInit {
  isCollapsed = window.innerWidth < 992;
  active = true;
  _user: User;
  _tasks: Observable<TaskState>;
  onReady: boolean = false;
  constructor(private store: Store<AppState>) {
    this._tasks = this.store.select(selectTaskState);
  }

  clickHeader($event: MouseEvent): void {
    if (this.isCollapsed) {
      this.active = !this.active;
    }
  }

  @Input()
  set user(value: User) {
    this._user = value;
  }

  get user() {
    return this._user;
  }

  set tasks(value: Observable<TaskState>) {
    this._tasks = value;
  }

  get tasks() {
    return this._tasks;
  }

  ngOnInit() {
    this._tasks.pipe(tap((state) => (this.onReady = state.success))).subscribe();
  }
}
