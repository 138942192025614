import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShopWidgetComponent } from '@Mesh/shared/modules/shop/components/shop-widget/shop-widget.component';
import { ComponentsModule } from '../../components/components.module';
import { UserInfoModule } from '@Mesh/shared/modules/user-info/user-info.module';

@NgModule({
  declarations: [ShopWidgetComponent],
  exports: [ShopWidgetComponent],
  imports: [CommonModule, ComponentsModule, UserInfoModule],
})
export class ShopModule {}
