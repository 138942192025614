import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { BlankComponent, CondensedComponent, RootLayout } from './@pages/layouts';
import { pagesToggleService } from './@pages/services/toggler.service';
import { SidebarComponent } from './@pages/components/sidebar/sidebar.component';
import { QuickviewComponent } from './shared/components/quickview/quickview.component';
import { QuickviewService } from './shared/components/quickview/quickview.service';
import { HeaderComponent } from './@pages/components/header/header.component';
import { HorizontalMenuComponent } from './@pages/components/horizontal-menu/horizontal-menu.component';
import { SharedModule } from './@pages/components/shared.module';
import { pgListViewModule } from './@pages/components/list-view/list-view.module';
import { pgCardSocialModule } from './@pages/components/card-social/card-social.module';
import {
  AccordionModule,
  AlertModule,
  BsDropdownModule,
  ButtonsModule,
  CollapseModule,
  ModalModule,
  ProgressbarModule,
  TabsModule,
  TooltipModule,
  TypeaheadModule,
} from 'ngx-bootstrap';
import { pgSwitchModule } from './@pages/components/switch/switch.module';
import { ProgressModule } from './@pages/components/progress/progress.module';
import { IsotopeModule } from 'ngx-isotope';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { QuillModule } from 'ngx-quill';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { CondensedDashboardComponent } from './pages/dashboard/condensed/dashboard.component';
import { TradePointsModule } from '@iqlru/iqretail-components';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { MessageService } from './@pages/components/message/message.service';
import { MessageModule } from './@pages/components/message/message.module';
import { ResponsiveModule } from 'ngx-responsive';
import { NgxCdkResponsiveModule } from 'ngx-cdk-responsive';
import { TitleModule } from './core/modules/title/title.module';
import { PageLayoutComponent } from './core/modules/page-layout/page-layout.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { WindowFocusModule } from './core/modules/window-focus/window-focus.module';
import { ErrorComponent } from './pages/error/error.component';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CommonComponent } from '@Mesh/@pages/layouts/common/common.component';
import { LocalStorageService } from '@Mesh/core/services/local-storage';
import { ChatModule } from '@Mesh/shared/modules/chat/chat.module';
import { AppStoreModule } from '@Mesh/store/app-store.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { UserDialogsComponent } from './pages/user-dialogs/user-dialogs.component';
import { NgxMaskModule } from 'ngx-mask';
import { CountdownModule } from 'ngx-countdown';
import { ComponentsModule } from './shared/components/components.module';
import { NotificationsModule } from './shared/modules/notifications/notifications.module';
import locale from '@angular/common/locales/ru';
import { CatalogModalModule } from './pages/catalog/components/product-table/catalog-modal/catalog-modal.module';
import { CompanyModule } from '@Mesh/shared/modules/company/company.module';
import { TasksModule } from '@Mesh/shared/modules/tasks/tasks.module';
import { TargetsModule } from './shared/modules/targets/targets.module';
import { ActiveOrdersModule } from '@Mesh/shared/modules/orders/active-orders.module';
import { OrganizationModule } from '@Mesh/shared/modules/organization/organization.module';
import { HistoryModule } from '@Mesh/shared/modules/history/history.module';
import { TrainingTasksModule } from '@Mesh/shared/modules/training-tasks/training-tasks.module';
import { ShopModule } from '@Mesh/shared/modules/shop/shop.module';

registerLocaleData(locale);
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

// Hammer Config Overide
// https:// github.com/angular/angular/issues/10541
export class AppHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

const config = {
  debounceTime: 100,
};

@NgModule({
  declarations: [
    AppComponent,
    CondensedComponent,
    CommonComponent,
    SidebarComponent,
    QuickviewComponent,
    HeaderComponent,
    HorizontalMenuComponent,
    BlankComponent,
    RootLayout,
    ErrorComponent,
    CondensedDashboardComponent,
    PageLayoutComponent,
    UnderConstructionComponent,
    UserDialogsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    NgxCdkResponsiveModule,
    SharedModule,
    ProgressModule,
    pgListViewModule,
    pgCardSocialModule,
    ChatModule,
    RouterModule.forRoot(AppRoutes, { onSameUrlNavigation: 'reload' }),
    TitleModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    ResponsiveModule.forRoot(),
    WindowFocusModule.forRoot(),
    IsotopeModule,
    NgxDnDModule,
    QuillModule,
    PerfectScrollbarModule,
    pgSwitchModule,
    DashboardModule,
    MessageModule,
    BreadcrumbModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    SwiperModule,
    AppStoreModule,
    ComponentsModule,
    NgxDaterangepickerMd.forRoot(),
    SlickCarouselModule,
    ReactiveFormsModule,
    NgxMaskModule,
    CountdownModule,
    TradePointsModule,
    TasksModule,
    HistoryModule,
    ActiveOrdersModule,
    TargetsModule,
    OrganizationModule,
    NotificationsModule,
    CatalogModalModule,
    CompanyModule,
    TrainingTasksModule,
    ShopModule,
  ],
  providers: [
    QuickviewService,
    MessageService,
    pagesToggleService,
    LocalStorageService,
    FeathersService,
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
