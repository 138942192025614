import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, switchMap, tap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  AcceptCode,
  AcceptCodeError,
  AcceptCodeSuccess,
  ApplicationError,
  ApplicationRequest,
  ApplicationSuccess,
  GetLegalEntities,
  GetLegalEntitiesResponse,
  SetClient,
  SetClientSuccess,
  SetClientFailure,
  CheckExistPhoneNumber,
  CheckExistPhoneNumberError,
  CheckExistPhoneNumberSuccess,
  ConfirmRegister,
  ConfirmRegisterError,
  ConfirmRegisterSuccess,
  LoginRequest,
  LoginActionsTypes,
  LoginError,
  LoginSuccess,
  RegisterPhoneNumber,
  RegisterPhoneNumberError,
  RegisterPhoneNumberSuccess,
  Signup,
  SignupError,
  SignupSuccess,
  LogoutRequest,
  EmptyAction,
  RequestUpdatePassword,
  RequestUpdatePasswordSuccess,
  RequestUpdatePasswordFailure,
  VerifyUpdatePassword,
  VerifyUpdatePasswordSuccess,
  VerifyUpdatePasswordFailure,
  SetNewPasswordRequest,
  SetNewPasswordRequestSuccess,
  SetNewPasswordRequestFailure,
  RequestUserUpdatePassword,
  RequestUserUpdatePasswordSuccess,
  RequestUserUpdatePasswordFailure,
  UpdateLoginUserAvatar,
  GetUser,
  GetUserSuccess,
  GetUserFailure,
  SetClientNext,
  SendSmsCodeParticipant,
  SendSmsCodeParticipantSuccess,
  SendSmsCodeParticipantFailure,
  CreateParticipant,
  CreateParticipantSuccess,
  CreateParticipantFailure,
  RefreshToken,
  RefreshTokenSuccess,
  RefreshTokenFailure,
} from './login.actions';
import { AuthenticationService } from '@Mesh/core/services';
import { Action, Store } from '@ngrx/store';
import { IUserInfo } from '@Mesh/core/models';
import { Router } from '@angular/router';
import { AppState } from '@Mesh/store/app.state';
import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { SetPasswordActionBody } from '@Mesh/core/models/request/password-models';
import { UpdateAvatarSuccess, UserActionsTypes } from '@Mesh/store/user/user.actions';
import {
  Participant,
  ParticipantRequestPost,
  ParticipantRequestPostConfirm,
  ParticipantResponsePostConfirm,
  ParticipantRequestGet,
} from '@Mesh/core/models/participant';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  @Effect()
  login: Observable<Action> = this.actions$.pipe(
    ofType<LoginRequest>(LoginActionsTypes.Login),
    switchMap((action) => {
      return this.authService.login(action.payload).pipe(
        map((response: IUserInfo) => {
          return new LoginSuccess({ token: response.accessToken, refreshToken: response.refreshToken, user: response.user });
        }),
        catchError((error) => of(new LoginError(error)))
      );
    })
  );

  @Effect({ dispatch: false })
  logout = this.actions$.pipe(
    ofType<LogoutRequest>(LoginActionsTypes.Logout),
    tap(() => {
      return this.authService.logout();
    })
  );

  @Effect()
  getLegalEntities: Observable<Action> = this.actions$.pipe(
    ofType<GetLegalEntities>(LoginActionsTypes.GetLegalEntities),
    switchMap(() => {
      return this.authService.getLegalEntities().pipe(map((response: any) => new GetLegalEntitiesResponse(response)));
    })
  );

  @Effect()
  checkExistPhoneNumber: Observable<Action> = this.actions$.pipe(
    ofType<CheckExistPhoneNumber>(LoginActionsTypes.CheckExistPhoneNumber),
    switchMap((action) => {
      return this.authService.checkExistPhoneNumber(action.payload.phoneNumber).pipe(
        mergeMap((response: any) => {
          return [
            new CheckExistPhoneNumberSuccess({ canBeAuthorized: null }),
            new CheckExistPhoneNumberSuccess({ canBeAuthorized: response.canBeAuthorized }),
          ];
        }),
        catchError((error) => of(new CheckExistPhoneNumberError(error)))
      );
    })
  );

  @Effect()
  RegisterPhoneNumber: Observable<Action> = this.actions$.pipe(
    ofType<RegisterPhoneNumber>(LoginActionsTypes.RegisterPhoneNumber),
    switchMap((action) => {
      return this.authService.registerPhoneNumber(action.payload.phoneNumber).pipe(
        map((response) => {
          return new RegisterPhoneNumberSuccess({
            identifier: response.identifier,
            documents: response.documents,
            shouldCreatePassword: response.shouldCreatePassword,
          });
        }),
        catchError((error) => of(new RegisterPhoneNumberError(error)))
      );
    })
  );

  @Effect()
  confirmRegister: Observable<Action> = this.actions$.pipe(
    ofType<ConfirmRegister>(LoginActionsTypes.ConfirmRegister),
    switchMap((action) => {
      return this.authService.registerPhoneNumberConfirm(action.payload.identifier).pipe(
        map((response) => {
          return new ConfirmRegisterSuccess(response);
        }),
        catchError((error) => of(new ConfirmRegisterError(error)))
      );
    })
  );

  @Effect()
  acceptCode: Observable<Action> = this.actions$.pipe(
    ofType<AcceptCode>(LoginActionsTypes.AcceptCode),
    switchMap((action) => {
      return this.authService.acceptCode(action.payload).pipe(
        map((response: any) => {
          return new AcceptCodeSuccess({});
        }),
        catchError((error) => of(new AcceptCodeError(error)))
      );
    })
  );

  @Effect()
  signup: Observable<Action> = this.actions$.pipe(
    ofType<Signup>(LoginActionsTypes.Signup),
    switchMap((action) => {
      return this.authService.signup(action.payload).pipe(
        map((userInfo: IUserInfo) => {
          return new SignupSuccess(userInfo);
        }),
        catchError((error) => of(new SignupError(error)))
      );
    })
  );

  @Effect()
  application: Observable<Action> = this.actions$.pipe(
    ofType<ApplicationRequest>(LoginActionsTypes.ApplicationRequest),
    switchMap((action) => {
      return this.authService.application(action.payload).pipe(
        map(() => {
          return new ApplicationSuccess({ applicationStatus: true });
        }),
        catchError((error) => of(new ApplicationError(error)))
      );
    })
  );

  @Effect()
  requestUpdatePassword = this.actions$.pipe(
    ofType<RequestUpdatePassword>(LoginActionsTypes.RequestUpdatePassword),
    switchMap((action) => {
      return this.authService.updatePasswordRequest(action.payload.phoneNumber).pipe(
        map((data) => new RequestUpdatePasswordSuccess({ identifier: data.identifier })),
        catchError((error) => of(new RequestUpdatePasswordFailure(error)))
      );
    })
  );

  @Effect()
  userUpdatePassword = this.actions$.pipe(
    ofType<RequestUserUpdatePassword>(LoginActionsTypes.RequestUserUpdatePassword),
    switchMap((action) => {
      return this.authService.updatePasswordRequest(action.payload.phoneNumber).pipe(
        map((data) => new RequestUserUpdatePasswordSuccess({ identifier: data.identifier, password: action.payload.password })),
        catchError((error) => of(new RequestUserUpdatePasswordFailure(error)))
      );
    })
  );

  @Effect()
  verifyUpdatePassword = this.actions$.pipe(
    ofType<VerifyUpdatePassword>(LoginActionsTypes.VerifyUpdatePassword),
    withLatestFrom(this.store.select(loginSelectors.selectUpdatePhoneIdentifier)),
    withLatestFrom(this.store.select(loginSelectors.newUserPassword)),
    switchMap(([[action, identifier], password]) => {
      const body: SetPasswordActionBody = {
        code: action.payload.code,
        password: password,
      };
      return this.authService.updatePasswordVerify({ code: action.payload.code, identifier }).pipe(
        mergeMap(() => {
          return [new VerifyUpdatePasswordSuccess(), new SetNewPasswordRequest(body)];
        }),
        catchError((error) => of(new VerifyUpdatePasswordFailure(error)))
      );
    })
  );

  @Effect()
  setNewPassword = this.actions$.pipe(
    ofType<SetNewPasswordRequest>(LoginActionsTypes.SetNewPasswordRequest),
    withLatestFrom(this.store.select(loginSelectors.selectUpdatePhoneIdentifier)),
    switchMap(([action, identifier]) => {
      return this.authService.setNewPassword({ ...action.payload, identifier }).pipe(
        map((data) => new SetNewPasswordRequestSuccess(data)),
        catchError((error) => of(new SetNewPasswordRequestFailure(error)))
      );
    })
  );

  @Effect()
  setNewAvatar = this.actions$.pipe(
    ofType<UpdateAvatarSuccess>(UserActionsTypes.UpdateAvatarSuccess),
    map((action) => {
      return new UpdateLoginUserAvatar({ avatar: action.payload.content.relativeUrl });
    })
  );

  @Effect()
  getUser = this.actions$.pipe(
    ofType<GetUser>(LoginActionsTypes.GetUser),
    withLatestFrom(this.store.select(loginSelectors.selectUserId)),
    filter((userId) => !!userId),
    switchMap(([, userId]) => {
      return this.authService.getUser(userId).pipe(
        map((user) => {
          return new GetUserSuccess({ user });
        }),
        catchError((err) => {
          return of(new GetUserFailure({ error: err }));
        })
      );
    })
  );

  @Effect()
  setClient: Observable<Action> = this.actions$.pipe(
    ofType<SetClient>(LoginActionsTypes.SetClient),
    switchMap(({ payload }) => {
      return this.authService.setClient(payload.clientSapId).pipe(
        map((response) => {
          return new SetClientSuccess({
            token: response.accessToken,
            refreshToken: response.refreshToken,
            user: response.user,
            redirect: payload.redirect,
          });
        }),
        catchError((err) => {
          return of(new SetClientFailure({ error: err }));
        })
      );
    })
  );

  @Effect()
  sendSmsCodeParticipant: Observable<Action> = this.actions$.pipe(
    ofType<SendSmsCodeParticipant>(LoginActionsTypes.SendSmsCodeParticipant),
    switchMap((action) => {
      return this.authService.sendSmsCodeParticipant(action.payload).pipe(
        map((response: ParticipantResponsePostConfirm) => {
          return new SendSmsCodeParticipantSuccess(response);
        }),
        catchError((error) => of(new SendSmsCodeParticipantFailure(error)))
      );
    })
  );

  @Effect()
  createParticipant: Observable<Action> = this.actions$.pipe(
    ofType<CreateParticipant>(LoginActionsTypes.CreateParticipant),
    switchMap((action) => {
      return this.authService.createParticipant(action.payload).pipe(
        map((response: Participant) => {
          return new CreateParticipantSuccess(response);
        }),
        catchError((error) => of(new CreateParticipantFailure(error)))
      );
    })
  );

  @Effect()
  refreshToken: Observable<Action> = this.actions$.pipe(
    ofType<RefreshToken>(LoginActionsTypes.RefreshToken),
    throttleTime(5000),
    mergeMap(({ payload }) => {
      return this.authService.refreshToken(payload.refreshToken).pipe(
        map((response) => {
          return new RefreshTokenSuccess({ token: response.accessToken, refreshToken: response.refreshToken });
        }),
        catchError((err) => {
          return of(new RefreshTokenFailure({ error: err }));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  refreshTokenSuccess$ = this.actions$.pipe(
    ofType<RefreshTokenSuccess>(LoginActionsTypes.RefreshTokenSuccess),
    tap(() => {
      console.log('RefreshToken выполнился успешно');
    })
  );

  @Effect()
  refreshTokenFailure$ = this.actions$.pipe(
    ofType<RefreshTokenFailure>(LoginActionsTypes.RefreshTokenFailure),
    tap(() => {
      console.log('RefreshToken выполнился c ошибкой');
    }),
    map(() => new LogoutRequest())
  );
}
