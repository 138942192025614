import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'times',
})
export class TimesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return moment.utc(value).local().format('hh:mm');
  }
}
