import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { loginSelectors } from '@Mesh/store/login/login.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { userSelectors } from '@Mesh/store/user/user.selectors';
import { documentSelectors } from '@Mesh/store/document/document.selectors';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Outlet, User } from '@Mesh/core/models';
import { outletSelectors } from '@Mesh/store/outlet/outlet.selectors';
import { UsersLoadPageRequest } from '@Mesh/store/user/user.actions';
import { DocChange } from '@Mesh/pages/profile/components/event-types';
import { map, takeUntil } from 'rxjs/operators';
import { Documents } from '@Mesh/core/models/documents';
import { HttpClient } from '@angular/common/http';
import { SelectedUserBonusBalanceLoad } from '@Mesh/store/bonus/bonus.actions';
import { UserBonusModalComponent } from '@Mesh/pages/dashboard/components/user-bonus-modal/user-bonus-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-documents-widget',
  templateUrl: './documents-widget.component.html',
  styleUrls: ['./documents-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsWidgetComponent implements OnInit, OnDestroy {
  user$ = this.store.select(loginSelectors.selectUser);
  users$ = this.store.select(userSelectors.users);
  documents$ = this.store.select(documentSelectors.getDocuments);
  usersTotalCount$ = this.store.select(userSelectors.totalCount);
  activeOutlet$: Observable<Outlet> = this.store.select(outletSelectors.getActive);
  totalDocs$ = this.store.select(documentSelectors.getDocumentTotalCount);
  usersTotalPages$ = this.store.select(userSelectors.usersTotalPages);
  getUsersCurrentPage$ = this.store.select(userSelectors.usersCurrentPage);

  readonly openView$ = new BehaviorSubject<
    'addEmployee' | 'sms-verification' | 'sms-verified' | 'employee-deleted-verified' | 'employee-delete' | 'update-pass' | 'none'
  >('none');
  readonly userDialogsData$ = new BehaviorSubject<{ show: boolean; selectedUserId: number }>(undefined);
  readonly deleteUserData$ = new BehaviorSubject<number>(undefined);
  readonly subscribeDocumentToggle = new BehaviorSubject(false);
  readonly selectedDoc$ = new BehaviorSubject<Documents>(undefined);
  readonly subscribeButtonToggle = new BehaviorSubject(false);
  bsModalRef: any;

  readonly iframeSrc$ = new BehaviorSubject<string>(undefined);
  documents: Documents[] = [];

  private unsubscribe$ = new Subject();

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService
  ) {
    this.user$ = this.store.pipe(select(loginSelectors.selectUser));
  }

  ngOnInit() {
    this.openView$.subscribe((v) => {
      if (v === 'none') {
        document.body.classList.remove('disable-scroll');
      } else {
        document.body.classList.add('disable-scroll');
      }
    });

    this.store
      .select(documentSelectors.getDocuments)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((docs) => {
        this.documents = docs;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }

  loadUsersPages(page: number) {
    this.store.dispatch(new UsersLoadPageRequest({ page: page, itemsPerPage: 10 }));
  }

  openDialog(
    onViewType:
      | 'addEmployee'
      | 'sms-verification'
      | 'sms-verified'
      | 'employee-deleted-verified'
      | 'employee-delete'
      | 'update-pass'
      | 'none'
  ) {
    this.openView$.next(onViewType);
  }

  editUser(userId: number) {
    this.onShowAddEmployee(true, userId);
  }

  onShowAddEmployee(show: boolean, selectedUserId = -1): void {
    this.userDialogsData$.next({ show, selectedUserId });
  }

  removeUser(userId: number) {
    this.deleteUserData$.next(userId);
    this.openDialog('employee-delete');
  }

  downloadDocument(docId: number): void {
    this.onDocChange({ type: 'downloadDoc', data: docId });
  }

  onDocChange(evt: DocChange): void {
    switch (evt.type) {
      case 'signDoc':
      case 'downloadDoc':
        this.onShowDoc(true, evt.data);
        break;
      default:
        console.log('fake call with: ', evt);
    }
  }

  onShowDoc(flag: boolean, id?: number): void {
    if (!flag) {
      document.body.classList.remove('disable-scroll');
      this.subscribeDocumentToggle.next(false);
    } else {
      if (!this.selectedDoc$.value || this.selectedDoc$.value?.id !== id) {
        this.selectedDoc$.next(this.documents.find((d) => d.id === id));
        this.downloadFile(this.selectedDoc$.value?.htmlUrl)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((blob) => {
            this.iframeSrc$.next(URL.createObjectURL(blob));
            this.subscribeButtonToggle.next(!this.selectedDoc$.value?.isSigned && this.selectedDoc$.value?.shouldSign);
            document.body.classList.add('disable-scroll');
            this.subscribeDocumentToggle.next(true);
            this.cdr.detectChanges();
          });
      }
    }
  }

  private downloadFile(filePath: string): Observable<any> {
    return this.http.get(filePath, { responseType: 'text' }).pipe(map((data) => new Blob([data], { type: 'text/html' })));
  }

  signDocument(docId: number): void {
    this.onDocChange({ type: 'signDoc', data: docId });
  }

  onShowUserHistoryPoint(user: User) {
    if (this.bsModalRef) {
      this.bsModalRef.content.closed.unsubscribe();
    }
    this.store.dispatch(new SelectedUserBonusBalanceLoad({ userId: user.id }));
    this.bsModalRef = this.modalService.show(UserBonusModalComponent, {
      initialState: { user },
      class: 'modal-dialog-centered user-bonus-modal',
    });
    this.bsModalRef.content.closed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.bsModalRef.hide();
    });
  }
}
