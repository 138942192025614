import { SetActiveBonusAndPredictBonusByPlanId } from '@Mesh/store/bonus/bonus.actions';
import { bonusSelectors } from '@Mesh/store/bonus';
import { Observable } from 'rxjs/Observable';
import { ProductTableService } from '@Mesh/core/services/product-table.service';
import { CatalogListComponent } from '@Mesh/pages/catalog/catalog-list/catalog-list.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { OutletLoadRequest } from '@Mesh/store/outlet/outlet.actions';
import { PlanLoadRequest } from '@Mesh/store/plan/plan.actions';
import { select, Store } from '@ngrx/store';
import { AppState } from '@Mesh/store/app.state';
import { TableColumn } from '@Mesh/core/models/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@Mesh/core/services';
import { ResizeHelper } from '@Mesh/shared/helpers/resize.helper';
import { BasketHelper } from '@Mesh/shared/helpers/basket.helper';
import { CatalogHelper } from '@Mesh/shared/helpers/catalog.helper';
import * as moment from 'moment';
import { Bonus } from '@Mesh/core/models/bonus';
import { BehaviorSubject, combineLatest, interval } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, last, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ScrollService } from '@Mesh/pages/catalog/services/scroll.service';
import { IStickers, planListColumns, planStickers } from '@Mesh/pages/catalog/consts/table-consts';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CartActions, CartSelectors } from '@Mesh/store/cart';
import { CartItemDisplay, CartRuleGroupedStatus, MinimalCartRuleGroupedStatus } from '@Mesh/store/cart/cart.models';
import { LoadMinimalCartRuleGroupedStatus, LoadMinimalCartRulesByCategory } from '../../../store/cart/cart.actions';

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.scss', '../catalog-list/catalog-list.component.scss'],
  animations: [
    trigger('basketButtonOpenClose', [
      state(
        'open',
        style({
          opacity: '*',
          bottom: '15px',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          bottom: '-56px',
        })
      ),
      transition('open => closed', [animate('0.7s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanListComponent extends CatalogListComponent implements OnInit, OnDestroy {
  columns: TableColumn[] = planListColumns;
  itemsPerSlide = 3;
  singleSlideOffset = false;
  noWrap = false;

  slidesChangeMessage = '';
  activeDate = moment();
  readonly typeState$ = new BehaviorSubject<boolean>(false);

  stickers: IStickers[] = planStickers;

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: "<div class='nav-btn next-slide'></div>",
    prevArrow: "<div class='nav-btn prev-slide'></div>",
    dots: false,
    infinite: false,
  };
  activeMobileSlide: IStickers;
  activeIndex: any;
  reSizeToggle = false;
  webMode = true;
  tabletMode = false;
  mobileMode = false;
  searchLocationToggle = false;
  basketToggle = false;

  bonuses$ = this.store.select(bonusSelectors.getOutletBonuses);

  activeBonus$: Observable<Bonus>;
  activePredictBonus$: Observable<Bonus>;
  activeBonus: Bonus;
  activeBonusId$ = this.store.select(bonusSelectors.getActiveBonusId);

  activeBackground$?: Observable<string>;
  minimalCartRulesGroupStatus$: Observable<MinimalCartRuleGroupedStatus>;
  CartRuleGroupedStatus = CartRuleGroupedStatus;

  readonly plans$ = this.store.select(bonusSelectors.getPlans);

  readonly categories$ = this.plans$.pipe(
    map((plans) => {
      const set = new Set<string>(plans?.map((el) => el.category) || []);
      return Array.from(set);
    })
  );

  readonly activeCategory$ = new BehaviorSubject<string>(undefined);
  readonly totalCartItems$ = this.store.select(CartSelectors.numberOfCartItems);

  isDataInSwiper$ = this.activeCategory$.pipe(
    filter((el) => !!el),
    switchMap((category) => {
      return this.store.select(bonusSelectors.getBonusesByCategory(category));
    }),
    map((data) => !!data?.length)
  );

  constructor(
    public store: Store<AppState>,
    public route: ActivatedRoute,
    public router: Router,
    public dataService: DataService,
    public productTableService: ProductTableService,
    public resize: ResizeHelper,
    public basket: BasketHelper,
    public cdr: ChangeDetectorRef,
    public catalogHelper: CatalogHelper,
    public scrollService: ScrollService
  ) {
    super(dataService, store, productTableService, resize, catalogHelper, basket, route, router, scrollService);
    combineLatest([this.route.queryParams, this.bonuses$])
      .pipe(
        filter(([queryParams, bonuses]) => !!queryParams && !!bonuses?.length),
        take(1)
      )
      .subscribe(([queryParams, bonuses]) => {
        if (queryParams['planId'] && !isNaN(Number(queryParams['planId'])) && bonuses?.length) {
          const planId = Number(queryParams['planId']);
          this.store.dispatch(new SetActiveBonusAndPredictBonusByPlanId({ planId }));
        } else if (bonuses?.length) {
          const planId = bonuses[0].planId;
          this.store.dispatch(new SetActiveBonusAndPredictBonusByPlanId({ planId }));
        }
      });
    this.resize.recalcSize();
  }

  calcPlanColor(bonus: Bonus) {
    if (bonus) {
      const color = Math.floor(bonus.executedPercent / 10) + 1;
      return color < 11 ? color : 11;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.scrollService.onScroll();
  }

  curMonth() {
    return moment().startOf('month').format('DD.MM') + ' - ' + moment().endOf('month').format('DD.MM');
  }

  ngOnInit() {
    this.store.dispatch(new CartActions.LoadCart());
    this.store.dispatch(new OutletLoadRequest({}));
    this.store.dispatch(new PlanLoadRequest());
    this.getActiveBonus();
    this.minimalCartRulesGroupStatus$ = this.store.pipe(select(CartSelectors.selectMinimalCartRulesGroupStatus));
    this.store.pipe(select(CartSelectors.selectCart), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.store.dispatch(new LoadMinimalCartRuleGroupedStatus());
    });
    interval(2000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.typeState$.next(!this.typeState$.value);
      });

    this.activeBonusId$.pipe(takeUntil(this.unsubscribe$)).subscribe((planId) => {
      if (planId) {
        this.getProduct({ planId: planId.toString() }, false);
      } else {
        this.getProduct({ planId: '' }, false);
      }
    });

    this.activeBackground$ = this.activeBonus$.pipe(
      map((bonus) => {
        if (!bonus) {
          return undefined;
        }

        return this.catalogHelper.getBackground(bonus.planId);
      })
    );

    combineLatest([this.plans$, this.activeBonus$])
      .pipe(
        filter(([plans, activeBonus]) => !!plans?.length && activeBonus !== undefined),
        map(([plans, activeBonus]) => {
          const category = plans.find((el) => el.plan_id === activeBonus.planId)?.category;
          return { category, plans };
        }),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        debounceTime(300),
        take(1)
      )
      .subscribe(({ category, plans }) => {
        if (category) {
          this.activeCategory$.next(category);
        } else {
          this.activeCategory$.next(plans[0].category);
        }
      });

    combineLatest([this.activeCategory$, this.plans$, this.activeBonus$])
      .pipe(
        filter(([activeCategory, plans]) => !!plans?.length && !!activeCategory),
        filter(([activeCategory, plans, activeBonus]) => {
          const plansByCategory = plans.filter((el) => el.category === activeCategory).map((el) => el.plan_id);
          const isBonusMatchCategory = plansByCategory.includes(activeBonus.planId);
          // we should not change active bonus if it is the same activeCategory;
          return !isBonusMatchCategory;
        }),
        debounceTime(300),
        map(([activeCategory, plans]) => plans.find((el) => el.category === activeCategory)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((selectedPlan) => {
        if (selectedPlan) {
          this.store.dispatch(new SetActiveBonusAndPredictBonusByPlanId({ planId: selectedPlan.plan_id }));
        }
      });
  }

  @HostListener('window:resize', [])
  caller() {
    this.resize.recalcSize();
  }
  onSlideRangeChange(indexes: number[] | void): void {
    this.slidesChangeMessage = `Slides have been switched: ${indexes}`;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetActivePlan() {
    this.store.dispatch(new SetActiveBonusAndPredictBonusByPlanId({ planId: null }));
  }

  getActiveBonus() {
    this.activeBonus$ = this.store.select(bonusSelectors.getActiveBonus);
    this.activePredictBonus$ = this.store.select(bonusSelectors.getActivePredictBonus);
    this.activeBonus$.pipe(takeUntil(this.unsubscribe$)).subscribe((bonus) => {
      this.activeBonus = bonus;
      this.cdr.detectChanges();
    });
  }

  changeActiveSlide(item: IStickers) {
    this.dataService.headerBgcSubject.next(item.headerBgc);
  }

  changeMobileActiveSlide(e) {
    this.dataService.headerBgcSubject.next(this.stickers[e].headerBgc);
  }

  incrementAmount(product: CartItemDisplay) {
    this.store.dispatch(
      new CartActions.IncrementProductInCart({
        id: product.id,
        unit: product.unit,
        materialId: product.materialId,
        distributorName: product.distributorName,
      })
    );
  }

  decrementAmount(product: CartItemDisplay) {
    this.store.dispatch(
      new CartActions.DecrementProductInCart({
        id: product.id,
        unit: product.unit,
        materialId: product.materialId,
        distributorName: product.distributorName,
      })
    );
  }

  onSetSelectedCategory(category: string) {
    this.activeCategory$.next(category);
  }
}
