import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  SimpleChanges,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { CartItemDisplay } from '../../../../store/cart/cart.models';
import { SalePlans } from '../../../../core/models/product';
import { CatalogHelper } from '../../../../shared/helpers/catalog.helper';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-basket-product-item-sm',
  templateUrl: './basket-product-item-sm.component.html',
  styleUrls: ['./basket-product-item-sm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketProductItemSmComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() product: CartItemDisplay;
  @Output() decrementedAmount: EventEmitter<any> = new EventEmitter();
  @Output() incrementedAmount: EventEmitter<any> = new EventEmitter();
  @Output() deleted: EventEmitter<any> = new EventEmitter();
  @Output() showedOrderHistory: EventEmitter<any> = new EventEmitter();
  @Output() toggleFavorite: EventEmitter<any> = new EventEmitter();
  @Output() changeAmount: EventEmitter<any> = new EventEmitter();
  @ViewChild('salePlanDrag', { read: DragScrollComponent }) salePlanDrag: DragScrollComponent;
  countProduct = new FormControl(0, [Validators.min(0)]);

  constructor(private catalogHelper: CatalogHelper) {}

  ngOnInit(): void {
    this.countProduct.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)).subscribe((amount) => {
      if (amount > this.product.quantityMaterial) {
        this.changeAmount.emit({ product: this.product, amount: this.product.quantityMaterial });
      } else {
        this.changeAmount.emit({ product: this.product, amount });
      }
    });
  }

  ngAfterViewInit() {
    // if (this.filteredPlans && this.filteredPlans.length) {
    //   setTimeout(() => {
    //     this.salePlanDrag.moveTo(this.filteredPlans.length - 1);
    //   }, 100);
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.countProduct.setValue(this.product.quantity, { emitEvent: false });
    this.countProduct.setValidators([Validators.min(0), Validators.max(this.product.quantityMaterial)]);
  }

  incrementAmount(): void {
    this.incrementedAmount.emit();
  }

  decrementAmount(): void {
    this.decrementedAmount.emit();
  }

  deleteCartItem(): void {
    this.deleted.emit();
  }

  onShowOrderHistory(): void {
    this.showedOrderHistory.emit();
  }

  onToggleFavorite(): void {
    this.toggleFavorite.emit();
  }

  getShortPlanName(salePlan: SalePlans): string {
    return this.catalogHelper.getShortPlanName(salePlan);
  }

  get filteredPlans(): SalePlans[] {
    return this.product && this.product.salePlans && this.catalogHelper.filteredPlans(this.product.salePlans);
  }

  getLabelBg(planId: number): string {
    return this.catalogHelper.getLabelBg(planId);
  }

  get currentPlan() {
    return this.product && this.product.salePlans && this.product.salePlans.length && this.product.salePlans[0];
  }

  get productProgress(): number {
    if (this.currentPlan) {
      return (this.currentPlan.quant / (this.currentPlan.quant + this.currentPlan.planLeftQuantity)) * 100;
    } else {
      return 0;
    }
  }

  get attentionCount() {
    if (this.product?.unit === 'БЛК' && this.product?.quantity >= 10) {
      return true;
    }

    if (this.product?.unit === 'КОР' && this.product?.quantity >= 1) {
      return true;
    }

    return false;
  }
}
