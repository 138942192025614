import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { queryState } from '@Mesh/core/models/async-item';
import { fadeIn, fadeOut } from '@Mesh/utils/animations/fade-animations';
import { Award } from '@Mesh/core/models/award';
import { User } from '@Mesh/core/models';
import { ShopWidgetService } from '@Mesh/shared/modules/shop/components/shop-widget/shop-widget.service';

@Component({
  selector: 'app-shop-widget',
  templateUrl: './shop-widget.component.html',
  styleUrls: ['./shop-widget.component.scss', './ghost/ghost-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('collapseState', [
      state(
        'inactive',
        style({
          opacity: '0',
          height: 0,
          display: 'none',
        })
      ),
      state(
        'active',
        style({
          opacity: '1',
          height: '*',
          display: '*',
        })
      ),
      transition('inactive => active', animate('125ms ease-in')),
      transition('active => inactive', animate('125ms ease-out')),
    ]),
    trigger('fadeOut', fadeOut()),
    trigger('fadeIn', fadeIn()),
  ],
})
export class ShopWidgetComponent implements OnInit {
  state = queryState;
  award$: Observable<Award> = new BehaviorSubject<Award>(new Award());
  isCollapsed = false;
  _active = true;
  defaultImage = '/assets/stub/iqschool-truck.png';
  private _user: User;
  dummyAward: Award;
  @Input()
  set user(value: User) {
    const id = this.user?.id;
    this._user = value;

    if (this.user && this.user.id !== id) {
      this.init();
    }
  }

  get user(): User {
    return this._user;
  }

  set Active(value: boolean) {
    this._active = value;
  }

  get Active(): boolean {
    return this._active;
  }

  @HostListener('window:resize', [])
  onResize() {
    this.toggleNavbar();
  }

  toggleNavbar() {
    this.isCollapsed = window.innerWidth < 992;
    if (window.innerWidth <= 560) {
      this.Active = false;
      this.cdr.markForCheck();
    }
  }

  clickHeader($event: MouseEvent): void {
    if (this.isCollapsed) {
      this.Active = !this.Active;
    } else {
      this.router.navigate(['/shop']);
    }
  }

  constructor(private router: Router, private awardService: ShopWidgetService, private cdr: ChangeDetectorRef) {}

  auctionClick(id) {
    if (id) {
      this.router.navigate(['/shop/product/ ', id]);
    }
  }

  init() {
    this.award$ = new BehaviorSubject<Award>(new Award());
  }

  ngOnInit() {
    this.createDummyAward();
    this.isCollapsed = window.innerWidth < 992;
    if (window.innerWidth <= 560) {
      this.Active = false;
      this.cdr.markForCheck();
    }
  }

  raiseBet(award_id) {
    this.router.navigate(['/shop/product/', award_id]);
  }

  private createDummyAward(): void {
    this.dummyAward = new Award();
    this.dummyAward.main_image = '/assets/stub/iqschool-truck.png';
    this.dummyAward.name = 'Скоро';
    this.dummyAward.description = 'Здесь появятся товары!';
  }
}
