export interface Documents {
  clientSapId: number;
  documentType: string;
  endDate: string;
  filename: string;
  html: null;
  id: number;
  iqReportId: number;
  isSigned: boolean;
  isDeclined: boolean;
  signedAt: string;
  signedAtInSecond?: number;
  startDate: string;
  description?: string;
  htmlUrl?: string;
  shouldSign: boolean;
  url: string;
}

export const documentsTypes = [
  'SALEPLAN_DS',
  'TASK',
  'TASK_SUPPLEMENT',
  'TASK_SUPPLEMENT_MATRIX',
  'SALEPLAN_ACT',
  'TASK_ACT',
  'TASK_DETAIL',
  'TASK_MATRIX',
  'TASK_RECOMMENDED_ORDER',
  'LICENSE_AGREEMENT',
  'SUBLICENSE_AGREEMENT',
  'MEGACOM_SUBLICENSE_AGREEMENT',
  'RETAIL_DISTRIBUTION',
  'PERSONAL_DATA_AGREEMENT',
  'CLIENT_ADDRESSES',
  'USER_AGREEMENT',
  'EQUIPMENT_INSTALL',
  'EQUIPMENT_DEINSTALL',
  'AGREEMENT_PARTICIPATION',
];

export enum AgreementsType {
  SALEPLAN_DS = 'План продаж',
  TASK = 'Задание',
  TASK_SUPPLEMENT = 'Дополнение задачи',
  TASK_SUPPLEMENT_MATRIX = 'Дополнение задачи (матрица)',
  AGREEMENT_PARTICIPATION = 'Cоглашение об Участии',
}

export enum ActsType {
  SALEPLAN_ACT = 'Акт плана продаж',
  TASK_ACT = 'Акт задания',
  TASK_DETAIL = 'Детализация задания',
  TASK_MATRIX = 'Матрица задания',
  TASK_RECOMMENDED_ORDER = 'Рекомендованный заказ',
  EQUIPMENT_INSTALL = 'Акт установки оборудования',
  EQUIPMENT_DEINSTALL = 'Акт демонтажа оборудования',
}

export enum RestType {
  LICENSE_AGREEMENT = 'Лицензионное соглашение',
  SUBLICENSE_AGREEMENT = 'Сублицензионное соглашение',
  RETAIL_DISTRIBUTION = 'Розниченое распространение',
  PERSONAL_DATA_AGREEMENT = 'Персональное соглашение',
  CLIENT_ADDRESSES = 'Адреса клиентов',
  USER_AGREEMENT = 'Пользовательское соглашение',
  MEGACOM_SUBLICENSE_AGREEMENT = 'Мегаком Сублицензионное соглашение',
}

export type DocType = 'agreements' | 'acts' | 'rest';
