import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Bonus, Plan, PlanCategory } from '../targets.model';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const today = new Date();

@Component({
  selector: 'iqretail-targets-content',
  templateUrl: './targets-content.component.html',
  styleUrls: ['./targets-content.component.scss'],
})
export class TargetsContentComponent implements OnInit, OnDestroy {
  @Input() isTab = false;
  @Input() plans: Plan[];
  @Input() bonuses: Bonus[] = [];
  @Input() predictBonuses: Bonus[] = [];
  @Input() activeCategory: string;
  @Input() scrollDistance: number;
  @Input() throttle: number;
  @Input() categories: string[] = [];
  @Input() imageUrl: string;
  @Output() scrollEnd = new EventEmitter();

  @Output() categoryChange = new EventEmitter<string>();
  @Output() navigateToPlan = new EventEmitter<number>();
  @Output() navigateToCatalog = new EventEmitter();

  typeState$ = new BehaviorSubject(false);

  startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  PlanCategory = PlanCategory;

  active = true;

  private unsubscribe$ = new Subject();

  onCategoryChange(category: string) {
    this.activeCategory = category;
    this.categoryChange.emit(this.activeCategory);
  }

  ngOnInit() {
    interval(5000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.typeState$.next(!this.typeState$.value);
      });
  }

  toggle() {
    this.active = !this.active;
  }

  onScrollEnd() {
    this.scrollEnd.emit();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
