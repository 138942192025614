import { Injectable } from '@angular/core';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { BehaviorSubject, combineLatest, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ChatMessage } from '../models/chat-message';
import { ChatPager } from '../chat.service';
import { Comment } from '@Mesh/core/models/comment';
import { User } from '@Mesh/core/models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NODE_URL } from '@Env/environment';
import { uniqByKeepFirst } from '@Mesh/shared/helpers/object.helpers';
import { Sounds, SoundService } from '@Mesh/core/services/chat/sound.service';
import { UserService } from '@Mesh/core/services/user.service';
import { StepType } from '@Mesh/core/models/task';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { ChatDialog } from '../models/chat-dialog';

export enum ResponseType {
  CREATED = 'created',
  UPDATED = 'updated',
  PATCHED = 'patched',
  REMOVED = 'removed',
  FOUND = 'found',
  TYPING = 'typing',
}

export class ChatMessageResponse {
  type?: ResponseType;
  data: ChatMessage | ChatMessage[];
}

export const ModuleTypes = ['article', 'book', 'course', 'exam', 'scorm', 'video', 'film', 'podcast'];

export type ChatDialogType = 'user' | 'clan' | 'alliance' | 'guild';

export interface ITypingUser {
  type: string;
  typeId: number | null;
  replyUserId?: number;
  fullname?: string;
}

interface SendMessageParams {
  text: string;
  type: string;
  attached?: { messages: Comment[]; uploads: File[] };
  canceled: boolean;
  approved: boolean;
  answer?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ChatDialogService {
  communityChat;
  messagesSubject: Subject<{ type: ResponseType; data: ChatMessage | ChatMessage[] }> = new Subject();
  typingSubject: Subject<{ type: ResponseType; data: ITypingUser }> = new Subject();
  messages: ChatMessage[] = [];
  paginatedResult: any = {};
  total;
  record;
  current_user;
  loadImage;
  update$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  nextUpdateUploads: { id: number; uploads: any[] };
  dialog: ChatDialog;
  typingSubscription;
  stepId;

  constructor(
    private feathers: FeathersService,
    private userService: UserService,
    private soundService: SoundService,
    private httpClient: HttpClient
  ) {
    this.feathers
      .service('messages/:type/:typeId')
      .on(ResponseType.CREATED, (msg) => {
        this.soundService.play(Sounds.CHAT_MESSAGE_INCOMING);
        console.log('record:', msg, this.record);
        const predicator = this.record && msg.type === this.record.type && msg.typeId === this.record.info.id;
        if (predicator && !this.messages.find(({ id }) => id === msg.id)) {
          console.log('created:', msg, this.record);
          this.messagesSubject.next({ type: ResponseType.CREATED, data: msg });
        }
      })
      .on(ResponseType.PATCHED, (msg) => {
        console.log('patched:', msg, this.record);
        const predicator = this.record && msg.type === this.record.type && msg.typeId === this.record.info.id;
        if (predicator && this.messages.find(({ id }) => id === msg.id)) {
          this.messagesSubject.next({ type: ResponseType.PATCHED, data: msg });
        }
      })
      .on(ResponseType.REMOVED, (msg) => {
        console.log('removed:', msg, this.record);
        const predicator = this.record && msg.type === this.record.type;
        if (predicator && this.messages.find(({ id }) => id === msg.id)) {
          this.messagesSubject.next({ type: ResponseType.REMOVED, data: msg });
        }
      });
    this.feathers
      .service('users-chat')
      .on(ResponseType.CREATED, (msg) => this.messagesSubject.next({ type: ResponseType.CREATED, data: msg }))
      .on(ResponseType.PATCHED, (msg) => this.messagesSubject.next({ type: ResponseType.PATCHED, data: msg }))
      .on(ResponseType.REMOVED, (msg) => this.messagesSubject.next({ type: ResponseType.REMOVED, data: msg }))
      .on('shouldUpdate', (data) => {
        let u_id = (data as ChatMessage).userId,
          reply_u_id = (data as ChatMessage).replyUserId;
        if (
          this.record &&
          (this.isTaskChat ? true : u_id === this.record.id || reply_u_id === this.record.id) &&
          (data as ChatMessage).addressSapId === this.dialog.addressSapId &&
          (data as ChatMessage).type === this.dialog.type &&
          (data as ChatMessage).typeId === this.dialog.typeId
        ) {
          this.update$.next(true);
          console.log('shouldUpdate', data);
        }
      });

    this.feathers
      .service('typing-users')
      .on(ResponseType.CREATED, (data) => this.typingSubject.next({ type: ResponseType.CREATED, data }))
      .on(ResponseType.REMOVED, (data) => this.typingSubject.next({ type: ResponseType.REMOVED, data }));
  }

  setTypingStatus(data) {
    this.feathers.service('typing-users').create(data);
  }

  get isCommunityChat() {
    return false;
  }

  get isTaskChat() {
    return this.dialog?.type === 'task-comments' || this.dialog?.type === 'support';
  }

  get isModuleChat() {
    return ModuleTypes.indexOf(this.dialog?.type) !== -1;
  }

  get service() {
    if (this.isCommunityChat) {
      return this.feathers.service(`messages/${this.record.type}/${this.record.info.id}`);
    } else {
      return this.feathers.service('users-chat');
    }
  }

  async sendAppliedTask(value) {
    console.log('sendApproveTask:', this.dialog);
    await this.feathers.service('users-chat/task-step-success').create({
      isStepInfo: false,
      addressSapId: this.dialog.addressSapId,
      stepId: this.dialog.stepId,
      type: this.dialog.type,
      typeId: this.dialog.typeId,
      userId: this.current_user.id,
      replyUserId: this.record.id,
      isApprovedTask: value,
      isCanceledTask: !value,
    });
  }

  readMessage(msg) {
    this.service.patch(msg.id, { readAt: new Date() });
  }

  markIsReadMessage(message: any): Promise<ChatMessage> {
    const { id } = message;
    return this.service.patch(id, { readAt: moment().toISOString() });
  }

  removeUpload(upload) {
    const message = this.messages.find((msg) => {
      return msg.id === upload.typeId;
    });
    const attachedUploads = message.attached.uploads.filter((item) => item.id !== upload.id);
    this.feathers.service('upload-media').remove(upload.id);
    this.messagesSubject.next({
      type: ResponseType.PATCHED,
      data: { ...message, attached: { messages: message.attached.messages, uploads: attachedUploads } },
    });
  }

  addMessageToList(msg) {
    // this.messages.pop();
    console.log(this.messages, msg);
    const msgIndex = this.messages.findIndex(({ id }) => msg.id === id);
    if (msgIndex === -1) {
      this.messages.unshift(msg);
      this.total++;
    } else {
      this.messages[msgIndex] = { ...msg };
    }
    return this.messages.sort((a, b) => {
      if (a.sort === b.sort) {
        // Price is only important when cities are the same
        return b.id - a.id;
      }
      return b.sort - a.sort;
    });
  }

  activateTypingStatus(): Observable<any> {
    return new Observable((observer) => {
      if (this.typingSubscription) {
        this.typingSubscription.unsubscribe();
      }
      this.typingSubscription = this.typingSubject.subscribe(({ type, data }) => {
        if (type === ResponseType.CREATED) {
          observer.next({ type: ResponseType.TYPING, data });
        } else {
          observer.next({ type: ResponseType.REMOVED, data });
        }
      });
    });
  }

  subscription;

  activateChat({ record, current_user, dialog }: { record: User | any; current_user: User | any; dialog?: ChatDialog }): Observable<any> {
    this.record = record;
    this.current_user = current_user;
    this.dialog = dialog;
    this.total = undefined;

    console.log('active chat');
    return new Observable((observer) => {
      console.log('active observable');
      this.messages = [];
      this.paginatedResult = {};
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.subscription = this.messagesSubject.subscribe(({ type, data }: { type: ResponseType; data: ChatMessage | ChatMessage[] }) => {
        if ([ResponseType.CREATED, ResponseType.PATCHED, ResponseType.REMOVED].includes(type)) {
          console.log('data:', data, this.dialog, this.record);
          let u_id = (data as ChatMessage).user?.id,
            reply_u_id = (data as ChatMessage).replyUser?.id;
          if (
            (this.record &&
              type === ResponseType.CREATED &&
              (+(data as ChatMessage).addressSapId !== +this.dialog.addressSapId ||
                +(data as ChatMessage).typeId !== +this.dialog.typeId)) ||
            (this.record && this.record.type === 'all')
          ) {
            console.log('activateChatUsers:', u_id, reply_u_id, this.record.id);
            return;
          }
        }

        if (type === ResponseType.FOUND) {
          let lastIndex = 0;
          Object.keys(this.paginatedResult)
            .sort()
            .forEach((key) => {
              const result = this.paginatedResult[key];
              /*result.sort((a, b) => {
              return b.id - a.id;
            });*/

              this.messages = [...this.messages, ...result];
              this.messages.sort((a, b) => {
                if (a.sort === b.sort) {
                  // Price is only important when cities are the same
                  return b.id - a.id;
                }
                return b.sort - a.sort;
              });
              lastIndex = this.messages.findIndex((item) => item.id === result[result.length - 1].id);
              this.messages = uniqByKeepFirst(this.messages, (msg) => msg.id);

              // const stocksMessage = this.messages.find((m: any) => m?.answer === 'amount' && m?.taskStep?.type?.name === StepType.calculateStock) as any;

              // if (!!stocksMessage) {
              //   this.store.dispatch(loadPlanStock({
              //     planId: stocksMessage.taskStep.planId,
              //     outletId: stocksMessage.taskStep.addressSapId,
              //     date: moment.utc(stocksMessage.createdAt).format('YYYY-MM-DD')
              //   }));
              // }
            });
          setTimeout(() => observer.next({ messages: this.messages, type, lastIndex }), 1000);
        }

        if (type === ResponseType.CREATED) {
          const msg = data as ChatMessage;
          console.log(msg, this.record);
          if (msg.replyUser && msg.replyUser.id === this.current_user.id && this.record && this.record.id === msg.user.id) {
            //this.readMessage(data);
          }
          if (this.nextUpdateUploads && this.nextUpdateUploads.id === msg.id) {
            msg.attached.uploads = [...this.nextUpdateUploads.uploads];
          }
          console.log('msg:', msg);
          console.log('nextUpdateUploads:', this.nextUpdateUploads);
          observer.next({
            messages: this.addMessageToList(msg) as any,
            type,
          });
        }

        if (type === ResponseType.PATCHED) {
          this.messages = this.messages.map((msg) => {
            if (msg.id === (data as ChatMessage).id) {
              const newMsg = data as ChatMessage;
              if (this.nextUpdateUploads && this.nextUpdateUploads.id === newMsg.id) {
                if (newMsg.attached.uploads.length === 0) {
                  newMsg.attached.uploads = [...this.nextUpdateUploads.uploads];
                }
                if (newMsg.attached.uploads.length === msg.attached.uploads.length) {
                  newMsg.attached.uploads = msg.attached.uploads.map((upload) => {
                    const newUpload = newMsg.attached.uploads.find((u) => u.name === upload.name);
                    return newUpload;
                  });
                }
                this.nextUpdateUploads = null;
                console.log('msg:', newMsg);
                console.log('nextUpdateUploads:', this.nextUpdateUploads);
              }
              return { ...newMsg };
            } else {
              return msg;
            }
          });
          console.log(this.messages);
          observer.next({ messages: this.messages, type });
        }
        if (type === ResponseType.REMOVED) {
          this.messages = this.messages.filter((msg) => msg.id !== (data as ChatMessage).id);
          this.total--;
          console.log(this.messages);
          observer.next({ messages: this.messages, type });
        }
      });
      console.log('messages load');
      this.getMessages({ page: 0 });
    });
  }

  updateUploads(id, uploads) {
    const message = this.messages.find((msg) => {
      return msg.id === id;
    });
    console.log('messages:', this.messages);
    console.log('id:', id);
    console.log('message:', message);
    message.attached.uploads = [...message.attached.uploads, ...uploads];

    this.nextUpdateUploads = { id, uploads: [...uploads] };
    if (message) {
      this.messagesSubject.next({ type: ResponseType.PATCHED, data: message });
    }
  }

  getMessages({ page = 0, take = 10, skip = page * take }: ChatPager = {}) {
    if (typeof this.total !== undefined && this.messages.length >= this.total) {
      return;
    }
    let query: any = {
      $limit: take,
      $skip: skip,
      $sort: {
        createdAt: -1,
      },
    };

    //if (!this.isCommunityChat && !this.isTaskChat) {
    query.participant = this.record.id;
    //}

    if (this.dialog) {
      if (this.dialog.type) {
        query.type = this.dialog.type;
      }
      if (this.dialog.typeId) {
        query.typeId = this.dialog.typeId;
      }
      if (this.dialog.addressSapId) {
        query.addressSapId = this.dialog.addressSapId;
      }
    }

    console.log('get messages 2');

    return this.service
      .watch({
        idField: 'id',
        listStrategy: 'never',
      })
      .find({
        query,
      })
      .pipe(first())
      .subscribe(({ data, total }) => {
        console.log('get messages 3');
        this.total = total;
        this.paginatedResult[page] = data;
        this.messagesSubject.next({ type: ResponseType.FOUND, data });
      });
  }

  editMessage(message): Promise<ChatMessage> {
    const { id, text, attached } = message;
    return this.service.patch(id, { text, attached });
  }

  findNotifications(): Promise<ChatMessage> {
    return this.feathers.service('notifications').find({ $like: 'сообщение', $limit: 30, $skip: 0, $sort: { createdAt: -1 } });
  }

  removeMessage({ id }): Promise<ChatMessage> {
    this.soundService.play(Sounds.CHAT_MESSAGE_DELETE);
    return this.service.remove(id);
  }

  sendMessage({
    text,
    type = 'users-chat',
    canceled,
    approved,
    attached = {
      messages: [],
      uploads: [],
    },
    answer,
  }: SendMessageParams): Observable<Comment> {
    this.soundService.play(Sounds.CHAT_MESSAGE_OUTCOMING);
    return forkJoin([
      this.getMessagesToQuote({ messages: attached.messages, type }),
      this.uploadMedia({ uploads: attached.uploads, type }),
    ]).pipe(
      map(([messages, uploads]) => ({ attached: { messages, uploads } })),
      map(({ attached }) => {
        switch (true) {
          case this.isCommunityChat:
            return this.service.create(new ChatMessage({ text, attached }));
          case this.isTaskChat:
            return this.service.create(
              new ChatMessage({
                text,
                attached,
                answer,
                replyUserId: this.record.id,
                type: this.dialog.type,
                typeId: this.dialog.typeId,
                taskId: this.dialog.task && this.dialog.task.id,
                stepId: this.dialog.stepId,
                approved,
                canceled,
                addressSapId: this.dialog.addressSapId,
                senderAddressSapId: this.dialog.addressSapId,
              })
            );
          case this.isModuleChat:
            return this.service.create(
              new ChatMessage({
                text,
                attached,
                replyUserId: this.record.id,
                type: this.dialog.type,
                typeId: this.dialog.typeId,
              })
            );
          default:
            return this.service.create(new ChatMessage({ text, attached, replyUserId: this.record.id }));
        }
      })
    );
  }

  forwardMessage({ text, record, dialog, type = 'users-chat', attached = { messages: [], uploads: [] } }): Observable<Comment> {
    let r = record;
    let d = dialog || {};
    this.record = record;
    console.log(record);

    return forkJoin(
      this.getMessagesToQuote({ messages: attached.messages, type }),
      this.uploadMedia({ uploads: attached.uploads, type })
    ).pipe(
      map(([messages, uploads]) => ({ attached: { messages, uploads } })),
      map(({ attached }) => {
        switch (true) {
          case this.isCommunityChat:
            return this.service.create(new ChatMessage({ text, attached }));
          case d.type === 'task-comments':
          case d.type === 'task-comments':
            return this.service.create(
              new ChatMessage({
                text,
                attached,
                replyUserId: r.id,
                type: d.type,
                typeId: d.typeId,
                stepId: d.stepId,
                addressSapId: d.addressSapId,
                senderAddressSapId: null,
              })
            );
          case ModuleTypes.indexOf(d.type) !== -1:
            return this.service.create(new ChatMessage({ text, attached, replyUserId: r.id, type: d.type, typeId: d.typeId }));
          default:
            return this.service.create(new ChatMessage({ text, attached, replyUserId: r.id }));
        }
      })
    );
  }

  uploadMedia({ uploads, type }: { uploads: File[]; type: string }): Observable<Array<number>> {
    return !uploads.length
      ? of([])
      : combineLatest([
          ...uploads.map((file) => {
            const formData = new FormData();
            formData.append('media', file, file.name);
            formData.append('type', type);
            let headers = new HttpHeaders();
            headers = headers.append('UserId', this.current_user.id);
            headers = headers.append('no-content-type', 'multipart/form-data');
            return this.httpClient.post<any>(`${NODE_URL}/upload-media`, formData, { headers }).pipe(
              map(({ id, type, typeId, url }: { id: number; type: string; typeId: number; url: string }) => {
                return { id };
              })
            );
          }),
        ]);
  }

  getMessagesToQuote({ messages, type }: { messages: Comment[]; type: string }) {
    return !messages.length
      ? of([])
      : combineLatest([
          ...messages.map((message) => {
            const linkType = message.type === 'task-comments' || message.type.indexOf('chat') !== -1 ? 'users-chat' : 'messages';
            return from(
              this.feathers
                .service('message-links')
                .create({ linkId: message.id, type: linkType })
                .then(({ id }) => ({ id, service: type }))
            );
          }),
        ]);
  }
}
