import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ModalModule,
  TabsModule,
  BsDropdownModule,
  AccordionModule,
  AlertModule,
  ButtonsModule,
  CollapseModule,
  ProgressbarModule,
  TooltipModule,
  TypeaheadModule,
} from 'ngx-bootstrap';

import { TargetsComponent } from './targets.component';
import { TargetsContentComponent } from './targets-content/targets-content.component';
import { TargetsItemComponent } from './targets-content/targets-item/targets-item.component';
import { TargetsTitleComponent } from './targets-title/targets-title.component';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GroupByPipe } from './group-by.pipe';
import { TargetsGroupItemComponent } from './targets-content/targets-group-item/targets-group-item.component';
import { TargetsWidgetComponent } from './components/targets-widget/targets-widget.component';

@NgModule({
  declarations: [
    TargetsComponent,
    TargetsContentComponent,
    TargetsItemComponent,
    TargetsTitleComponent,
    GroupByPipe,
    TargetsGroupItemComponent,
    TargetsWidgetComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CollapseModule,
    ProgressbarModule,
    TooltipModule,
    TypeaheadModule,
    InfiniteScrollModule,
  ],
  exports: [TargetsComponent, TargetsContentComponent, TargetsItemComponent, TargetsWidgetComponent],
  providers: [],
  entryComponents: [],
})
export class TargetsModule {}
