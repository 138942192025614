import { Component, Input } from '@angular/core';
import { OrderDetails, Outlet, UnitIdName } from '../../history.models';

@Component({
  selector: 'iqretail-ordrer-detail',
  templateUrl: './ordrer-detail.component.html',
  styleUrls: ['./ordrer-detail.component.scss'],
})
export class OrderDetailComponent {
  @Input() orderDetail: OrderDetails;
  @Input() outlet: Outlet;

  unitIdNameRus = {
    [UnitIdName.BLK]: 'блк',
    [UnitIdName.KOP]: 'кор',
    [UnitIdName.ST]: 'шт',
    [UnitIdName.PAK]: 'пач',
  };
}
